import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import "./../../public/scss/reports.scss";
import { Link } from "react-router-dom";
import Header from './../website/Header';
import Footer from "./../website/Footer";

import {
    Container,
    Row,
    Col,
    Badge
  } from "reactstrap";

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveWaffle } from '@nivo/waffle';

class IndividualRegionComponent extends Component {

    constructor(props) {
        super(props);

        console.log(props)

        this.state = {
            regionData: [
              {
                region : "NCR",
                total : 12583,
                topSkill_1 : 74,
                topSkill_2 : 75,
                // topSkill_3 : 74,
                // topSkill_4 : 0,
                topSkill_3 : 0,
                topSkill_4 : 74,
                // lowSkill_1 : 60,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                // lowSkill_4 : 0
                lowSkill_4 : 60,
                top_schools : [{
                  id : "Far Eastern University",
                  topskills : [
                    "Workplace ethics",
                    "Math functional",
                    "Self motivation"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Planning and organizing",
                    "Problem sensitivity"
                  ]},{
                  id : "University of St. Tomas",
                  topskills : [
                    "Multitasking",
                    "English Functional",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Teamwork",
                    "Critical Thinking",
                    "Problem sensitivity"
                  ]},{
                  id : "University of Makati",
                  topskills : [
                    "Workplace ethics",
                    "English comprehension",
                    "Multitasking"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Planning and organizing",
                    "Problem sensitivity"
                  ]}
                ],
                gender : {
                  male : 5223,
                  female : 6918
                },
                status : {
                  looking : 5799,
                  notlooking : 3631,
                  notsure : 2711
                },
                age : {
                  groupA : 511, // under 18yr
                  groupB : 2799, // 18yr - 25yr
                  groupC : 313, // 25y - 35yr
                  groupD : 153 // above 35yr
                },
                type : {
                  employed : 373,
                  students : 2491,
                  trainee : 8,
                  tvigraduate : 32,
                  unemployed : 579
                }
              },
              {
                region : "Region 7",
                total : 3604,
                topSkill_1 : 75,
                topSkill_2 : 73,
                topSkill_3 : 0,
                topSkill_4 : 72,
                lowSkill_1 : 0,
                lowSkill_2 : 56,
                lowSkill_3 : 56,
                lowSkill_4 : 58,
                top_schools : [{
                  id : "Bohol Island State University",
                  topskills : [
                    "Social perceptiveness",
                    "Math functional",
                    "Multitasking"
                  ],
                  lowskills : [
                    "Teamwork",
                    "Planning and organizing",
                    "Problem sensitivity"
                  ]},{
                  id : "University of Bohol",
                  topskills : [
                    "English comprehension",
                    "Social perceptiveness",
                    "Self motivation"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Critical Thinking",
                    "Problem sensitivity"
                  ]},{
                  id : "University of Cebu",
                  topskills : [
                    "English Functional",
                    "Workplace ethics",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Teamwork",
                    "Critical Thinking",
                    "Problem sensitivity"
                  ]}
                ],
                gender : {
                  male : 3379,
                  female : 5514
                },
                status : {
                  looking : 4343,
                  notlooking : 3010,
                  notsure : 1540
                },
                age : {
                  groupA : 222, // under 18yr
                  groupB : 1302, // 18yr - 25yr
                  groupC : 109, // 25y - 35yr
                  groupD : 46 // above 35yr
                },
                type : {
                  employed : 233,
                  students : 1303,
                  trainee : 1,
                  tvigraduate : 21,
                  unemployed : 132
                }
              },
              {
                region : "Calabarzon",
                total : 3293,
                // topSkill_1 : 0,
                topSkill_1 : 76,
                topSkill_2 : 77,
                // topSkill_3 : 76,
                topSkill_3 : 0,
                topSkill_4 : 76,
                // lowSkill_1 : 60,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                // lowSkill_4 : 0
                lowSkill_4 : 60,
                top_schools : [{
                  id : "De La Salle University - Dasmarinas",
                  topskills : [
                    "English Functional",
                    "Math functional",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Planning and organizing",
                    "Innovation"
                  ]},{
                  id : "STI Orca",
                  topskills : [
                    "English Functional",
                    "Self motivation",
                    "Multitasking"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Problem sensitivity",
                    "Self Motivation"
                  ]},{
                  id : "De La Salle Lipa",
                  topskills : [
                    "English comprehension",
                    "Social perceptiveness",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Teamwork",
                    "Critical Thinking",
                    "Self Motivation"
                  ]}
                ],
                gender : {
                  male : 1056,
                  female : 1921
                },
                status : {
                  looking : 1562,
                  notlooking : 936,
                  notsure : 479
                },
                age : {
                  groupA : 53, // under 18yr
                  groupB : 643, // 18yr - 25yr
                  groupC : 56, // 25y - 35yr
                  groupD : 25 // above 35yr
                },
                type : {
                  employed : 92,
                  students : 681,
                  trainee : 0,
                  tvigraduate : 1,
                  unemployed : 38
                }
              },
              {
                region : "Region 10",
                total : 3205,
                topSkill_1 : 65,
                topSkill_2 : 69,
                topSkill_3 : 0,
                topSkill_4 : 65,
                // lowSkill_1 : 51,
                lowSkill_1 : 0,
                lowSkill_2 : 51,
                lowSkill_3 : 51,
                // lowSkill_4 : 0
                lowSkill_4 : 51,
                top_schools : [{
                  id : "North Central Mindanao College",
                  topskills : [
                    "English Functional",
                    "Math functional",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Self Motivation",
                    "Planning and organizing",
                    "Innovation"
                  ]},{
                  id : "Iligan City National High School",
                  topskills : [
                    "English comprehension",
                    "Workplace ethics",
                    "Self motivation"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Problem sensitivity",
                    "Self Motivation"
                  ]},{
                  id : "University of Science & Technology of Southern Philippines",
                  topskills : [
                    "English comprehension",
                    "Multitasking",
                    "English Functional"
                  ],
                  lowskills : [
                    "Teamwork",
                    "Critical Thinking",
                    "Self Motivation"
                  ]}
                ],
                gender : {
                  male : 503,
                  female : 840
                },
                status : {
                  looking : 595,
                  notlooking : 454,
                  notsure : 294
                },
                age : {
                  groupA : 1164, // under 18yr
                  groupB : 2306, // 18yr - 25yr
                  groupC : 275, // 25y - 35yr
                  groupD : 74 // above 35yr
                },
                type : {
                  employed : 374,
                  students : 2860,
                  trainee : 3,
                  tvigraduate : 14,
                  unemployed : 331
                }
              },
              {
                region : "Region 3",
                total : 2988,
                topSkill_1 : 80,
                topSkill_2 : 80,
                topSkill_3 : 0,
                topSkill_4 : 78,
                lowSkill_1 : 0,
                lowSkill_2 : 63,
                lowSkill_3 : 60,
                lowSkill_4 : 63,                
                top_schools : [{
                  id : "Holy Angel University",
                  topskills : [
                    "Multitasking",
                    "Math functional",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Decision Making",
                    "Planning and organizing",
                    "Self Motivation"
                  ]},{
                  id : "Columban College",
                  topskills : [
                    "Workplace ethics",
                    "English comprehension",
                    "English Functional"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Planning and organizing",
                    "Innovation"
                  ]},{
                  id : "Bataan Peninsula State University",
                  topskills : [
                    "Multitasking",
                    "Math functional",
                    "English Functional"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Problem sensitivity",
                    "Self Motivation"
                  ]}
                ],
                gender : {
                  male : 2724,
                  female : 3632
                },
                status : {
                  looking : 3728,
                  notlooking : 1821,
                  notsure : 807
                },
                age : {
                  groupA : 128, // under 18yr
                  groupB : 718, // 18yr - 25yr
                  groupC : 101, // 25y - 35yr
                  groupD : 104 // above 35yr
                },
                type : {
                  employed : 170,
                  students : 562,
                  trainee : 0,
                  tvigraduate : 12,
                  unemployed : 177
                }
              },
              {
                region : "Region 12",
                total : 801,
                topSkill_1 : 68,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 64,
                lowSkill_1 : 0,
                lowSkill_2 : 52,
                lowSkill_3 : 50,
                lowSkill_4 : 53,
                top_schools : [{
                  id : "General Santos Academy Inc",
                  topskills : [
                    "Self motivation",
                    "English Functional",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Decision Making",
                    "Self Motivation"
                  ]},{
                  id : "Sultan Kudarat State University",
                  topskills : [
                    "Workplace ethics",
                    "English comprehension",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Planning and organizing",
                    "Self Motivation"
                  ]},{
                  id : "Notre Dame of Daliangas University",
                  topskills : [
                    "Self motivation",
                    "English Functional",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Teamwork",
                    "Planning and organizing",
                    "Innovation"
                  ]}
                ],
                gender : {
                  male : 1126,
                  female : 1615
                },
                status : {
                  looking : 1806,
                  notlooking : 605,
                  notsure : 333
                },
                age : {
                  groupA : 14, // under 18yr
                  groupB : 673, // 18yr - 25yr
                  groupC : 134, // 25y - 35yr
                  groupD : 44 // above 35yr
                },
                type : {
                  employed : 178,
                  students : 448,
                  trainee : 6,
                  tvigraduate : 4,
                  unemployed : 123
                }
              },
              {
                region : "Region 1",
                total : 872,
                topSkill_1 : 70,
                topSkill_2 : 71,
                topSkill_3 : 0,
                topSkill_4 : 71,
                lowSkill_1 : 0,
                lowSkill_2 : 55,
                lowSkill_3 : 53,
                lowSkill_4 : 57,               
                top_schools : [{
                  id : "Pangasinan State University",
                  topskills : [
                    "Multitasking",
                    "Math functional",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Problem sensitivity",
                    "Self Motivation"
                  ]},{
                  id : "Pass College",
                  topskills : [
                    "Workplace ethics",
                    "English Functional",
                    "Social perceptiveness"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Planning and organizing",
                    "Teamwork"
                  ]},{
                  id : "Mariano Marcos State University",
                  topskills : [
                    "Multitasking",
                    "Math functional",
                    "English comprehension"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Problem sensitivity",
                    "Self Motivation"
                  ]}
                ],
                gender : {
                  male : 1456,
                  female : 2323
                },
                status : {
                  looking : 2258,
                  notlooking : 970,
                  notsure : 551
                },
                age : {
                  groupA : 92, // under 18yr
                  groupB : 1192, // 18yr - 25yr
                  groupC : 150, // 25y - 35yr
                  groupD : 30 // above 35yr
                },
                type : {
                  employed : 291,
                  students : 881,
                  trainee : 0,
                  tvigraduate : 10,
                  unemployed : 218
                }
              },
              {
                region : "Region 13",
                total : 902,
                topSkill_1 : 67,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 0,
                lowSkill_2 : 50,
                lowSkill_3 : 52,
                lowSkill_4 : 52,       
                top_schools : [{
                  id : "St. Francis Xavier College",
                  topskills : [
                    "Social perceptiveness",
                    "English Functional",
                    "Self motivation"
                  ],
                  lowskills : [
                    "Problem sensitivity",
                    "Planning and organizing",
                    "Innovation"
                  ]},{
                  id : "Don Jose Ecleo Memorial Foundation College of Science & Technology",
                  topskills : [
                    "Multitasking",
                    "Social perceptiveness",
                    "English comprehension"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Problem sensitivity",
                    "Teamwork"
                  ]},{
                  id : "AMA Computer Learning Center College of Butuan",
                  topskills : [
                    "Math functional",
                    "Workplace ethics",
                    "English Functional"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Innovation",
                    "Teamwork"
                  ]}
                ],
                gender : {
                  male : 328,
                  female : 523
                },
                status : {
                  looking : 426,
                  notlooking : 256,
                  notsure : 169
                },
                age : {
                  groupA : 35, // under 18yr
                  groupB : 161, // 18yr - 25yr
                  groupC : 31, // 25y - 35yr
                  groupD : 10 // above 35yr
                },
                type : {
                  employed : 55,
                  students : 144,
                  trainee : 0,
                  tvigraduate : 6,
                  unemployed : 25
                }
              },
              {
                region : "Region 5",
                total : 932,
                topSkill_1 : 76,
                topSkill_2 : 77,
                topSkill_3 : 0,
                topSkill_4 : 74,
                // lowSkill_1 : 59,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 56,
                // lowSkill_4 : 0
                lowSkill_4 : 59,
                top_schools : [{
                  id : "University of Nueva Caceres",
                  topskills : [
                    "Multitasking",
                    "Math functional",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Problem sensitivity",
                    "Planning and organizing",
                    "Innovation"
                  ]},{
                  id : "Universidad De Sta. Isabel",
                  topskills : [
                    "English Functional",
                    "Social perceptiveness",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Problem sensitivity",
                    "Teamwork"
                  ]},{
                  id : "Camarines Sur National High School",
                  topskills : [
                    "Multitasking",
                    "English comprehension",
                    "Self motivation"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Planning and organizing",
                    "Problem sensitivity"
                  ]}
                ],
                gender : {
                  male : 1010,
                  female : 1629
                },
                status : {
                  looking : 1437,
                  notlooking : 731,
                  notsure : 471
                },
                age : {
                  groupA : 174, // under 18yr
                  groupB : 558, // 18yr - 25yr
                  groupC : 81, // 25y - 35yr
                  groupD : 10 // above 35yr
                },
                type : {
                  employed : 265,
                  students : 455,
                  trainee : 2,
                  tvigraduate : 10,
                  unemployed : 68
                }
              },
              {
                region : "Region 11",
                total : 2412,
                topSkill_1 : 76,
                topSkill_2 : 74,
                topSkill_3 : 0,
                topSkill_4 : 75,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 57,                
                top_schools : [{
                  id : "The University of Mindanao",
                  topskills : [
                    "Workplace ethics",
                    "Math functional",
                    "English Functional"
                  ],
                  lowskills : [
                    "Creative problem solving",
                    "Planning and organizing",
                    "Teamwork"
                  ]},{
                  id : "Assumption College of Nabunturan Southern Philippines Agriculture",
                  topskills : [
                    "English comprehension",
                    "Multitasking",
                    "English Functional"
                  ],
                  lowskills : [
                    "Critical Thinking",
                    "Teamwork",
                    "Creative problem solving"
                  ]},{
                  id : "Business and Marine and Aquatic School of Technology",
                  topskills : [
                    "Multitasking",
                    "English comprehension",
                    "Workplace ethics"
                  ],
                  lowskills : [
                    "Innovation",
                    "Teamwork",
                    "Self Motivation"
                  ]}
                ],
                gender : {
                  male : 7198,
                  female : 12073
                },
                status : {
                  looking : 11108,
                  notlooking : 4901,
                  notsure : 3262
                },
                age : {
                  groupA : 203, // under 18yr
                  groupB : 3072, // 18yr - 25yr
                  groupC : 502, // 25y - 35yr
                  groupD : 147 // above 35yr
                },
                type : {
                  employed : 371,
                  students : 2463,
                  trainee : 6,
                  tvigraduate : 40,
                  unemployed : 541
                }
              }
            ],
            regionDataAscending: [
              {
                region : "Region 12",
                total : 801,
                topSkill_1 : 68,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 64,
                lowSkill_1 : 0,
                lowSkill_2 : 52,
                lowSkill_3 : 50,
                lowSkill_4 : 53
              },
              {
                region : "Region 1",
                total : 872,
                topSkill_1 : 70,
                topSkill_2 : 71,
                topSkill_3 : 0,
                topSkill_4 : 71,
                lowSkill_1 : 0,
                lowSkill_2 : 55,
                lowSkill_3 : 53,
                lowSkill_4 : 57
              },
              {
                region : "Region 13",
                total : 902,
                topSkill_1 : 67,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 0,
                lowSkill_2 : 50,
                lowSkill_3 : 52,
                lowSkill_4 : 52
              },
              {
                region : "Region 5",
                total : 932,
                topSkill_1 : 76,
                topSkill_2 : 77,
                topSkill_3 : 0,
                topSkill_4 : 74,
                lowSkill_1 : 59,
                lowSkill_2 : 59,
                lowSkill_3 : 56,
                lowSkill_4 : 0
              },
              {
                region : "Region 11",
                total : 2412,
                topSkill_1 : 76,
                topSkill_2 : 74,
                topSkill_3 : 0,
                topSkill_4 : 75,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 57
              },
              {
                region : "Region 3",
                total : 2988,
                topSkill_1 : 80,
                topSkill_2 : 80,
                topSkill_3 : 0,
                topSkill_4 : 78,
                lowSkill_1 : 0,
                lowSkill_2 : 63,
                lowSkill_3 : 60,
                lowSkill_4 : 63
              },
              {
                region : "Region 10",
                total : 3205,
                topSkill_1 : 65,
                topSkill_2 : 69,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 51,
                lowSkill_2 : 51,
                lowSkill_3 : 51,
                lowSkill_4 : 0
              },
              {
                region : "Calabarzon",
                total : 3293,
                topSkill_1 : 0,
                topSkill_2 : 77,
                topSkill_3 : 76,
                topSkill_4 : 76,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              },
              {
                region : "Region 7",
                total : 3604,
                topSkill_1 : 75,
                topSkill_2 : 73,
                topSkill_3 : 0,
                topSkill_4 : 72,
                lowSkill_1 : 0,
                lowSkill_2 : 56,
                lowSkill_3 : 56,
                lowSkill_4 : 58
              },
              {
                region : "NCR",
                total : 12583,
                topSkill_1 : 74,
                topSkill_2 : 75,
                topSkill_3 : 74,
                topSkill_4 : 0,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              }
            ],
            regionDataDescending: [
              {
                region : "NCR",
                total : 12583,
                topSkill_1 : 74,
                topSkill_2 : 75,
                topSkill_3 : 74,
                topSkill_4 : 0,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              },
              {
                region : "Region 7",
                total : 3604,
                topSkill_1 : 75,
                topSkill_2 : 73,
                topSkill_3 : 0,
                topSkill_4 : 72,
                lowSkill_1 : 0,
                lowSkill_2 : 56,
                lowSkill_3 : 56,
                lowSkill_4 : 58
              },
              {
                region : "Calabarzon",
                total : 3293,
                topSkill_1 : 0,
                topSkill_2 : 77,
                topSkill_3 : 76,
                topSkill_4 : 76,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              },
              {
                region : "Region 10",
                total : 3205,
                topSkill_1 : 65,
                topSkill_2 : 69,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 51,
                lowSkill_2 : 51,
                lowSkill_3 : 51,
                lowSkill_4 : 0
              },
              {
                region : "Region 3",
                total : 2988,
                topSkill_1 : 80,
                topSkill_2 : 80,
                topSkill_3 : 0,
                topSkill_4 : 78,
                lowSkill_1 : 0,
                lowSkill_2 : 63,
                lowSkill_3 : 60,
                lowSkill_4 : 63
              },
              {
                region : "Region 11",
                total : 2412,
                topSkill_1 : 76,
                topSkill_2 : 74,
                topSkill_3 : 0,
                topSkill_4 : 75,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 57
              },
              {
                region : "Region 5",
                total : 932,
                topSkill_1 : 76,
                topSkill_2 : 77,
                topSkill_3 : 0,
                topSkill_4 : 74,
                lowSkill_1 : 59,
                lowSkill_2 : 59,
                lowSkill_3 : 56,
                lowSkill_4 : 0
              },
              {
                region : "Region 13",
                total : 902,
                topSkill_1 : 67,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 0,
                lowSkill_2 : 50,
                lowSkill_3 : 52,
                lowSkill_4 : 52
              },
              {
                region : "Region 1",
                total : 872,
                topSkill_1 : 70,
                topSkill_2 : 71,
                topSkill_3 : 0,
                topSkill_4 : 71,
                lowSkill_1 : 0,
                lowSkill_2 : 55,
                lowSkill_3 : 53,
                lowSkill_4 : 57
              },
              {
                region : "Region 12",
                total : 801,
                topSkill_1 : 68,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 64,
                lowSkill_1 : 0,
                lowSkill_2 : 52,
                lowSkill_3 : 50,
                lowSkill_4 : 53
              }
            ],
            topSkillsPie: {},
            topSkillsWaffle: {},
            lowSkillsWaffle: {},
            topSkillsRadar: [{
              "skill": "loading",
              "value": 0
            }],
            lowSkillsRadar: [{
              "skill": "loading",
              "value": 0
            }],
            regionSample2: {},
            regionSampleGroupedTop: {},
            regionSampleGroupedLow: {},
            regionSampleGrouped: [],
            genderClassification : [],
            genderClassificationIndex : 0,
            jobClassification : [],
            jobClassificationIndex : 0,
            ageClassification : [],
            ageClassificationIndex : 0,
            typeClassification : [],
            typeClassificationIndex : 0,
            orderOption : 'lowest',
            // skillChartOption : 'top',
            skilldataindex : 0,
            selectedRegion : this.props.match.params.regionId
        };
    }

  componentDidMount() {

    var topskillarray = [];
    var lowskillarray = [];

    var genderArray = [];
    var genderArray_all = [];
    var male_all = 0,
    female_all = 0;

    var jobSearchArray = [];
    var jobSearchArray_all = [];
    var looking_all = 0,
    notlooking_all = 0,
    notsure_all = 0;

    var ageArray = [];
    var ageArray_all = [];
    var groupA_all = 0,
    groupB_all = 0,
    groupC_all = 0,
    groupD_all = 0;

    var typeArray = [];
    var typeArray_all = [];
    var employed_all = 0,
    students_all = 0,
    trainee_all = 0,
    tvigraduate_all = 0,
    unemployed_all = 0;

    
    this.state.regionData.map((region, key) => {
      
      if(region.region == this.state.selectedRegion){ // checking the selected region with region in array
        topskillarray.push({
          "id": "Math Functional Skill",
          "label": "Math Functional Skill",
          "value": region.topSkill_1,
          "color": "#468df3"
        },{
          "id": "English Functional Skill",
          "label": "English Functional Skill",
          "value": region.topSkill_2,
          "color": "#ba72ff"
        },{
          "id": "Workplace Ethics",
          "label": "Workplace Ethics",
          "value": region.topSkill_4,
          "color": "#468df3"
        })

        lowskillarray.push({
          "id": "Planning and Organizing",
          "label": "Planning and Organizing",
          "value": region.lowSkill_2,
          "color": "#ba72ff"
        },{
          "id": "Creative Problem Solving",
          "label": "Creative Problem Solving",
          "value": region.lowSkill_3,
          "color": "#a1cfff"
        },{
          "id": "Innovation",
          "label": "Innovation",
          "value": region.lowSkill_4,
          "color": "#468df3"
        })

        this.setState({
          topSkillsWaffle : {
            data : topskillarray,
            total : region.total
          },
          lowSkillsWaffle: {
            data : lowskillarray,
            total : region.total
          }
        })

        this.setState({
          topSkillsRadar : [{
            "skill": "Math Functional Skill",
            "value": region.topSkill_1
          },
          {
            "skill": "English Functional Skill",
            "value": region.topSkill_2
          },
          {
            "skill": "Workplace Ethics",
            "value": region.topSkill_4
          }],
          lowSkillsRadar: [{
            "skill": "Planning and Organizing",
            "value": region.lowSkill_2
          },{
            "skill": "Creative Problem Solving",
            "value": region.lowSkill_3
          },{
            "skill": "Innovation",
            "value": region.lowSkill_4
          }]
        },
        () => {
          console.log(this.state.topSkillsRadar)
          console.log(this.state.lowSkillsRadar)
        })

        if(region.gender){
          var male = Math.round((region.gender.male / (region.gender.male + region.gender.female)) * 100);
          var female = Math.round((region.gender.female / (region.gender.male + region.gender.female)) * 100);
          genderArray.push({
            "id": "male",
            "label": "male",
            "value": male ,
            "color": "hsl(259, 70%, 50%)"
          },
          {
            "id": "female",
            "label": "female",
            "value": female,
            "color": "hsl(184, 70%, 50%)"
          });
          this.setState({
            genderClassification : [genderArray, genderArray_all]
          },
          () => {
            console.log(this.state.genderClassification)
          })
        }

        if(region.status){
          var looking = Math.round((region.status.looking / (region.status.looking + region.status.notlooking + region.status.notsure)) * 100);
          var notlooking = Math.round((region.status.notlooking / (region.status.looking + region.status.notlooking + region.status.notsure)) * 100);
          var notsure = Math.round((region.status.notsure / (region.status.looking + region.status.notlooking + region.status.notsure)) * 100);
          jobSearchArray.push({
            "id": "Looking for jobs",
            "label": "Looking for jobs",
            "value": looking ,
            "color": "hsl(259, 70%, 50%)"
          },
          {
            "id": "Not looking for jobs",
            "label": "Not looking for jobs",
            "value": notlooking,
            "color": "hsl(184, 70%, 50%)"
          },
          {
            "id": "Not sure about job search",
            "label": "Not sure about job search",
            "value": notsure,
            "color": "hsl(184, 70%, 50%)"
          });
          this.setState({
            jobClassification : [jobSearchArray, jobSearchArray_all]
          },
          () => {
            console.log(this.state.jobClassification)
          })
        }

        if(region.age){
          var groupA = Math.round((region.age.groupA / (region.age.groupA + region.age.groupB + region.age.groupC + region.age.groupD)) * 100);
          var groupB = Math.round((region.age.groupB / (region.age.groupA + region.age.groupB + region.age.groupC + region.age.groupD)) * 100);
          var groupC = Math.round((region.age.groupC / (region.age.groupA + region.age.groupB + region.age.groupC + region.age.groupD)) * 100);
          var groupD = Math.round((region.age.groupD / (region.age.groupA + region.age.groupB + region.age.groupC + region.age.groupD)) * 100);
          ageArray.push({
            "id": "under 18yr",
            "label": "under 18 years",
            "value": groupA ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "18yr - 25yr",
            "label": "Between 18 years to 25 years",
            "value": groupB ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "25yr - 35yr",
            "label": "Between 25 years to 35 years",
            "value": groupC ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "above 35yr",
            "label": "Above 35 years",
            "value": groupD ,
            "color": "hsl(259, 70%, 50%)"
          });
          this.setState({
            ageClassification : [ageArray, ageArray_all]
          },
          () => {
            console.log(this.state.ageClassification)
          })
        }

        if(region.type){
          var employed = Math.round((region.type.employed / (region.type.employed + region.type.students + region.type.trainee + region.type.tvigraduate + region.type.unemployed)) * 100);
          var students = Math.round((region.type.students / (region.type.employed + region.type.students + region.type.trainee + region.type.tvigraduate + region.type.unemployed)) * 100);
          var trainee = Math.round((region.type.trainee / (region.type.employed + region.type.students + region.type.trainee + region.type.tvigraduate + region.type.unemployed)) * 100);
          var tvigraduate = Math.round((region.type.tvigraduate / (region.type.employed + region.type.students + region.type.trainee + region.type.tvigraduate + region.type.unemployed)) * 100);
          var unemployed = Math.round((region.type.unemployed / (region.type.employed + region.type.students + region.type.trainee + region.type.tvigraduate + region.type.unemployed)) * 100);
          typeArray.push({
            "id": "Employed",
            "label": "Employed",
            "value": employed ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "Student",
            "label": "Student",
            "value": students ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "Trainee",
            "label": "Trainee",
            "value": trainee ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "TVI Graduate",
            "label": "TVI Graduate",
            "value": tvigraduate ,
            "color": "hsl(259, 70%, 50%)"
          },{
            "id": "Unemployed",
            "label": "Unemployed",
            "value": unemployed ,
            "color": "hsl(259, 70%, 50%)"
          });
          this.setState({
            typeClassification : [typeArray, typeArray_all]
          },
          () => {
            console.log(this.state.typeClassification)
          })
        }
        

      }

      // pushing all regions gender data
      if(region.gender){
        male_all += region.gender.male;
        female_all += region.gender.female;
        console.log(male_all, female_all);
      }

      // pushing all regions age data
      if(region.age){
        groupA_all += region.age.groupA;
        groupB_all += region.age.groupB;
        groupC_all += region.age.groupC;
        groupD_all += region.age.groupD;
        console.log(groupA_all, groupB_all, groupC_all, groupD_all);
      }

      // pushing all regions jobs data
      if(region.status){
        looking_all += region.status.looking;
        notlooking_all += region.status.notlooking;
        notsure_all += region.status.notsure;
        console.log(looking_all, notlooking_all, notsure_all);
      }

      // pushing all regions types data
      if(region.type){
        employed_all += region.type.employed;
        students_all += region.type.students;
        trainee_all += region.type.trainee;
        tvigraduate_all += region.type.tvigraduate;
        unemployed_all += region.type.unemployed;
        console.log(employed_all, students_all, trainee_all, tvigraduate_all, unemployed_all);
      }

    })

    //setting up the main state for genderclassification
    var male = Math.round((male_all / (male_all + female_all)) * 100);
    var female = Math.round((female_all / (male_all + female_all)) * 100);
    genderArray_all.push({
      "id": "male",
      "label": "male",
      "value": male ,
      "color": "hsl(259, 70%, 50%)"
    },
    {
      "id": "female",
      "label": "female",
      "value": female,
      "color": "hsl(184, 70%, 50%)"
    });
    this.setState({
      genderClassification : [genderArray, genderArray_all]
    },
    () => {
      console.log(this.state.genderClassification)
    })

    //setting up the main state for ageclassification
    var groupA = Math.round((groupA_all / (groupA_all + groupB_all + groupC_all + groupD_all)) * 100);
    var groupB = Math.round((groupB_all / (groupA_all + groupB_all + groupC_all + groupD_all)) * 100);
    var groupC = Math.round((groupC_all / (groupA_all + groupB_all + groupC_all + groupD_all)) * 100);
    var groupD = Math.round((groupD_all / (groupA_all + groupB_all + groupC_all + groupD_all)) * 100);
    ageArray_all.push({
      "id": "under 18yr",
      "label": "under 18 years",
      "value": groupA ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "18yr - 25yr",
      "label": "Between 18 years to 25 years",
      "value": groupB ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "25yr - 35yr",
      "label": "Between 25 years to 35 years",
      "value": groupC ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "above 35yr",
      "label": "Above 35 years",
      "value": groupD ,
      "color": "hsl(259, 70%, 50%)"
    });
    this.setState({
      ageClassification : [ageArray, ageArray_all]
    },
    () => {
      console.log(this.state.ageClassification)
    })

    //setting up the main state for jobclassification
    var looking = Math.round((looking_all / (looking_all + notlooking_all + notsure_all)) * 100);
    var notlooking = Math.round((notlooking_all / (looking_all + notlooking_all + notsure_all)) * 100);
    var notsure = Math.round((notsure_all / (looking_all + notlooking_all + notsure_all)) * 100);
    jobSearchArray_all.push({
      "id": "Looking for jobs",
      "label": "Looking for jobs",
      "value": looking ,
      "color": "hsl(259, 70%, 50%)"
    },
    {
      "id": "Not looking for jobs",
      "label": "Not looking for jobs",
      "value": notlooking,
      "color": "hsl(184, 70%, 50%)"
    },
    {
      "id": "Not sure about job search",
      "label": "Not sure about job search",
      "value": notsure,
      "color": "hsl(184, 70%, 50%)"
    });
    this.setState({
      jobClassification : [jobSearchArray, jobSearchArray_all]
    },
    () => {
      console.log(this.state.jobClassification)
    })

    //setting up the main state for typeclassification
    var employed = Math.round((employed_all / (employed_all + students_all + trainee_all + tvigraduate_all + unemployed_all)) * 100);
    var students = Math.round((students_all / (employed_all + students_all + trainee_all + tvigraduate_all + unemployed_all)) * 100);
    var trainee = Math.round((trainee_all / (employed_all + students_all + trainee_all + tvigraduate_all + unemployed_all)) * 100);
    var tvigraduate = Math.round((tvigraduate_all / (employed_all + students_all + trainee_all + tvigraduate_all + unemployed_all)) * 100);
    var unemployed = Math.round((unemployed_all / (employed_all + students_all + trainee_all + tvigraduate_all + unemployed_all)) * 100);
    typeArray_all.push({
      "id": "Employed",
      "label": "Employed",
      "value": employed ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "Student",
      "label": "Student",
      "value": students ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "Trainee",
      "label": "Trainee",
      "value": trainee ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "TVI Graduate",
      "label": "TVI Graduate",
      "value": tvigraduate ,
      "color": "hsl(259, 70%, 50%)"
    },{
      "id": "Unemployed",
      "label": "Unemployed",
      "value": unemployed ,
      "color": "hsl(259, 70%, 50%)"
    });
    this.setState({
      typeClassification : [typeArray, typeArray_all]
    },
    () => {
      console.log(this.state.typeClassification)
    })


  }

  barChartClicked = (e) => {
    console.log(e);
    localStorage.setItem('selectedRegion', this.state.selectedRegion); // updating the selected region
    this.props.history.push('/skill/' + e.indexValue);
  }

  render() {
      
    return (
      <div className="IndividualRegionComponent">
        {/* <Header /> */}

        <div>
            <Container fluid>
                <Row style={{ padding: '3vh 0' }}>
                    <Col style={{padding: '0vh 20px 2vh', textAlign: 'left'}} md={{ size: 12 }}>
                      <h6><Link to="/dashboard">Dashboard</Link> / {this.state.selectedRegion}</h6>
                    </Col>
                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>Participating schools from {this.state.selectedRegion}, and their skill proficiency </h5>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row style={{padding: '25px'}}>
                            {
                              this.state.regionData.map((data, key) => 

                                data.region == this.state.selectedRegion ?

                                  data.top_schools.map((school, key) => 

                                    <Col md={{ size: 4 }}>
                                      <div className="schoolBox">
                                        <Row>
                                          <Col style={{background: '#0544a2'}}>
                                            <div style={{verticalAlign: 'middle', padding: '6.5vh 0'}}>
                                              <FontAwesomeIcon icon={faSchool} size="2x" />
                                              <h4>{school.id}</h4>
                                            </div>
                                          </Col>
                                          <Col style={{textAlign: 'left'}}>
                                            <p style={{color: '#fff', fontSize: ''}}>High proficiency skills<br/>
                                            {
                                              school.topskills && school.topskills.map((data) => 
                                                <span style={{fontSize: '12px', lineHeight: '14px'}}>{data}<br/></span>
                                              )
                                            }
                                            </p>
                                            
                                            <p style={{color: '#fff', marginBottom: '3px'}}>Low proficiency skills<br/>
                                            {
                                              school.lowskills && school.lowskills.map((data) => 
                                                <span style={{fontSize: '12px', lineHeight: '14px'}}>{data}<br/></span>
                                              )
                                            }
                                            </p>
                                          </Col>
                                        </Row>
                                        {/* <span className="topLabel">Top School</span> */}
                                      </div>
                                    </Col>

                                  )
                                :
                                null
                              )
                            }
                          </Row>
                        </div>
                      </div>
                    </Col>
                    
                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>High proficiency skill scores in {this.state.selectedRegion} (3 of 15 skills)</h5>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row>
                            <Col md={{size: 12}} style={{height: '220px'}}>
                              <ResponsiveBar
                                data={this.state.topSkillsRadar}
                                keys={["value"]}
                                indexBy={"skill"}
                                margin={{ top: 50, right: 60, bottom: 50, left: 180 }}
                                padding={0.3}
                                onClick={e => this.barChartClicked(e)}
                                groupMode="stacked"
                                label={d => `${d.value}%`}
                                colors={{ scheme: 'dark2' }}
                                layout="horizontal"
                                enableGridY= {false}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 50
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -60
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                // legends={[
                                //     {
                                //         dataFrom: 'keys',
                                //         anchor: 'top-right',
                                //         direction: 'column',
                                //         justify: false,
                                //         translateX: 120,
                                //         translateY: 0,
                                //         itemsSpacing: 2,
                                //         itemWidth: 100,
                                //         itemHeight: 20,
                                //         itemDirection: 'left-to-right',
                                //         itemOpacity: 0.85,
                                //         symbolSize: 20,
                                //         effects: [
                                //             {
                                //                 on: 'hover',
                                //                 style: {
                                //                     itemOpacity: 1
                                //                 }
                                //             }
                                //         ]
                                //     }
                                // ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </Col>
                          </Row>
                          
                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>Low proficiency skill scores in {this.state.selectedRegion} (3 of 15 skills)</h5>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row>
                            <Col md={{size: 12}} style={{height: '220px'}}>
                              <ResponsiveBar
                                data={this.state.lowSkillsRadar}
                                keys={["value"]}
                                indexBy={"skill"}
                                margin={{ top: 50, right: 60, bottom: 50, left: 180 }}
                                padding={0.3}
                                onClick={e => this.barChartClicked(e)}
                                groupMode="stacked"
                                label={d => `${d.value}%`}
                                colors={{ scheme: 'set1' }}
                                layout="horizontal"
                                enableGridY= {false}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 50
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -60
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                // legends={[
                                //     {
                                //         dataFrom: 'keys',
                                //         anchor: 'top-right',
                                //         direction: 'column',
                                //         justify: false,
                                //         translateX: 120,
                                //         translateY: 0,
                                //         itemsSpacing: 2,
                                //         itemWidth: 100,
                                //         itemHeight: 20,
                                //         itemDirection: 'left-to-right',
                                //         itemOpacity: 0.85,
                                //         symbolSize: 20,
                                //         effects: [
                                //             {
                                //                 on: 'hover',
                                //                 style: {
                                //                     itemOpacity: 1
                                //                 }
                                //             }
                                //         ]
                                //     }
                                // ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </Col>
                          </Row>
                          
                        </div>
                      </div>
                    </Col>
                    
                    {

                      this.state.genderClassification[this.state.genderClassificationIndex] ?

                      <Col md={{ size: 5 }}>
                        <div className="chartBox">
                          <div className="titleHead">
                            <Row>
                              <Col md={{ size : 8 }}>
                                <h5>Gender distribution in { this.state.genderClassificationIndex == 0 ? this.state.selectedRegion : 'all regions'}</h5>
                              </Col>
                              <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                                <Switch
                                  checked={this.state.genderClassificationIndex === 1}
                                  onChange={e => this.setState({ genderClassificationIndex : this.state.genderClassificationIndex == 0 ? 1 : 0 }) }
                                  uncheckedIcon={
                                    <div className="switchLabel">
                                      {this.state.selectedRegion}
                                    </div>
                                  }
                                  checkedIcon={
                                    <div className="switchLabel">
                                      All Regions
                                    </div>
                                  }
                                  className={this.state.genderClassificationIndex == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                  id="icon-switch"
                                />
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row>
                              <Col md={{size: 12}} style={{height: '400px'}}>
                                <ResponsivePie
                                  data={this.state.genderClassification[this.state.genderClassificationIndex]}
                                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                  innerRadius={0.5}
                                  padAngle={0.7}
                                  sliceLabel={function(e){return e.value+"%"}}
                                  cornerRadius={3}
                                  colors={{ scheme: this.state.genderClassificationIndex == 0 ? 'set1' : 'set1' }}
                                  borderWidth={1}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                  radialLabelsSkipAngle={10}
                                  radialLabelsTextXOffset={6}
                                  radialLabelsTextColor="#333333"
                                  radialLabelsLinkOffset={0}
                                  radialLabelsLinkDiagonalLength={16}
                                  radialLabelsLinkHorizontalLength={24}
                                  radialLabelsLinkStrokeWidth={1}
                                  radialLabelsLinkColor={{ from: 'color' }}
                                  slicesLabelsSkipAngle={10}
                                  slicesLabelsTextColor="#333333"
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: 'rgba(255, 255, 255, 0.3)',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: 'rgba(255, 255, 255, 0.3)',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  legends={[
                                      {
                                          anchor: 'bottom',
                                          direction: 'row',
                                          translateY: 56,
                                          itemWidth: 100,
                                          itemHeight: 18,
                                          itemTextColor: '#999',
                                          symbolSize: 18,
                                          symbolShape: 'circle',
                                          effects: [
                                              {
                                                  on: 'hover',
                                                  style: {
                                                      itemTextColor: '#000'
                                                  }
                                              }
                                          ]
                                      }
                                  ]}
                                />
                              </Col>
                            </Row>
                            
                          </div>
                        </div>
                      </Col>
                    :
                    null
                    }


                  {
                  
                  this.state.ageClassification[this.state.ageClassificationIndex] ?

                  <Col md={{ size: 7 }}>
                    <div className="chartBox">
                      <div className="titleHead">
                        <Row>
                          <Col md={{ size : 8 }}>
                            <h5>Age distribution in { this.state.ageClassificationIndex == 0 ? this.state.selectedRegion : 'all regions'}</h5>
                          </Col>
                          <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                            <Switch
                              checked={this.state.ageClassificationIndex === 1}
                              onChange={e => this.setState({ ageClassificationIndex : this.state.ageClassificationIndex == 0 ? 1 : 0 }) }
                              uncheckedIcon={
                                <div className="switchLabel">
                                  {this.state.selectedRegion}
                                </div>
                              }
                              checkedIcon={
                                <div className="switchLabel">
                                  All Regions
                                </div>
                              }
                              className={this.state.ageClassificationIndex == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                              id="icon-switch"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col md={{size: 12}} style={{height: '400px'}}>
                              <ResponsivePie
                                  data={this.state.ageClassification[this.state.ageClassificationIndex]}
                                  margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
                                  innerRadius={0.5}
                                  padAngle={0}
                                  sliceLabel={function(e){return e.value+"%"}}
                                  cornerRadius={0}
                                  colors={{ scheme: this.state.ageClassificationIndex == 0 ? 'dark2' : 'dark2' }}
                                  borderWidth={1}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                  radialLabelsSkipAngle={0}
                                  radialLabelsTextXOffset={6}
                                  radialLabelsTextColor="#333333"
                                  radialLabelsLinkOffset={0}
                                  radialLabelsLinkDiagonalLength={16}
                                  radialLabelsLinkHorizontalLength={24}
                                  radialLabelsLinkStrokeWidth={1}
                                  radialLabelsLinkColor={{ from: 'color' }}
                                  slicesLabelsSkipAngle={10}
                                  slicesLabelsTextColor="#333333"
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: 'rgba(255, 255, 255, 0.3)',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: 'rgba(255, 255, 255, 0.3)',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  legends={[
                                      {
                                          anchor: 'top-right',
                                          direction: 'column',
                                          translateY: -20,
                                          translateX: 50,
                                          itemWidth: 150,
                                          itemHeight: 28,
                                          itemTextColor: '#999',
                                          symbolSize: 18,
                                          symbolShape: 'circle',
                                          effects: [
                                              {
                                                  on: 'hover',
                                                  style: {
                                                      itemTextColor: '#000'
                                                  }
                                              }
                                          ]
                                      }
                                  ]}
                                />
                          </Col>
                        </Row>
                        
                      </div>
                    </div>
                  </Col>
                  :
                  null
                  }

                  
                    
                  {
                  
                  this.state.jobClassification[this.state.jobClassificationIndex] ?

                  <Col md={{ size: 6 }}>
                    <div className="chartBox">
                      <div className="titleHead">
                        <Row>
                          <Col md={{ size : 8 }}>
                            <h5>Job searching status in { this.state.jobClassificationIndex == 0 ? this.state.selectedRegion : 'all regions'}</h5>
                          </Col>
                          <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                            <Switch
                              checked={this.state.jobClassificationIndex === 1}
                              onChange={e => this.setState({ jobClassificationIndex : this.state.jobClassificationIndex == 0 ? 1 : 0 }) }
                              uncheckedIcon={
                                <div className="switchLabel">
                                  {this.state.selectedRegion}
                                </div>
                              }
                              checkedIcon={
                                <div className="switchLabel">
                                  All Regions
                                </div>
                              }
                              className={this.state.jobClassificationIndex == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                              id="icon-switch"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col md={{size: 12}} style={{height: '400px'}}>
                          <ResponsiveWaffle
                                data={this.state.jobClassification[this.state.jobClassificationIndex]}
                                total={100}
                                rows={26}
                                columns={54}
                                emptyOpacity={0}
                                margin={{ top: 60, right: 30, bottom: 20, left: 30 }}
                                colors={{ scheme: this.state.jobClassificationIndex == 0 ? 'category10' : 'category10' }}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={40}
                                legends={[
                                    {
                                        anchor: 'top-left',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 0,
                                        translateY: -35,
                                        itemsSpacing: 4,
                                        itemWidth: 140,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        itemTextColor: '#777',
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000',
                                                    itemBackground: '#f7fafb'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                          </Col>
                        </Row>
                        
                      </div>
                    </div>
                  </Col>
                  :
                  null
                  }

                  {

                  this.state.typeClassification[this.state.typeClassificationIndex] ?

                  <Col md={{ size: 6 }}>
                    <div className="chartBox">
                      <div className="titleHead">
                        <Row>
                          <Col md={{ size : 8 }}>
                            <h5>Candidates classification in { this.state.typeClassificationIndex == 0 ? this.state.selectedRegion : 'all regions'}</h5>
                          </Col>
                          <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                            <Switch
                              checked={this.state.typeClassificationIndex === 1}
                              onChange={e => this.setState({ typeClassificationIndex : this.state.typeClassificationIndex == 0 ? 1 : 0 }) }
                              uncheckedIcon={
                                <div className="switchLabel">
                                  {this.state.selectedRegion}
                                </div>
                              }
                              checkedIcon={
                                <div className="switchLabel">
                                  All Regions
                                </div>
                              }
                              className={this.state.typeClassificationIndex == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                              id="icon-switch"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col md={{size: 12}} style={{height: '400px'}}>
                          <ResponsiveWaffle
                                data={this.state.typeClassification[this.state.typeClassificationIndex]}
                                total={100}
                                rows={26}
                                columns={54}
                                emptyOpacity={0}
                                margin={{ top: 60, right: 30, bottom: 20, left: 30 }}
                                colors={{ scheme: this.state.typeClassificationIndex == 0 ? 'category10' : 'category10' }}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={40}
                                legends={[
                                    {
                                        anchor: 'top-left',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 0,
                                        translateY: -35,
                                        itemsSpacing: 4,
                                        itemWidth: 110,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        itemTextColor: '#777',
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000',
                                                    itemBackground: '#f7fafb'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                          </Col>
                        </Row>
                        
                      </div>
                    </div>
                  </Col>
                  :
                  null
                  }

                   
                </Row>
            </Container>
        </div>
        
        {/* <Footer /> */}
      </div>
    );
    
  }
}

export default IndividualRegionComponent;
