import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

import FourBox from './../snippets/FourBox';
import ValidityContainers from './../snippets/ValidityContainers';

import PhaseValidity from './../../../public/images/validity/4PhaseValidity.png';
import vr2 from './../../../public/images/validity/vr2.jpg';
import vr12 from './../../../public/images/validity/vr12.jpg';
import vr9 from './../../../public/images/validity/vr9.jpg';
import vr8 from './../../../public/images/validity/vr8.jpg';

class ValidityComponent extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        fourboxdata: {
            labelImage: PhaseValidity,
            boxes: [
                {
                    background: '#ff2500',
                    text: 'Construct Validity',
                    link: '#validity1'
                },{
                    background: '#a1e34a',
                    text: 'Face Validity',
                    link: '#validity2'
                },{
                    background: '#2eb0f1',
                    text: 'Content Validity',
                    link: '#validity3'
                },{
                    background: '#fdc509',
                    text: 'Predictive Validity',
                    link: '#validity4'
                }
            ]
        },
        ValidityData: [
            {
                heading: "Construct Validity",
                text: "ensures that the right elements are measured",
                bgColor: '#ff2500',
                bgImage: vr2,
                button: {
                    label : "Donwload Report",
                    link : "http://www.testedtalent.com/wp-content/themes/TestedTalent/Validity.pdf"
                },
                position: "rtl"
            },{
                heading: "Face Validity",
                text: "ensures that the questions can be understood",
                bgColor: '#a1e34a',
                bgImage: vr12,
                button: {
                    label : "Donwload Report",
                    link : "http://www.testedtalent.com/wp-content/themes/TestedTalent/Validity.pdf"
                },
                position: "ltr"
            },{
                heading: "Content Validity",
                text: "ensures that the situations used in questions are valid",
                bgColor: '#2eb0f1',
                bgImage: vr9,
                button: {
                    label : "Donwload Report",
                    link : "http://www.testedtalent.com/wp-content/themes/TestedTalent/Validity.pdf"
                },
                position: "rtl"
            },{
                heading: "Predictive Validity",
                text: "ensures that the scores on a test are able to anticipate performance on the job",
                bgColor: '#fdc509',
                bgImage: vr8,
                button: {
                    label : "Donwload Report",
                    link : "http://www.testedtalent.com/wp-content/themes/TestedTalent/Validity.pdf"
                },
                position: "ltr"
            }
        ]
        
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      
  }

  render() {
    return (
      <div className="ValidityComponent">
      
        <FourBox data={this.state.fourboxdata} />

        <ValidityContainers data={this.state.ValidityData} />
      
      </div>
    );
  }
}

export default ValidityComponent;
