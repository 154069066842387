import React, { Component } from "react";
// import "../../public/scss/ObjectivesComponent.scss";
import Header from './Header';
import Footer from "./Footer";

// importing snippets for homepage
import BannerWithText from './snippets/bannerWithText';
import TextWithImage from './snippets/TextWithImage';

import bannerImageObj from './../../public/images/objectiveBanner.png';
import worldBannerIcon from './../../public/images/The-Desk_Cube.png';
import objective1 from './../../public/images/objectives/21.png';
import objective2 from './../../public/images/objectives/lmHow-It-works_ownthedata.png';
import objective3 from './../../public/images/objectives/lmHow-It-works_owntheplatform.png';
import objective4 from './../../public/images/objectives/lmHow-It-works_ownthetestbank.png';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class ObjectivesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bannerData3 : {
                heading: "<img style='max-width: 100%; left: 60px;' src='"+ worldBannerIcon +"' /> Objectives of the<br/>TalentMap",
                // para: "The Philippine TalentMap Initiative (PTMI) (https://talentmap.ph) categorized and examined the current trends and issues that both the academe and industry face in terms of workforce development.  The data derived from this research study was used as springboard to develop sound action plans in positioning the Filipino workforce in the local and global labor market. <br/><br/>TalentMap World will focus on creating an ecology that bridges the gaps amongst different schools, industries, local government units, and national agencies that have a stake in addressing the labor market information gaps of a particular locality and compare the data across other areas covered by the research.",
                btn_text: "",
                btn_text_url: "",
                themeColor: "white header",
                image_url: bannerImageObj,
                // video_url: "http://testedtalent.com/videos/Jeff.mp4"
            },
            section1 : {
                // heading: "Objectives of Talent",
                para: "<span style='font-size: 2rem; top: 10vh; position:relative;'>Assess the workplace competencies of candidates based on 21st Century Skills.</span>",
                btn_text: "",
                btn_text_url: "",
                themeColor: "white",
                image_url: objective1,
                position: 'ltr'
            },
            section2 : {
                // heading: "Objectives of Talent",
                para: "<span style='font-size: 2rem; top: 10vh; position:relative;'>Consolidate and create a workforce profile that represents different age groups.</span>",
                btn_text: "",
                btn_text_url: "",
                themeColor: "gray",
                image_url: objective2,
                position: 'rtl'
            },
            section3 : {
                // heading: "Objectives of Talent",
                para: "<span style='font-size: 2rem; top: 10vh; position:relative;'>Training programs to up skill the workforce.</span>",
                btn_text: "",
                btn_text_url: "",
                themeColor: "white",
                image_url: objective3,
                position: 'ltr'
            },
            section4 : {
                // heading: "Objectives of Talent",
                para: "<span style='font-size: 2rem; top: 10vh; position:relative;'>Provide valuable insights to policy makers in creative relevant programs addressing the issues of the workforce.</span>",
                btn_text: "",
                btn_text_url: "",
                themeColor: "gray",
                image_url: objective4,
                position: 'rtl'
            }
        };
    }
    
  render() {
    return (
      <div className="ObjectivesComponent">
        <Header />

            <BannerWithText bannerData={this.state.bannerData3} />
            <TextWithImage propData={this.state.section1} />
            <TextWithImage propData={this.state.section2} />
            <TextWithImage propData={this.state.section3} />
            <TextWithImage propData={this.state.section4} />
        
        <Footer />
      </div>
    );
  }
}

export default ObjectivesComponent;
