import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoHere from "./../../public/images/logo.png";
import "../../public/scss/Header.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

// import store from './../../store';
import PropTypes from 'prop-types';
import { getCenturySkillsData } from './../../actions/webRedActions';
import { connect } from 'react-redux';

import flag_ph from './../../public/images/adoptacity/icons/flag_ph.png';
import flag_pk from './../../public/images/adoptacity/icons/flag_pk.png';
import flag_usa from './../../public/images/adoptacity/icons/flag_usa.png';
import flag_all from './../../public/images/adoptacity/icons/flag_all.png';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Media,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // NavLink
} from "reactstrap";

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      isOpen: false,
      isModal: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleModal() {
    this.setState({
      isModal: !this.state.isModal
    });
  }

  componentDidMount () {
    this.props.getCenturySkillsData(3);

    localStorage.setItem("selectedRegion", "NCR");

  }

  render() {
    return (
      <div className="MainHeader">

        <Modal isOpen={this.state.isModal} toggle={this.toggleModal} centered className="selectRegionsModal">
          <ModalHeader toggle={this.toggleModal}>Select your country</ModalHeader>
          <ModalBody style={{textAlign: 'center'}}>
            <Row>
              <Col md={{size: 4}}>
                <Link to="/adopt-a-city-philippines">
                  <Button color="link">
                    <Media object src={flag_ph} alt="Philippines" />
                    <p>Philippines</p>
                  </Button>
                </Link>
              </Col>
              <Col md={{size: 4}}>
                <Link to="/adopt-a-city-usa">
                  <Button color="link">
                    <Media object src={flag_usa} alt="USA" />
                    <p>USA</p>
                  </Button>
                </Link>
              </Col>
              <Col md={{size: 4}}>
                <Button color="link">
                  <Media object src={flag_pk} alt="Pakistan" />
                  <p>Pakistan</p>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={{size: 12}}>
                <Button color="link">
                  <Media object src={flag_all} alt="other" />
                  <p>Other country</p>
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <div>
          <Navbar color="dark" dark expand="md">
            <NavbarBrand href="/">
              <img src={logoHere} alt="talentmap" width="150" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto text-left" navbar>
                <NavItem>
                  <Link className="nav-link" to="/">
                    <span style={{lineHeight: '45px'}}>
                      <FontAwesomeIcon icon={faHome} size="1x" />
                    </span>
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link className="nav-link" to="/">
                    About Us <br/> <sup>- What we do</sup>
                  </Link>
                </NavItem> */}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <span style={{lineHeight: '45px'}}>About Us</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <Link className="nav-link" to="/objectives">
                        Our Objectives
                      </Link>
                    </DropdownItem>
                    {/* <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/21st-century-skills">
                        21<sup>st</sup> Century Skills
                      </Link>
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem>
                    <Link style={{lineHeight: '45px'}} className="nav-link" to="/21st-century-skills">
                      21<sup>st</sup> Century Skills
                    </Link>
                </NavItem>
                
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    HireLabs <br/>Assessments
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <Link className="nav-link" to="/assessments/methodology">
                        Methodology
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/assessments/validity-reliability">
                        Validity & Reliability
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/assessments/reports-analytics">
                        Reports & Analytics
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <span style={{lineHeight: '45px'}}>Grant</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <a className="nav-link" target="new" href="http://www.testedtalent.com/grant-application-form/">Apply for grant funding</a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <a className="nav-link" target="new" href="http://www.testedtalent.com/terms-of-grant/">Terms & Conditions</a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <Link className="nav-link" to="/grant/faq">
                        FAQs
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem>
                  {/* <Link className="nav-link" to="/#">
                    Staffing<br/> Solutions
                  </Link> */}
                  {/* <Link className="nav-link" to="/"> */}
                    <a style={{lineHeight: '45px'}} className="nav-link" href="https://talentmap.ph/">PTMI</a>
                  {/* </Link> */}
                </NavItem>
                <NavItem>
                    {/* <a style={{lineHeight: '45px'}} className="nav-link" href="#">Partners</a> */}
                    <Link style={{lineHeight: '45px'}} className="nav-link" to="/partners">
                      Partners
                    </Link>
                </NavItem>
                
                <NavItem className="adoptCityButtonTop" onClick={this.toggleModal}>
                    {/* <a style={{lineHeight: '45px'}} className="nav-link" href="#">Partners</a> */}
                    <Link className="nav-link" to="#">
                      Adopt a<br/>City
                    </Link>
                </NavItem>
                {/* <NavItem> */}
                  {/* <Link className="nav-link" to="/#">
                    Staffing<br/> Solutions
                  </Link> */}
                  {/* <Link className="nav-link" to="/"> */}
                    {/* <a style={{lineHeight: '45px'}} className="nav-link" href="http://www.testedtalent.com/grant-application-form/">Grant</a> */}
                  {/* </Link> */}
                {/* </NavItem> */}

                {/* <NavItem>
                  <Link className="nav-link" to="/">
                    Contact <br/> <sup>- Get in touch</sup>
                  </Link>
                </NavItem> */}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

// export default Header;

Header.propTypes = {
  getCenturySkillsData: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  // skillsResponse: state.webRed.skillsResponse
})

export default connect(mapStatesToProps, { getCenturySkillsData })(Header);