import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./../../../public/scss/snippets/FourBox.scss";

import {
//   Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class FourBox extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        data: props.data
    };
  }

  componentDidMount(){
      console.log(this.state.data);
  }

  componentWillReceiveProps(thisProps, nextProps){
      
  }

  render() {

    return (
      <div className="FourBox">
            <Container>
                <Row>

                    {/* <Col md="12"> */}
                        <Media className="labelImage" object src={this.state.data.labelImage} />
                    {/* </Col> */}

                    {
                        this.state.data.boxes.map((box, key) =>
                            <Col style={{background: box.background}} className="box" key={key} md={{ size: 6 }}>
                                <a style={{color: '#fff'}} href={box.link}>
                                    <h2 className="boxLabel" dangerouslySetInnerHTML={{__html: box.text}}></h2>
                                </a>
                            </Col>
                        )
                    }

                </Row>
            </Container>
      </div>
    );
  }
}

export default FourBox;
