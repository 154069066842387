import React, { Component } from "react";
import "../../public/scss/AdoptACityComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
    Media,
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";

import BannerWithText from './snippets/bannerWithText';

import ungc_logo from "./../../public/images/adoptacity/usa/ungc_logo.png";

// import git1 from "./../../public/images/getInTouch/git1.png";
// import git2 from "./../../public/images/getInTouch/git2.png";
import git3 from "./../../public/images/getInTouch/git3.png";
// import adoptcity1 from "./../../public/images/adoptacity/1.png";


class AdoptACityUSAComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            africanAmericanHistory: "https://comptonrising.org/wp-content/uploads/2018/12/cs_02.jpg",
            raceAndGenderEquality: "https://comptonrising.org/wp-content/uploads/2018/12/cs_03.jpg",
            leni_banner : 'http://comptonrising.org/wp-content/uploads/2018/12/lani-banner.jpg',
            robotBg: 'http://comptonrising.org/wp-content/uploads/2018/12/cs_07.jpg',
            turningAroundLives: 'http://comptonrising.org/wp-content/uploads/2018/12/cs_09.jpg',
            videoTestimonials: 'http://comptonrising.org/wp-content/uploads/2018/12/cs_11.jpg',
            celebritiesBg: 'http://comptonrising.org/wp-content/uploads/2018/12/cs_13.jpg',
            corporateSocial: 'http://comptonrising.org/wp-content/uploads/2018/12/cs_15.jpg',
            leaderCommunity: 'http://comptonrising.org/wp-content/uploads/2018/12/cs_17.jpg',
            bannerData : {
                heading: "",
                subheading: "",
                btn_text: "Watch our kids RISE with<br/>Unilever's CEO Paul Polman<br/><strong>at the United Nations</strong>",
                btn_text_url: "https://youtu.be/3IB4GREDpKc",
                video_url: "http://comptonrising.org/wp-content/uploads/2018/12/WhatsApp-Video-2018-12-06-at-5.27.31-PM.mp4",
                themeColor: "yellow"
            },
            bannerData2 : {
                heading: "",
                subheading: "",
                btn_text: "Watch our kids<br/><strong>Create Vlogs</strong><br/>for their conferences",
                btn_text_url: "https://youtu.be/GZsPZJECtqw",
                video_url: "http://comptonrising.org/wp-content/uploads/2018/12/WhatsApp-Video-2018-12-07-at-10.56.29-AM.mp4",
                themeColor: "yellow"
            }
        };
    }
    
  render() {

    const { africanAmericanHistory, raceAndGenderEquality, leni_banner, robotBg, turningAroundLives, videoTestimonials, celebritiesBg, corporateSocial, leaderCommunity } = this.state;

    return (
      <div className="AdoptACityComponent AdoptACityUSAComponent">
        <Header />
            
            <div className="titleContainer">
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h3>Associate your brand with Compton Rising</h3>
                            <h3 style={{fontSize: '2rem'}}>BECOME A CORPORATE SPONSOR</h3>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="titleContainer firstContainer one" style={{backgroundImage: 'url('+ africanAmericanHistory +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <h1>
                                Associate your brand with<br/>
                                <span className="bold">400 years of African American History</span>
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="titleContainer firstContainer two" style={{backgroundImage: 'url('+ raceAndGenderEquality +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <h1 style={{color : "#ff005f"}}>
                                Associate your brand with<br/>
                                <span className="bold">Race & Gender Equality</span>
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>
            
            <div className="titleContainer firstContainer three" style={{backgroundImage: 'url('+ leni_banner +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 6 }} style={{textAlign: 'right', padding: '14vh 0'}}>
                            <h1 style={{color : "#fff"}}>
                                Associate your brand with<br/>
                                <Media style={{maxWidth: '400px', width: '100%', margin: '10px 0'}} object src={ungc_logo} alt="ungc logo" />
                                <br/>
                                Quality Education
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>
            
            <BannerWithText playPress={true} bannerData={this.state.bannerData} />

            <div className="titleContainer firstContainer four" style={{backgroundImage: 'url('+ robotBg +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6}} style={{textAlign: 'left', padding: '25vh 0'}}>
                            <h1 style={{color : "#282742"}}>
                                Associate your brand with<br/>
                                <span className="bold">TECHNOLOGY</span>
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="titleContainer firstContainer five" style={{backgroundImage: 'url('+ turningAroundLives +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6}} style={{textAlign: 'left', padding: '25vh 0'}}>
                            <h1 style={{color : "#ff3e3e"}}>
                                Associate your brand with<br/>
                                <span className="bold">Turning around lives</span>
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="titleContainer">
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h3>Here is what you get if you become</h3>
                            <h3 style={{fontSize: '2rem'}}>A CORPORATE SPONSOR</h3>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="titleContainer firstContainer six" style={{backgroundImage: 'url('+ videoTestimonials +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }} style={{paddingTop: '30vh'}}>
                            <h1 style={{fontSize: '2.8rem'}}>
                                You will get<br/>
                                <span className="bold">mentioned in VLOGS</span><br/>
                                & video testimonials<br/>
                                created by kids
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>   

            <BannerWithText playPress={true} bannerData={this.state.bannerData2} />   

            <div className="titleContainer firstContainer seven" style={{backgroundImage: 'url('+ celebritiesBg +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 5 }} style={{paddingTop: '45vh', textAlign: 'left'}}>
                            <h1 style={{color: '#Fff'}}>
                                You will get <span className="bold">aligned with celebrities</span> who also endorse this project
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="titleContainer firstContainer eigth" style={{backgroundImage: 'url('+ corporateSocial +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 5 }} style={{paddingTop: '20vh', textAlign: 'left'}}>
                            <h1 style={{color: '#Fff'}}>
                                You will get a worthy <span className="bold">Corporate Social Responsibility Cause</span>
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="titleContainer firstContainer nine" style={{backgroundImage: 'url('+ leaderCommunity +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <h1 style={{color : "#203c6f"}}>
                                You will get<br/>
                                <span className="bold">greater presence</span><br/>
                                as a leader in the community
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="titleContainer">
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h3 style={{fontSize: '2rem'}}>You will get all of this if you become</h3>
                            <h3 style={{fontSize: '3.2rem'}}>A CORPORATE SPONSOR</h3>
                            <h3 style={{fontSize: '2rem'}}>for $250,000</h3>
                            <ul style={{listStyleType: 'none', paddingTop: '50px', paddingLeft: 0}}>
                                <li><a href="tel:4242231519">(424) 223 1519</a></li>
                                <li><a href="tel:9496131100">(949) 613 1100</a></li>
                                <li><a href="tel:9492455239">(949) 245 5239</a></li>
                                <li><a href="tel:9499295654">(949) 929 5654</a></li>
                                <li><a href="tel:3103468390">(310) 346 8390</a></li>
                                <li><a href="mailto:hello@comptonrising.org">hello@comptonrising.org</a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>


            {/* <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ git2 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{textAlign: 'left'}}>
                        <Col md={{ size: 12 }}>
                            <h1>
                            Get in touch with us by choosing a pledge to support
                            </h1>
                            <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button className="yellow" color="warning" size="lg">Choose a pledge</Button></a>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>

                    </Row>
                </Container>
            </div>

            
            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ adoptcity1 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{textAlign: 'left'}}>
                        <Col md={{ size: 12 }}>
                            <h1>
                            Get in touch with us by choosing a city to adopt
                            </h1>
                            <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>

                    </Row>
                </Container>
            </div> */}

            
            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ git3 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{textAlign: 'left'}}>
                        <Col md={{ size: 12 }}>
                            <h1 style={{
                                textTransform: 'uppercase',
                                fontSize: '3.5rem',
                                fontWeight: 'bold',
                                margin: '10vh 0',
                                textShadow: '5px 8px 20px #000',
                                color: '#fff'
                            }}>
                                How can we help you?
                            </h1>
                            <a href="https://form.assessmentfund.com/ODY5OA==" target="_blank"><Button className="yellow" color="warning" size="lg">Get in touch</Button></a>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>

                    </Row>
                </Container>
            </div>

        
        <Footer />
      </div>
    );
  }
}

export default AdoptACityUSAComponent;
