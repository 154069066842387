import { combineReducers } from 'redux';
import websiteReducer from './websiteReducer';


console.log = function() {
    
}

export default combineReducers({
    webRed : websiteReducer
});