import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "../../public/scss/Footer.scss";

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <Container
          className="text-center text-md-left"
          style={{ padding: "100px 0" }}
        >
          <Row>
            <Col md={{ size: 4 }}>
              <h5 className="title">Hirelabs Assessments</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="/#/assessments/methodology">Methodology</a>
                </li>
                <li className="list-unstyled">
                  <a href="/#/assessments/validity-reliability">Validity & Reliability</a>
                </li>
                <li className="list-unstyled">
                  <a href="/#/assessments/reports-analytics">Reports & Analytics</a>
                </li>
                <li className="list-unstyled">
                  <a href="https://form.assessmentfund.com/ODY4NQ==" target="_new">TESDA (Train the trainer)</a>
                </li>
                <li className="list-unstyled">
                  <a href="/#/grant-form-reports">Grant deal flow</a>
                </li>
                <li className="list-unstyled">
                  <a href="http://info.lincolnmethod.com/NTU-results/" target="_new">Textile Industry Survey</a>
                </li>
                <li className="list-unstyled">
                  <a href="/#/dashboard">Regional Dashboard</a>
                </li>
                <li className="list-unstyled">
                  <a href="/#/skill/Innovation">City Dashboard</a>
                </li>
              </ul>
            </Col>
            <Col md={{ size: 3, offset: 1 }}>
              <h5 className="title">About Us</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="/#/objectives">Our Objectives</a>
                </li>
                <li className="list-unstyled">
                  <a href="/#/21st-century-skills">21st Century Skills</a>
                </li>
              </ul>
            </Col>
            <Col md={{ size: 3, offset: 1 }}>
              <h5 className="title">Contact Us</h5>
              <ul>
                <li className="list-unstyled">
                  <a href="https://form.assessmentfund.com/ODY5OA==" target="_blank">Get in touch</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
