import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Media,
  Collapse,
  CardBody,
  Card
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import faq1 from './../../../public/images/grant/faq1.jpg';
import faq2 from './../../../public/images/grant/faq2.jpeg';
import faq3 from './../../../public/images/grant/faq3.jpeg';
import faq4 from './../../../public/images/grant/faq4.jpeg';
import faq5 from './../../../public/images/grant/faq5.jpeg';
import faq6 from './../../../public/images/grant/faq6.jpeg';

import conference_1 from './../../../public/images/grant/conference_1.jpg';
import conference_2 from './../../../public/images/grant/conference_2.jpg';
import conference_3 from './../../../public/images/grant/conference_3.jpg';
import conference_4 from './../../../public/images/grant/conference_4.jpg';
import conference_5 from './../../../public/images/grant/conference_5.jpg';
import conference_6 from './../../../public/images/grant/conference_6.jpg';
import conference_7 from './../../../public/images/grant/conference_7.jpg';
import conference_8 from './../../../public/images/grant/conference_8.jpg';
import conference_9 from './../../../public/images/grant/conference_9.jpg';
import conference_10 from './../../../public/images/grant/conference_10.jpg';
import conference_11 from './../../../public/images/grant/conference_11.jpg';
import conference_12 from './../../../public/images/grant/conference_12.jpg';
import conference_13 from './../../../public/images/grant/conference_13.jpg';
import conference_14 from './../../../public/images/grant/conference_14.jpg';
import conference_15 from './../../../public/images/grant/conference_15.jpg';
import conference_16 from './../../../public/images/grant/conference_16.jpg';
import conference_17 from './../../../public/images/grant/conference_17.jpg';
import conference_18 from './../../../public/images/grant/conference_18.jpeg';
import conference_19 from './../../../public/images/grant/conference_19.jpg';
import conference_20 from './../../../public/images/grant/conference_20.jpg';
import conference_21 from './../../../public/images/grant/conference_21.jpg';
import conference_22 from './../../../public/images/grant/conference_22.jpg';
import conference_23 from './../../../public/images/grant/conference_23.jpg';
import conference_24 from './../../../public/images/grant/conference_24.jpg';
import conference_25 from './../../../public/images/grant/conference_25.jpg';
import conference_26 from './../../../public/images/grant/conference_26.jpg';
import conference_27 from './../../../public/images/grant/conference_27.jpg';
import conference_28 from './../../../public/images/grant/conference_28.jpg';
import conference_29 from './../../../public/images/grant/conference_29.jpg';
import conference_30 from './../../../public/images/grant/conference_30.jpg';
import conference_31 from './../../../public/images/grant/conference_31.jpg';
import conference_32 from './../../../public/images/grant/conference_32.jpg';
import conference_33 from './../../../public/images/grant/conference_33.jpg';
import conference_34 from './../../../public/images/grant/conference_34.jpg';
import conference_35 from './../../../public/images/grant/conference_35.jpg';
import conference_36 from './../../../public/images/grant/conference_36.jpg';
import conference_37 from './../../../public/images/grant/conference_37.jpg';
import conference_38 from './../../../public/images/grant/conference_38.jpg';
import conference_39 from './../../../public/images/grant/conference_39.jpg';
import conference_40 from './../../../public/images/grant/conference_40.jpg';
import conference_41 from './../../../public/images/grant/conference_41.jpg';

// few clients' logo
// import cc_logo from './../../../public/images/clients/cc_logo.jpg';
// import efp_logo from './../../../public/images/clients/efp_logo.jpg';
// import iue_logo from './../../../public/images/clients/iue_logo.jpg';


import Header from './../Header';
import Footer from './../Footer';

import TextWithImage from './../snippets/TextWithImage';

import exceprt_bg from './../../../public/images/exceprt_bg.png';

class FaqComponent extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        collapseState1 : false,
        collapseState2 : false,
        collapseState3 : false,
        collapseState4 : false,
        collapseState5 : false,
        collapseState6 : false,
        collapseState7 : false,
        collapseState8 : false,
        collapseState9 : false,
        collapseState10 : false,
        collapseState11 : false,
        collapseState12 : false,
        photoIndex: 0,
        isOpen: false,
        conferenceList : [
            {
                date : "Oct 14, 2014",
                presentation : "14TH NATIONAL PESO CONGRESS",
                details : "Introduction of PTMI to the Public Employment Service Officers of the Philippines"
            },{
                date : "Mar 21, 2015",
                presentation : "LEAGUE OF CITIES OF THE PHILIPPINES",
                details : "Introduction of the PTMI to all Mayors"
            },{
                date : "Mar 29, 2015",
                presentation : "K-12 ORIENTATION for TVIs by TESDA",
                details : "Introduction of PTMI to TVIs"
            },{
                date : "May 16, 2015",
                presentation : "37th APEC Human Resources Working Group",
                details : "Pilot results shared to other economies as representatives for the Philippine delegation"
            },{
                date : "Aug 27, 2015",
                presentation : "5th NATIONAL EDUCATION FORUM",
                details : "shared pilot results with PRC,PMAP, EDC et al."
            },{
                date : "Oct 22, 2015",
                presentation : "WORLD ASSOCIATION OF PUBLIC EMPLOYMENT SERVICES",
                details : "Pilot results shared with government employment representatives from the world"
            },{
                date : "Dec 2015 - present",
                presentation : "Jobstart Series of Presentations",
                details : "PTMI is the Online Skills Assessment for JobStart - a DOLE program for out of school and unemployed youth"
            },{
                date : "May 26, 2016",
                presentation : "National Career Advocacy Congress",
                details : "PTMI part of STEP discussion"
            },{
                date : "July 14, 2016",
                presentation : "37th NATIONAL CONGRESS OF EMPLOYERS",
                details : "shared updated results with TESDA, EMPLOYERS and Academic Institutions"
            },{
                date : "Aug 16, 2016",
                presentation : "ECOP &PCCI General Board Meeting",
                details : "discussed PTMI and Jobstart"
            },{
                date : "Sep 1, 2016",
                presentation : "PCCI Regional Board Presentation",
                details : "shared results to regional board heads"
            },{
                date : "Sep 20, 2016",
                presentation : "Region 3 PESO Managers",
                details : "shared partial results and coordinated for roll-out"
            },{
                date : "Oct 13, 2016",
                presentation : "16th NATIONAL PESO CONGRESS",
                details : "shared updates with PESO, ILO, World Bank-STEP"
            },{
                date : "Dec 21, 2016",
                presentation : "DOLE Year End Accomplishment Report",
                details : "presentation of results to Media"
            },{
                date : "Jan 26, 2017",
                presentation : "CDAP's 39th ANNUAL CONVENTION",
                details : "shared results with PBED to all career development members"
            },{
                date : "Mar - Jun 2017",
                presentation : "DOLE Trainers Training",
                details : "Trained TSSD/Regional/Field and PESO officers for PTMI"
            },{
                date : "Mar 14, 2017",
                presentation : "INVESTORS IN PEOPLE PRESENTATION",
                details : "shared presentation to IIP Member companies"
            },{
                date : "Mar 30, 2017",
                presentation : "Association of Human Resource Managers in the Hospitality Industry (AHRM",
                details : "presented partial results during their quarterly meeting"
            },{
                date : "May 1, 2017",
                presentation : "Signing of New MOU with Secretary",
                details : "May 1 Job Fair"
            },{
                date : "Jun 6, 2017",
                presentation : "35th BLE Founding Anniversary",
                details : "received award for PTMI and PJN partnership"
            },{
                date : "July 26, 2017",
                presentation : "MOU signing with PCCI, ECOP",
                details : "Signing during DOLE Sr. Management Meeting"
            },{
                date : "Oct 5, 2017",
                presentation : "17th PESO Congress",
                details : "awarded top LGUs"
            },{
                date : "Nov 23, 2017",
                presentation : "WB Report - Developing Socioemotional Skills for the Philippines' Labor Market",
                details : "presented results with DOLE, DepEd, CHED and World Bank on how PTMI skills complemented STEP"
            },{
                date : "Nov 29, 2017",
                presentation : "CAR Employers Forum",
                details : "Discussed PTMI to Employers of CAR"
            },{
                date : "Jan 24, 2018",
                presentation : "DOLE Technical Working Group Mtg",
                details : "Presented updated results to Asec, Usec, PRC, TESDA, UNIFAST, et al"
            },{
                date : "Sep 21, 2018",
                presentation : "SDC -Technical Board, NEDA",
                details : "discussed final results to Social Development Council (Under Secretaries and Bureau heads) for the National Economic Development Authority"
            },{
                date : "Sep 25, 2018",
                presentation : "NSRP Presentation  - DOLE RO and TSSD",
                details : "Presented final results to DOLE Regional and Technical Officials"
            },{
                date : "Oct 9, 2018",
                presentation : "Mindanao Development Authority",
                details : "With World bank, discussed results of the TalentMap"
            },{
                date : "Oct 25, 2018",
                presentation : "18 th National PESO Congress",
                details : "Presented Final Results to PESO, DOLE Officials, elected officials and even the daughter of the President"
            },{
                date : "Nov 16, 2018",
                presentation : "Presentation of Results to DOLE Officials and ILS",
                details : "Presented final results for recommendations"
            },{
                date : "Nov 20, 2018",
                presentation : "NEDA's SDC National Regional Consultative Meeting",
                details : "Presented Results with other government agencies, represented DOLE"
            },{
                date : "Nov 28, 2018",
                presentation : "7th DOLE Research Conference",
                details : "Presented final results of PTMI"
            },{
                date : "Dec. 17, 2018",
                presentation : "DOLE Year End Accomplishment Report",
                details : "Presented final results of PTMI"
            },{
                date : "Jan 10, 2019",
                presentation : "DOLE Technical Working Group Mtg",
                details : "Presented final results to PRC, ECOP, FCGANP, CHED"
            },{
                date : "Feb 8, 2019",
                presentation : "GAIN 2nd National Convention",
                details : "Presented final results to the Government, Academe, Industry Network Members"
            },{
                date : "Feb 27, 2019",
                presentation : "UNIFAST Technical Board Meeting",
                details : "Presented results to UNIFAST BOARD - member are CHED heads, school board association heads"
            },{
                date : "Mar 11, 2019",
                presentation : "1st Quarter National Directorate  Commission En Banc Meeting",
                details : "Presented final results to CHED TOP Officials"
            },{
                date : "Mar 20, 2019",
                presentation : "SFI Career Center - Media Launch",
                details : "Presented results to Media and Partners"
            },{
                date : "Apr 15, 2019",
                presentation : "Philippine Statistics Authority",
                details : "technical meeting with NEDA, DOLE and SFI to inculcate survey to Philippine Labor Studies Surveys"
            },{
                date : "Jun 4, 2019",
                presentation : "CGAP-WG PTMI Presentation",
                details : "Presented results to CGAP working Group"
            },{
                date : "Jul 25, 2019",
                presentation : "EDC-EQI Presentation",
                details : "Presented results to ECOP, PCCI, Gov and Member Orgs"
            },{
                date : "Jul 31, 2019",
                presentation : "DOLE - Jobsfit Report 2022",
                details : "discussed PTMI Results during the Forum"
            },{
                date : "Oct 2, 2019",
                presentation : "discussion of Data Validation",
                details : "Data Validated by ILS"
            },{
                date : "Oct 4, 2019",
                presentation : "PTMI Results Discussion for TESDA",
                details : "MOU for TESDA signing this November 2019"
            },{
                date : "Oct 14, 2019",
                presentation : "8th Philippine Professional Summit",
                details : "Presentation of Results to PRC members, signing of 44 member boards November 2019"
            },{
                date : "Oct 15, 2019",
                presentation : "DepEd: Conference on Sr. High School Input to Policy Formulation",
                details : "Presented validated results to DepEd Sr. High School unique track representatives"
            },{
                date : "Oct 17, 2019",
                presentation : "TalentMap Grant presented at Employer Federation of Pakistan Forum",
                details : "Presented results to Employer Federation of Pakistan along with TalentMap grant"
            },{
                date : "Nov 21, 2019",
                presentation : "CDAP 42nd Convention",
                details : "Presentation of PTMI Validated Results and how it aids in recruitment"
            }
        ]
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

    openLighBox(index) {
        this.setState({
            photoIndex: index,
            isOpen: true
        })
    }

  expandCollapse = (this_state, do_this) => {
      this.setState({
          [this_state] : do_this
      })
  }

  render() {

    const { photoIndex, isOpen } = this.state;
    const marketingVideos = [
        {
            id: "aQgoqA6bSFw"
        },{
            id: "Pzv7WIgvx70"
        },{
            id: "ev78QQlReGM"
        },{
            id: "cgNAJcsztxk"
        },{
            id: "gdc9bNfMm_4"
        },{
            id: "Hckaaf6_7Og"
        },{
            id: "jamT1WPbn0Y"
        },{
            id: "0OG3pSRXEh4"
        },{
            id: "IyKG706zVAU"
        },{
            id: "RSN802auG1M"
        },{
            id: "TCOl8C3hAtw"
        },{
            id: "Cgt7WIc_XEc"
        },{
            id: "OmwSybrDUQk"
        },{
            id: "m3KvAmedoXc"
        },{
            id: "60Ask02hI4k"
        },{
            id: "6_09SDq3_u0"
        },{
            id: "GkM6Dt2ynNY"
        },{
        //     id: "akuXZ4fgyBU"
        // },{
            id: "BibqzkDpr3U"
        },{
            id: "edc1rEB1rwo"
        },{
            id: "IQG0VjhDolA"
        },{
            id: "dXLVGYuL5ew"
        },{
            id: "UZpFtJAaj78"
        },{
            id: "JL2cgfV63Jo"
        }
    ];

    const marketingVideosCustom = [{
        url : "http://info.lincolnmethod.com/wp-content/uploads/2020/02/adoptaCity.mp4"
    },{
        url : "http://info.lincolnmethod.com/wp-content/uploads/2020/02/AmBisyon.mp4"
    },{
        url : "http://info.lincolnmethod.com/wp-content/uploads/2020/02/BridgeTheSkillGap.mp4"
    }];

    const conferenceImages = [
        {
            url : conference_1,
            title : ""
        },{
            url : conference_2,
            title : ""
        },{
            url : conference_3,
            title : ""
        },{
            url : conference_4,
            title : ""
        },{
            url : conference_5,
            title : ""
        },{
            url : conference_6,
            title : ""
        },{
            url : conference_7,
            title : ""
        },{
            url : conference_8,
            title : ""
        },{
            url : conference_9,
            title : ""
        },{
            url : conference_10,
            title : ""
        },{
            url : conference_11,
            title : ""
        },{
            url : conference_12,
            title : ""
        },{
            url : conference_13,
            title : ""
        },{
            url : conference_14,
            title : ""
        },{
            url : conference_15,
            title : ""
        },{
            url : conference_16,
            title : ""
        },{
            url : conference_17,
            title : ""
        },{
            url : conference_18,
            title : ""
        },{
            url : conference_19,
            title : ""
        },{
            url : conference_20,
            title : ""
        },{
            url : conference_21,
            title : ""
        },{
            url : conference_22,
            title : ""
        },{
            url : conference_23,
            title : ""
        },{
            url : conference_24,
            title : ""
        },{
            url : conference_25,
            title : ""
        },{
            url : conference_26,
            title : ""
        },{
            url : conference_27,
            title : ""
        },{
            url : conference_28,
            title : ""
        },{
            url : conference_29,
            title : ""
        },{
            url : conference_30,
            title : ""
        },{
            url : conference_31,
            title : ""
        },{
            url : conference_32,
            title : ""
        },{
            url : conference_33,
            title : ""
        },{
            url : conference_34,
            title : ""
        },{
            url : conference_35,
            title : ""
        },{
            url : conference_36,
            title : ""
        },{
            url : conference_37,
            title : ""
        },{
            url : conference_38,
            title : ""
        },{
            url : conference_39,
            title : ""
        },{
            url : conference_40,
            title : ""
        },{
            url : conference_41,
            title : ""
        }
    ];

    return (
      <div className="FaqComponent" >
        <Header />

            {isOpen && (
                <Lightbox
                    mainSrc={conferenceImages[photoIndex].url}
                    nextSrc={conferenceImages[(photoIndex + 1) % conferenceImages.length].url}
                    prevSrc={conferenceImages[(photoIndex + conferenceImages.length - 1) % conferenceImages.length].url}
                    imageCaption={conferenceImages[photoIndex].title}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + conferenceImages.length - 1) % conferenceImages.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % conferenceImages.length,
                    })
                    }
                />
            )}

            <div className="pageHeader" style={{backgroundImage: 'url('+ exceprt_bg +')', padding: '10vh 0'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 12 }}>
                            <h3>Grant FAQs</h3>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div style={{padding: '8vh 0'}}>
                <Container className="contentBox" style={{textAlign: 'left'}}>
                    <p>Click to expand the answers</p>
                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                What methodology is used to create the assessment?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState1', !this.state.collapseState1)} className="expandIcon" icon={!this.state.collapseState1 ? faPlus : faMinus} size="1x" />
                            </Button>
                            <Collapse isOpen={this.state.collapseState1}>
                                <Card>
                                    <CardBody>
                                        HireLabs has developed a 9-step overview of how the assessments are being customized. You can see the detailed steps here: <a href="http://talentmap.ai/#/assessments/methodology" target="_new">http://talentmap.ai/#/assessments/methodology</a>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                What validity checks have been used to ensure the integrity of the assessments?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState2', !this.state.collapseState2)} className="expandIcon" icon={!this.state.collapseState2 ? faPlus : faMinus} size="1x" />
                            </Button>
                            <Collapse isOpen={this.state.collapseState2}>
                                <Card>
                                    <CardBody>
                                    HireLabs has constructed 4 validity and reliability checks to ensure the integrity of the assessments. You can see a detailed description and report here: <a href="http://www.testedtalent.com/validity-reliability/" target="_new">http://www.testedtalent.com/validity-reliability/</a>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                Which independent bodies have validated the assessment?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState3', !this.state.collapseState3)} className="expandIcon" icon={!this.state.collapseState3 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState3}>
                                <Card>
                                    <CardBody>
                                    <Media style={{maxWidth: '80%', cursor: 'pointer', margin: '15px 0'}} object src={conference_15} /><br/>

                                    The research has been validated by Institute for Labor Studies (ILS), an attached agency of the Department of Labor and Employment (DOLE). 
                                    The contact person for this body is Director Ahmma Charisma Lobrin-Satumba and Paulina Kim C. Pacete <strong>(pkcpacete@ils.dole.gov.ph)</strong>, Supervising Labor and Employment Officer Employment Research Division (ERD) at ILS.
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                What types of use cases are there where these assessments played a role?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState4', !this.state.collapseState4)} className="expandIcon" icon={!this.state.collapseState4 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState4}>
                                <Card>
                                    <CardBody>
                                        <strong>The assessments can be used for the following:</strong>
                                        <ul>
                                            <li>University Graduate Recruitments (within a school/university)  – can be conducted by the company’s own HR</li>
                                            <li>Internal Recruitment Drives (within a company) – can be conducted by the company’s own HR</li>
                                            <li>Assessing existing team (within a company) – can be conducted by company’s own HR</li>
                                            <li>Assessing for Promotions/Appraisals (within a company) – can be conducted by company’s own HR</li>
                                            <li>Head Hunting (hiring new recruits for the a third party) – can be conducted by external recruiting agencies</li>
                                            <li>Nation Building (talent mapping for cities/countries)</li>
                                        </ul>
                                        To check the use cases of the different competency framework, please see the following link: <a href="http://www.testedtalent.com/competency-frameworks/" target="_new">http://www.testedtalent.com/competency-frameworks/</a>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                What types of reports can we print out?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState5', !this.state.collapseState5)} className="expandIcon" icon={!this.state.collapseState5 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState5}>
                                <Card>
                                    <CardBody>
                                        All of our analysis is presented through an easy to understand acronym, called I M A G E <br/>
                                        
                                        <Media style={{maxWidth: '100%', border: '1px #333 solid', marginBottom: '15px', margin: '3vh 0'}} object src={faq1} alt="" />

                                        <p>
                                            We have two types of reports:
                                        </p>
                                        <ol>
                                            <li>Talent Score Card (One-Page Report)
                                                <br/>
                                                <Media style={{maxWidth: '100%', border: '1px #333 solid', marginBottom: '15px', margin: '3vh 0'}} object src={faq3} alt="" />
                                            </li>
                                            <li>Talent Assessment Report (Detailed Report)
                                                <br/>
                                                <Media style={{maxWidth: '100%', border: '1px #333 solid', marginBottom: '15px', margin: '3vh 0'}} object src={faq5} alt="" />
                                                <br/>
                                                <Media style={{maxWidth: '100%', border: '1px #333 solid', marginBottom: '15px', margin: '3vh 0'}} object src={faq6} alt="" />
                                                <br/>
                                                <Media style={{maxWidth: '100%', border: '1px #333 solid', marginBottom: '15px', margin: '3vh 0'}} object src={faq4} alt="" />
                                            </li>
                                        </ol>
                                        <p>
                                            We also have interactive dashboards. <br/>
                                            <Media style={{maxWidth: '100%', border: '1px #333 solid', marginBottom: '15px', margin: '3vh 0'}} object src={faq2} alt="" />
                                        </p>
                                        <p>
                                            To view samples reports, please check the following link: <a href="http://www.testedtalent.com/reports-analytics/" target="_new">http://www.testedtalent.com/reports-analytics/</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                What types of dashboards are available?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState6', !this.state.collapseState6)} className="expandIcon" icon={!this.state.collapseState6 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState6}>
                                <Card>
                                    <CardBody>
                                        The system creates a variety of dashboards. To view them, please check out the following link: <a href="http://talentmap.report/" target="_new">http://talentmap.report/</a>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                Which companies have used the assessments?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState7', !this.state.collapseState7)} className="expandIcon" icon={!this.state.collapseState7 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState7}>
                                <Card>
                                    <CardBody>
                                        Here are some of our True Stories: <a href="http://www.testedtalent.com/category/assessments-true-stories/?post_type=casestudies" target="_new">http://www.testedtalent.com/category/assessments-true-stories/?post_type=casestudies</a>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                {/* Which conferences was the TalentMap presented at? */}
                                Talent Map was presented at more than 50 conferences. Which conferences was it presented at?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState8', !this.state.collapseState8)} className="expandIcon" icon={!this.state.collapseState8 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState8}>
                                <Card>
                                    <CardBody style={{textAlign : 'left'}}>
                                        <Container className="conferencesList">
                                            <Row className="listHeading" style={{fontWeight: 'bold'}}>
                                                <Col md={{size: 2}}>
                                                    Date
                                                </Col>
                                                <Col md={{size: 5}}>
                                                    Presentation
                                                </Col>
                                                <Col md={{size: 5}}>
                                                    Details
                                                </Col>
                                            </Row>
                                            {
                                                this.state.conferenceList.map((data, key) => 
                                                    <Row key={key}>
                                                        <Col md={{size: 2}}>
                                                            {data.date}
                                                        </Col>
                                                        <Col md={{size: 5}}>
                                                            {data.presentation}
                                                        </Col>
                                                        <Col md={{size: 5}}>
                                                            {data.details}
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Container>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                Do you have pictures for these conferences?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState9', !this.state.collapseState9)} className="expandIcon" icon={!this.state.collapseState9 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState9}>
                                <Card>
                                    <CardBody style={{textAlign : 'center'}}>
                                        <h5>TalentMap was presented at the following conferences:</h5> <br />
                                        
                                        <Row className="galleryBox">
                                            {
                                                conferenceImages.map((image, key) =>
                                                    <Col key={key} md={{ size: 3 }}>
                                                        <Media style={{maxWidth: '100%', cursor: 'pointer', margin: '15px 0'}} onClick={() => this.openLighBox(key)} object src={image.url} alt={image.title} />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                Do you have marketing videos?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState10', !this.state.collapseState10)} className="expandIcon" icon={!this.state.collapseState10 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState10}>
                                <Card>
                                    <CardBody style={{textAlign : 'center'}}>
                                        
                                        <Row className="videoBox">
                                            {
                                                marketingVideos.map((video, key) =>
                                                    <Col key={key} md={{ size: 4 }}>
                                                        <iframe style={{margin: '10px 0', border: '1px #ccc solid'}} width="100%" height="180" src={"https://www.youtube.com/embed/" + video.id} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </Col>
                                                )
                                            }
                                            {
                                                marketingVideosCustom.map((video, key) =>
                                                    <Col key={ marketingVideos.length + key} md={{ size: 4 }}>
                                                        <video width="100%" controls>
                                                            <source type="video/mp4" src={video.url} />
                                                        </video>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                Who are the clients for HireLabs assessments?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState11', !this.state.collapseState11)} className="expandIcon" icon={!this.state.collapseState11 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState11}>
                                <Card>
                                    <CardBody>
                                        
                                        <Row>
                                            <Col className="ourClients">
                                                {/* <iframe width="100%" height="3000" src="http://www.testedtalent.com/clients/?react=yes" frameBorder="0"></iframe> */}
                                                <div className="col-md-12">
                                                <h5>HireLabs assessments have been used by the following clients:</h5><br />
                                                
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Telecom</h2>                
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/mobilink_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Mobilink</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ufone_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Ufone</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Performance appraisal assessment, Training workshops</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/du_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Du Telecom</p>
                            <p><strong>Since: </strong>2012</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/zong_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Zong</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/telefonica_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Telefonica</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Competency mapping, Job description development</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/qubee_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Qubee</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/warid_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Warid</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/nokia_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Nokia</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Executive search, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/at&t_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>AT&T</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Competency mapping</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Pharma & Healthcare</h2>                
                    </div>
                </div>
                

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/gsk_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>GSK</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/atco_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>ATCO</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/pharmaevo_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>PharmEvo</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/hetero_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Hetero</p>
                            <p><strong>Since: </strong>2012</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Automotive & Aerospace</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ford_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Ford</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Competency mapping, Job description development</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/gm_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>GM</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Competency mapping, Job description development</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/bmw_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>BMW</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/vw_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>VW</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/saudi_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Saudi Airlines</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Performance appraisal assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/zambian_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Zambian Airways</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Job description development, Performance appraisal assessment</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ethopian_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Ethiopian Airlines</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Job description development, Performance appraisal assessment</p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Power, Oil & Gas</h2>                
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/asiapatrol_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Asia Petroleum</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/skf_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>SKF</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/shell_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Shell</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/alfalah_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Al-Fatah Water & Power</p>
                            <p><strong>Since: </strong>2013</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Talent Management</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/kelly_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Kelly Services</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment, Job Board</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/monitor_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Monitor Group</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/mihnati_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Mihnati</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/clean_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Clean Journey</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/after_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>After College</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/iwantanew_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>IWantANewJob</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/bekshire_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Berkshire Associates</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/hta_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>HTA</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/aljadara_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Al-Jadara</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/hrm_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>HRM Club</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/white_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>White Systems</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Assessment Center</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/octara_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Octara</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Assessment Center</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/hire_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Hire Dimensions</p>
                            <p><strong>Since: </strong>2012</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment, Job Board</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/acentria_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Acentria</p>
                            <p><strong>Since: </strong>2012</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment, Job Board, Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/risk_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Risk Discovered</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/iandb_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>I & B</p>
                            <p><strong>Since: </strong>2018</p>
                            <p><strong>Services: </strong>Competency Mapping, Online Training Tool</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/evopro_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Evo Pro</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/olivestouch_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Olives touch</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/enfra_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Enfra share</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Pre-employment Assessment for batch hiring</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/dole_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Philipines Department of Labor and Employment</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src={cc_logo} />
                        </div>
                        <div className="col-xs-6">
                            <p>CareerCenter</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div> */}
                </div>




                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Retail</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/tiffany_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Tiffany</p>
                            <p><strong>Since: </strong>2007</p>
                            <p><strong>Services: </strong>Competency mapping</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/p7g_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>P&G</p>
                            <p><strong>Since: </strong>2009</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/alsawani_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Al-Sawani</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/buy_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Buymax</p>
                            <p><strong>Since: </strong>2007</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Food & Beverage</h2>                
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/star_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Starbucks</p>
                            <p><strong>Since: </strong>2007</p>
                            <p><strong>Services: </strong>Competency mapping</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/dunkin_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Dunkin Donuts</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Competency mapping</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/subway_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Subway</p>
                            <p><strong>Since: </strong>2008</p>
                            <p><strong>Services: </strong>Pre-employment Assessment</p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/butlers_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Butlers Chocolate</p>
                            <p><strong>Since: </strong>2010</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ebm_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>English Biscuit Manufacturers</p>
                            <p><strong>Since: </strong>2013</p>
                            <p><strong>Services: </strong>Assessment Center, Pre-employment Assessment, Job Board</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/national_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>National Foods</p>
                            <p><strong>Since: </strong>2012</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/dalda_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Dalda</p>
                            <p><strong>Since: </strong>2012</p>
                            <p><strong>Services: </strong>Executive search, 360 Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/lals_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Lals Chocolate</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/bataweel_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Bataweel group</p>
                            <p><strong>Since: </strong>2017</p>
                            <p><strong>Services: </strong>Staffing solution</p>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Media</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ice_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Ice Animations</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/aaj_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Aaj News</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/express_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Express News</p>
                            <p><strong>Since: </strong>2011</p>
                            <p><strong>Services: </strong>Executive search</p>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Financial Sectors</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/bankalfalah_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Bank Alfalah</p>
                            <p><strong>Since: </strong>2015</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Management Trainee Program</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/askari_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Askari bank</p>
                            <p><strong>Since: </strong>2016</p>
                            <p><strong>Services: </strong>Pre-employment Assessment for batch hiring</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/jsbank_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>JS bank</p>
                            <p><strong>Since: </strong>2017</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Management Trainee Program, Batch hiring for Trade & Credit department</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/hbl_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>HBL bank</p>
                            <p><strong>Since: </strong>2017</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Management Trainee Program</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/mobilinkmicro_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Mobilink Microfinance Bank</p>
                            <p><strong>Since: </strong>2016</p>
                            <p><strong>Services: </strong>Assessment Centre, Pre-employment Assessment</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ublfunds_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>UBL Funds</p>
                            <p><strong>Since: </strong>2016</p>
                            <p><strong>Services: </strong>Pre-employment Assessment for batch hiring</p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Logistics</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/tcs_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>TCS</p>
                            <p><strong>Since: </strong>2015</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Graduate Trainee Program, Batch hiring for Sales Position</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Education</h2>                
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/tesda_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>TESDA</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Competency Framework Development</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ntu_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>National textile university</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Online Training Tool</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/bhdi_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Beverly Hills Design Institute</p>
                            <p><strong>Since: </strong>2019</p>
                            <p><strong>Services: </strong>Online Training Tool</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h2 className="categoryTitle">Others</h2>                
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/jubilie_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Jubilie Insurance</p>
                            <p><strong>Since: </strong>2017</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Graduate Trainee Program & Summer Internship Program</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/kelectric_logo.png" />
                        </div>
                        <div className="col-xs-6">
                            <p>K-electric</p>
                            <p><strong>Since: </strong>2017</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Graduate Trainee Program & Summer Internship Program, Live Assessment Centre</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/fatima_logo.png" />
                        </div>
                        <div className="col-xs-6">
                            <p>Fatima group</p>
                            <p><strong>Since: </strong>2016</p>
                            <p><strong>Services: </strong>Pre-employment Assessment, Graduate Trainee Program & Summer Internship Program</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/omanchamber_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Oman chamber of commerce & industry</p>
                            <p><strong>Since: </strong>2015</p>
                            <p><strong>Services: </strong>Assessment Centre, Pre-employment Assessment, Executive Search</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/ecommerce_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Ecommerce Sea</p>
                            <p><strong>Since: </strong>2016</p>
                            <p><strong>Services: </strong>Executive Search & Staffing Solution</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/carter_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Carter Fleming Group</p>
                            <p><strong>Since: </strong>2017</p>
                            <p><strong>Services: </strong>Staffing Solution</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-xs-12 clientBox">
                        <div className="col-xs-6">
                            <img src="http://testedtalent.com/wp-content/themes/TestedTalent/images/clients/reforma_logo.jpg" />
                        </div>
                        <div className="col-xs-6">
                            <p>Reforma</p>
                            <p><strong>Since: </strong>2014</p>
                            <p><strong>Services: </strong>Pre-employment assessment for batch hiring</p>
                        </div>
                    </div>
                </div>

            </div>

                                            </Col>
                                        </Row>
                                        
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    
                    <Row style={{paddingBottom: '1vh'}}>
                        <Col md={{size: 12}}>
                            <Button style={{textAlign: 'left'}} size="lg" color="secondary" block>
                                What were our findings from the study on Textile industry's skill needs?
                                <FontAwesomeIcon onClick={e => this.expandCollapse('collapseState12', !this.state.collapseState12)} className="expandIcon" icon={!this.state.collapseState12 ? faPlus : faMinus} size="1x" />
                            
                            </Button>
                            <Collapse isOpen={this.state.collapseState12}>
                                <Card>
                                    <CardBody>
                                        
                                        <p>
                                            Here is the industry survey link for the Textile Sector in Pakistan. <br/>
                                            <a href="http://info.lincolnmethod.com/NTU-survey/" target="_new">http://info.lincolnmethod.com/NTU-survey/</a>
                                        </p>
                                        <p>
                                        Here are the results fro the survey. <br/>
                                            <a href="http://info.lincolnmethod.com/NTU-results/" target="_new">http://info.lincolnmethod.com/NTU-results/</a>
                                        </p>
                                        
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                </Container>
            </div>

        <Footer />
      </div>
    );
  }
}

export default FaqComponent;
