import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

import store from './../../store';
import { connect } from 'react-redux';

import Header from './Header';
import Footer from "./Footer";
import FourBox from './snippets/FourBox';
import SkillContainer from './snippets/SkillContainer';

import exceprt_bg from './../../public/images/exceprt_bg.png';
import PhaseValidity from './../../public/images/validity/4PhaseValidity.png';
import skill1 from './../../public/images/skills/skill1.jpeg';
import skill2 from './../../public/images/skills/skill2.jpeg';
import skill3 from './../../public/images/skills/skill3.jpeg';
import skill4 from './../../public/images/skills/skill4.jpeg';
import skill5 from './../../public/images/skills/skill5.jpeg';
import skill6 from './../../public/images/skills/skill6.jpeg';
import skill7 from './../../public/images/skills/skill7.jpeg';
import skill8 from './../../public/images/skills/skill8.jpeg';
import skill9 from './../../public/images/skills/skill9.jpeg';
import skill10 from './../../public/images/skills/skill10.jpeg';
import skill11 from './../../public/images/skills/skill11.jpeg';
import skill12 from './../../public/images/skills/skill12.jpeg';
import skill13 from './../../public/images/skills/skill13.jpeg';
import skill14 from './../../public/images/skills/skill14.jpeg';
import skill15 from './../../public/images/skills/skill15.jpeg';
// import skill16 from './../../public/images/skills/skill16.jpeg';
// import skill17 from './../../public/images/skills/skill17.jpeg';
// import skill18 from './../../public/images/skills/skill18.jpeg';

class TwentyfirstCentrurySkillComponent extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        skillsImages: [skill1,skill2,skill3,skill4,skill5,skill6,skill7,skill8,skill9,skill10,skill11,skill12,skill13,skill14,skill15],
        SkillsData: [
            // {
            //     heading: "Creative Problem Solving",
            //     text: [
            //         "Distinguishing seemingly alike scenarios",
            //         "Logically arranging pictorial information",
            //         "Drawing conclusion from evidence",
            //         "Identifying pattern in story",
            //         "Identifying logical-fallacies in argument",
            //         "Computing solutions through visualization",
            //         "Showing visual perceptual skills",
            //         "Observing keenly for data-extraction",
            //         "Decoding statements logically",
            //         "Using lateral-thinking in situations"
            //     ],
            //     bgColor: 'rgb(255, 37, 0)',
            //     bgImage: skill1,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/EJlpzM7nmr9LGQk"
            //     },
            //     chapters: 4,
            //     videos: 46,
            //     position: "rtl"
            // },
            // {
            //     heading: "Critical Thinking",
            //     text: [
            //         "Solving criteria based problems",
            //         "Deploying right-strategy for revenue",
            //         "Inferring statements attentively",
            //         "Decoding cause-and-effect statements correctly",
            //         "Making knowledge based decisions",
            //         "Scanning statements for implicit-meanings",
            //         "Forming word analogies",
            //         "Recognising number patterns",
            //         "Identifying the correct roadmap",
            //         "Sorting jumbled words logically"
            //     ],
            //     bgColor: 'rgb(105, 158, 35)',
            //     bgImage: skill2,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/gJYx4rLq97vBAwL"
            //     },
            //     position: "ltr"
            // },
            // {
            //     heading: "Decision Making",
            //     text: [
            //         "Choosing alternatives within budget",
            //         "Recruiting the right candidate",
            //         "Conducting a need-based analysis",
            //         "Communicating sensitive information promptly",
            //         "Managing social commitments wisely",
            //         "Basing decisions on quality",
            //         "Making tough career choices",
            //         "Managing unexpected customers",
            //         "Making time-bound decisions",
            //         "Making ethically correct decisions"
            //     ],
            //     bgColor: 'rgb(46, 176, 241)',
            //     bgImage: skill3,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/916EVjrqMrWeL45"
            //     },
            //     position: "rtl"
            // },
            // {
            //     heading: "English Comprehension",
            //     text: [
            //         "Using deductive reasoning",
            //         "Analyzing passages for computation",
            //         "Making judgments from text",
            //         "Reading for data extraction",
            //         "Choosing words as-per context",
            //         "Choosing verb for sentence-completion",
            //         "Linking similar statements",
            //         "Understanding textual-clues",
            //         "Structuring a sentence correctly",
            //         "Identifying word for sentence-correction"
            //     ],
            //     bgColor: 'rgb(239, 185, 4)',
            //     bgImage: skill4,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/exq0GpNMgrnP5vg"
            //     },
            //     position: "ltr"
            // },
            // {
            //     heading: "Innovation",
            //     text: [
            //         "Visualizing existing products differently",
            //         "Managing product failure effectively",
            //         "Sharing ideas-fearlessly with others",
            //         "Displaying creativity in appearance",
            //         "Displaying experimental consumer behaviour",
            //         "Being inquisitive for solutions",
            //         "Understanding an innovative mindset",
            //         "Pursuing innovation with practicality",
            //         "Using creative-instincts for app-development",
            //         "Communicating a message unconventionally"
            //     ],
            //     bgColor: 'rgb(255, 37, 0)',
            //     bgImage: skill5,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/4MvDxqr692E6kQp"
            //     },
            //     position: "rtl"
            // },{
            //     heading: "Math Functional",
            //     text: [
            //         "Calculating working hours correctly",
            //         "Solving basic division problem",
            //         "Solving basic multiplication problem",
            //         "Interpreting mathematical data correctly",
            //         "Solving a word problem",
            //         "Calculating profit after bonuses",
            //         "Solving addition-subtraction word problem",
            //         "Solving percentage word problem",
            //         "Applying logic to mathematical-situation",
            //         "Solving multiplication-subtraction equation"
            //     ],
            //     bgColor: 'rgb(105, 158, 35)',
            //     bgImage: skill6,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/8O496mNW9rpEvMP"
            //     },
            //     position: "ltr"
            // },{
            //     heading: "Multitasking",
            //     text: [
            //         "Performing unexpected tasks efficiently",
            //         "prioritizing multiple tasks effectively",
            //         "Performing multiple duties simultaneously",
            //         "Using multitasking skills wisely",
            //         "Preferring multitasking over monotasking",
            //         "Ensuring successful task completion",
            //         "Engaging available resources wisely",
            //         "Avoiding perfection in time-crunch",
            //         "Scanning multiple information channels",
            //         "Understanding levels of multitasking"
            //     ],
            //     bgColor: 'rgb(46, 176, 241)',
            //     bgImage: skill7,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/6A4WKd75PrQEDXp"
            //     },
            //     position: "rtl"
            // },{
            //     heading: "Planning & Organizing",
            //     text: [
            //         "Gathering relevant start-up information",
            //         "Present and future budgeting",
            //         "Developing an expansion strategy",
            //         "Organizing events within budgets",
            //         "Using correct presentation tools",
            //         "Making strategic re-location plans",
            //         "Creating well organized settings",
            //         "Making goal-oriented plans",
            //         "Gauging project deadlines"
            //     ],
            //     bgColor: 'rgb(239, 185, 4)',
            //     bgImage: skill8,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/ZPLBK2bAgNkezpA"
            //     },
            //     position: "ltr"
            // },{
            //     heading: "Problem Sensitivity",
            //     text: [
            //         "Identifying immediate risk",
            //         "Deriving conclusions through behavioral-analysis",
            //         "Spotting errors by observation",
            //         "Recognizing controversial statements",
            //         "Reading facial expressions correctly",
            //         "Sensing when situation deteriorates",
            //         "Mitigating problems proactively",
            //         "Scanning information purposefully",
            //         "Filtering out exaggerated problems",
            //         "Sensing subtle nuances"
            //     ],
            //     bgColor: 'rgb(255, 37, 0)',
            //     bgImage: skill9,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/QbDK3NXee2gwpkW"
            //     },
            //     position: "rtl"
            // },{
            //     heading: "Self Motivation",
            //     text: [
            //         "Exuding will in unexpected-situations",
            //         "Setting realistic goals",
            //         "Resisting short-term temptations",
            //         "Keeping the momentum going",
            //         "Staying motivated for passion",
            //         "Staying positive during uncertainty",
            //         "Staying motivated in long-term",
            //         "Congratulating yourself for efforts",
            //         "Setting clear self-improvement goals",
            //         "Being open to new-challenges"
            //     ],
            //     bgColor: 'rgb(105, 158, 35)',
            //     bgImage: skill10,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/P9az3rY8E2lAoQZ"
            //     },
            //     position: "ltr"
            // },{
            //     heading: "Social Perceptiveness",
            //     text: [
            //         "Knowing how-to initiate conversations",
            //         "Handling aggressive customers politely",
            //         "Owning responsibility for negligence",
            //         "Identifying emotion through expression",
            //         "Being empathetic in relationships",
            //         "Approaching personal issues discreetly",
            //         "Seeking to understand others",
            //         "Approaching a new-relationship wisely",
            //         "Identifying characteristics through observation",
            //         "Identifying socially sensitive information"
            //     ],
            //     bgColor: 'rgb(46, 176, 241)',
            //     bgImage: skill11,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/W6BykNVYj7xdan5"
            //     },
            //     position: "rtl"
            // },{
            //     heading: "Stress Tolerance",
            //     text: [
            //         "Identifying high-stressor during events",
            //         "Managing last-minute stress effectively",
            //         "Staying calm during crisis",
            //         "Measuring personal stress-tolerance boundaries",
            //         "Showing resilience during uncertainty",
            //         "Staying positive after failure",
            //         "Managing workplace stress",
            //         "Managing difficult people effectively",
            //         "Staying tolerant with colleagues",
            //         "Handling unexpected financial crisis"
            //     ],
            //     bgColor: 'rgb(239, 185, 4)',
            //     bgImage: skill12,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/zVgEdN1pw7R89l6"
            //     },
            //     position: "ltr"
            // },{
            //     heading: "Teamwork",
            //     text: [
            //         "Involving employees during training",
            //         "Making decisions with consensus",
            //         "Identifying a win-win situation",
            //         "Resolving conflict through communication",
            //         "Valuing diversity in team",
            //         "Valuing team's ideas",
            //         "Building morale through appreciation",
            //         "Supporting colleagues in crisis",
            //         "Identifying team building characteristics",
            //         "Acknowledging team's contribution honestly"
            //     ],
            //     bgColor: 'rgb(255, 37, 0)',
            //     bgImage: skill13,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/gQk6yN8YK2vq8pG"
            //     },
            //     position: "rtl"
            // },{
            //     heading: "Workplace Ethics",
            //     text: [
            //         "Refunding over-billed customers",
            //         "Providing accurate employment history",
            //         "Completing tasks with sincerity",
            //         "Giving others due credit",
            //         "Following supervisor's instructions",
            //         "Maintaining confidentiality of information",
            //         "Following corporate policies",
            //         "Safeguarding company's assets honestly",
            //         "Reporting hours worked honestly",
            //         "Using company's assets ethically"
            //     ],
            //     bgColor: 'rgb(105, 158, 35)',
            //     bgImage: skill14,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/w6Wx8NwJb79bDRq"
            //     },
            //     position: "ltr"
            // },{
            // //     heading: "Leadership for Millennials",
            // //     text: ["There is a big problem in the industry- It is too large to train.<br/>- Too many employees - Constant flow of new recruits poses logistical challenges<br/>- Too many locations - Gathering everyone, or going everywhere is expensive<br/>- Accountability of learning - Employees attend trainings, but little impact on productivity<br/><br/>We has a solution: Centralize training using EdTech. Using the EdTech, learners are assessed for their weakness, then they receive a curated learning journey. this not only provides personalized attention, but it is online and has a measurable impact.<br/><br/>Have a look at what we have!"],
            // //     bgColor: 'rgba(0,0,0,0.8)',
            // //     bgImage: skill15,
            // //     button: {
            // //         label : "View Course",
            // //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/YQDmZbNpW7vKA31"
            // //     },
            // //     position: "rtl"
            // // },
            // // {
            // //     heading: "Basic Occupational Safety & Health",
            // //     text: ["Work plays a central role in people’s lives. Since most workers spend at least eight hours a day in the workplace, whether it is on a plantation, in an office, factory, etc., therefore, work environments should be safe and healthy.<br/><br/><strong>Occupational Safety & Health</strong> is a discipline with a broad scope involving many specialized fields. Through this training program, participants will be able to:<br/><br/>- Express appreciation on Occupational Safety & Health and the importance of various data and legislation relating to Occupational Health & Safety.<br/>- Understand and learn the basic concepts of Occupational Safety & Health towards an effective Occupational Safety & Health implementation in the workplace.<br/>- Recognize safety hazards and appropriate safety measures in their workplace.<br/>- Perform risks/hazards assessment, evaluation and recommend measures of control.<br/>- Know how to respond in workplace emergencies and participate during conduct of emergency drills.<br/>- Identify the prescribed administrative requirements and express commitment to Occupational Safety & Health."],
            // //     bgColor: 'rgba(0,0,0,0.8)',
            // //     bgImage: skill17,
            // //     button: {
            // //         label : "View Course",
            // //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/Qog6xrzYD2PEYbl"
            // //     },
            // //     position: "ltr"
            // // },
            // // {
            //     heading: "English Functional",
            //     text: [
            //         "Using present-tense in sentence",
            //         "Using modal-auxiliary-verb in sentence",
            //         "Using past-tense in sentence",
            //         "Identifying adjective for sentence-completion",
            //         "Using superlative-adjective in sentence",
            //         "Structuring the sentence correctly",
            //         "Selecting odd one out",
            //         "Selecting the correct antonym",
            //         "Identifying the correct synonym",
            //         "Structuring sentence by rearranging-words"
            //     ],
            //     bgColor: 'rgb(46, 176, 241)',
            //     bgImage: skill15,
            //     button: {
            //         label : "View Course",
            //         link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/wWZ4pK7KJrkVvd6"
            //     },
            //     position: "rtl"
            // }
        ]
        
    };
  }

  componentDidMount () {
      
    var dataInStore = store.getState().webRed.getCenturySkills;
    if(dataInStore && dataInStore != "" && dataInStore != "error"){ // gets data from store if it already there
        this.dataStateManagement(dataInStore);
    }

  }

  componentWillReceiveProps(nextProps, thisProps){
    
    if(this.props.getCenturySkills != nextProps.getCenturySkills) {
        this.dataStateManagement(nextProps.getCenturySkills);
    }

  }

  dataStateManagement = (dataHere) => {
    var dataArray = [];
    var skills = dataHere.Corporate;
    var skill_keys = Object.keys(skills);
    var manualIndex = 0;
    skill_keys.map((id, key) => {
        var skillHere = skills[id];
        if(skillHere.course_name.toLowerCase() != "leadership for millennials" && skillHere.course_name.toLowerCase() != "21st century skills" && skillHere.course_name.toLowerCase() != "basic occupational safety & health"){
            
            dataArray.push({
                heading: skillHere.course_name,
                text: skillHere.course_details,
                bgColor: 'rgb(255, 37, 0)',
                bgImage: this.state.skillsImages[manualIndex],
                button: {
                    label : "View Course",
                    link : "https://dashboard.lincolnmethod.com/c/skillupz/storedetail/" + id
                },
                chapters: skillHere.chapters,
                videos: skillHere.videos,
                // position: ((key + 1) % 2) == 0 ? "ltr" : "rtl"
            });
            manualIndex++;

        }
    })
    
    this.setState({
        SkillsData : dataArray
    }, () => {
        console.log(this.state.SkillsData)
    })
  }

  render() {
    return (
      <div className="TwentyfirstCentrurySkillComponent">
        
        <Header />

            <div className="pageHeader" style={{backgroundImage: 'url('+ exceprt_bg +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 12 }}>
                            <h2>21<sup>st</sup> Century Skills</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
      
          {/* <FourBox data={this.state.fourboxdata} /> */}

          <SkillContainer data={this.state.SkillsData} />
      
        <Footer />

      </div>
    );
  }
}

// export default TwentyfirstCentrurySkillComponent;

const mapStatesToProps = state => ({
    getCenturySkills: state.webRed.getCenturySkills
})
  
export default connect(mapStatesToProps)(TwentyfirstCentrurySkillComponent);
