import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./../../../public/scss/snippets/AdoptCitySpecific.scss";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class AdoptCitySpecific extends Component {
  
  constructor(props) {
    super(props);

    console.log(props)

    this.state = {
        heading: props.propData.heading,
        para: props.propData.para,
        btn_text: props.propData.btn_text,
        btn_text_url: props.propData.btn_text_url,
        btn_text2: props.propData.btn_text2,
        btn_text_url2: props.propData.btn_text_url2,
        image_url: props.propData.image_url,
        theme: props.propData.theme,
        align: props.propData.align,
        position: props.propData.position
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {
    return (
      <div className={"AdoptCitySpecific " + this.state.theme} style={{backgroundImage:'url('+ this.state.image_url +')'}} >

        <Container>
            <Row>
                <Col md={{size: 6, offset : this.state.align == 'left' ? 0 : 6}} style={{textAlign: this.state.align == 'left' ? 'left' : 'right', paddingTop : this.state.position == "bottom" ? '20vh' : '0'}}>
                    <h1>{this.state.heading}</h1>
                    <p>{this.state.para}</p>
                    
                    <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button className="yellow" color="warning" size="lg">Choose a pledge</Button></a>
                    <br/><br/>
                    <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                </Col>
            </Row>
        </Container>

      </div>
    );
  }
}

export default AdoptCitySpecific;
