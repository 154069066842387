import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
// import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { triggerFormIframe } from '../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Tooltip,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

//   import samProfile from './../../../public/images/stats/samProfile.jpg';
  import samProfileBack from './../../../public/images/stats/samProfileBack.jpg';
  import samprofilebackground from './../../../public/images/stats/samprofilebackground.png';
  import downTriangle from './../../../public/images/stats/downTriangle.png';
  import './../../../public/scss/samProfile.scss';
  
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class SamProfileComponent extends Component {

  constructor(props) {
      super(props);

      this.state = {
          data : this.props.data,
          tooltipOpen : [false, false, false, false, false, false, false, false, false, false, false, false]
      };

  }

  componentDidMount() {
    // console.log(this.state.data);
    // var tempTooltipArray = [];
    // for(var i=0; i<=7; i++){
    //     tempTooltipArray[i] = false;
    // }
    // this.setState({
    //     tooltipOpen : tempTooltipArray
    // })
  }

  componentWillReceiveProps(nextProps, thisProps) {
      
    console.log(nextProps);

  }
  
    toggleTooltip = (key) => {
        var tempArrayTooltip = this.state.tooltipOpen;
        tempArrayTooltip[key] = !tempArrayTooltip[key];
        this.setState({
            tooltipOpen : tempArrayTooltip
        }, () => {
            // console.log(this.state.tooltipOpen);
        })
    };
    
  render() {

    let dataCompelete = this.state.data;
      
    return (
        <div style={{textAlign: 'center'}}>
            
            {/* <Media style={{width: '100%'}} object src={samProfile} alt="" /> */}

            <Row className="samProfileBox" style={{backgroundImage: 'url('+ samProfileBack +')'}}>
                <Col md={{size: '8', offset: '2'}} style={{color: '#fff', fontSize: '1rem', marginBottom: '7vh'}}>
                    A SAM profile can be created for each city. The SAM profile is brokendown into 12 different archetypes, each one of which describe an individuals skill level, how actively there are looking for a job, how motivated they are excel in their career. The graph below (illustrates what our city's SAM profile could look like). if you would like a SAM profile to be created for your city please<br/><span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick=
                    {e => this.props.triggerFormIframe('info')} >schedule an appointment to meet with us</span>
                </Col>
                <Col>
                    <h3><span>S</span>killed</h3>
                    <p>Talent pool tested on 15 skills</p>
                </Col>
                <Col>
                    <h3><span>A</span>ctive</h3>
                    <p>Talent pool's jobseeking activeness</p>
                </Col>
                <Col>
                    <h3><span>M</span>otivated</h3>
                    <p>Talent pool's desired to excel in career</p>
                </Col>
            </Row>

            <Row className="samProfileSection" >
                <Col className="mainBox" style={{backgroundImage: 'url('+ samprofilebackground +')'}}>
                    <div className="sampleTag" style={{backgroundImage: 'url('+ downTriangle +')'}}
                        onClick={e => this.props.triggerFormIframe('info')}>
                        <span className="first">
                            Schdule<br/>appointment<br/>to discuss<br/>data set
                        </span>
                        <span className="second">
                            Sample<br/>Data
                        </span>
                    </div>

                    <Row className="topRow">
                        <Col>
                            {/* <Row>
                                Skill level
                            </Row> */}
                            <Row>
                                {
                                    dataCompelete.top.left.map((value, key) =>
                                        <Col key={key}>
                                            <div className="bubbleBox">
                                                <div style={{ border: (value.avg * 3) + 'px #ffb848 solid', boxShadow: '0px 0px 61px -10px #ffb848'}} className="bubble" id={"tooltip_"+(key+0)}></div>
                                                
                                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key+0]} target={"tooltip_"+(key+0)} toggle={e => this.toggleTooltip(key+0)} innerClassName="customTooltip">
                                                    <p style={{textAlign: 'left', padding: '10px',  background:'#ffb848ad'}}>
                                                        The <strong style={{textTransform: 'uppercase'}}>{value.name}</strong> profile is:<br/><strong>- ACTIVE</strong> in their job search <br/>
                                                        <strong>- NOT MOTIVATED</strong> about their career<br/>
                                                        <strong style={{textTransform: 'uppercase'}}>- {value.skilled}</strong>
                                                    </p>
                                                </Tooltip>
                                            </div>
                                            <p>{value.avg}%</p>
                                            <p>{value.name}</p>
                                            <p>{value.skilled}</p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                        <Col>
                            {/* <Row>
                                Skill level
                            </Row> */}
                            <Row>
                                {
                                    dataCompelete.top.right.map((value, key) =>
                                        <Col key={key}>
                                           <div className="bubbleBox">
                                                <div style={{ border: (value.avg * 3) + 'px #84b761 solid', boxShadow: '0px 0px 61px -10px #84b761'}} className="bubble" id={"tooltip_"+(key+3)}></div>

                                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key+3]} target={"tooltip_"+(key+3)} toggle={e => this.toggleTooltip(key+3)} innerClassName="customTooltip">
                                                    <p style={{textAlign: 'left', padding: '10px',  background:'#84b761d1'}}>
                                                        The <strong style={{textTransform: 'uppercase'}}>{value.name}</strong> profile is:<br/><strong>- ACTIVE</strong> in their job search <br/>
                                                        <strong>- MOTIVATED</strong> about their career<br/>
                                                        <strong style={{textTransform: 'uppercase'}}>- {value.skilled}</strong>
                                                    </p>
                                                </Tooltip>
                                            </div>
                                            <p>{value.avg}%</p>
                                            <p>{value.name}</p>
                                            <p>{value.skilled}</p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                    
                    <Row className="bottomRow">
                        <Col>
                            <Row>
                                {
                                    dataCompelete.bottom.left.map((value, key) =>
                                        <Col key={key}>
                                            <div className="bubbleBox">
                                                <div style={{ border: (value.avg * 3) + 'px #ef6565 solid', boxShadow: '0px 0px 61px -10px #ef6565'}} className="bubble" id={"tooltip_"+(key+6)}></div>

                                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key+6]} target={"tooltip_"+(key+6)} toggle={e => this.toggleTooltip(key+6)} innerClassName="customTooltip">
                                                    <p style={{textAlign: 'left', padding: '10px',  background:'#ef6565d6'}}>
                                                        The <strong style={{textTransform: 'uppercase'}}>{value.name}</strong> profile is:<br/><strong>- NOT ACTIVE</strong> in their job search <br/>
                                                        <strong>- NOT MOTIVATED</strong> about their career<br/>
                                                        <strong style={{textTransform: 'uppercase'}}>- {value.skilled}</strong>
                                                    </p>
                                                </Tooltip>
                                            </div>
                                            <p>{value.avg}%</p>
                                            <p>{value.name}</p>
                                            <p>{value.skilled}</p>
                                        </Col>
                                    )
                                }
                            </Row>
                            {/* <Row>
                                Skill level
                            </Row> */}
                        </Col>
                        <Col>
                            <Row>
                                {
                                    dataCompelete.bottom.right.map((value, key) =>
                                        <Col key={key}>
                                            <div className="bubbleBox">
                                                <div style={{ border: (value.avg * 3) + 'px #67b7dc solid', boxShadow: '0px 0px 61px -10px #67b7dc'}} className="bubble" id={"tooltip_"+(key+9)}></div>

                                                <Tooltip placement="right" isOpen={this.state.tooltipOpen[key+9]} target={"tooltip_"+(key+9)} toggle={e => this.toggleTooltip(key+9)} innerClassName="customTooltip">
                                                    <p style={{textAlign: 'left', padding: '10px',  background:'#67b7dcdb'}}>
                                                        The <strong style={{textTransform: 'uppercase'}}>{value.name}</strong> profile is:<br/><strong>- NOT ACTIVE</strong> in their job search <br/>
                                                        <strong>- MOTIVATED</strong> about their career<br/>
                                                        <strong style={{textTransform: 'uppercase'}}>- {value.skilled}</strong>
                                                    </p>
                                                </Tooltip>
                                            </div>
                                            <p>{value.avg}%</p>
                                            <p>{value.name}</p>
                                            <p>{value.skilled}</p>
                                        </Col>
                                    )
                                }
                            </Row>
                            {/* <Row>
                                Skill level
                            </Row> */}
                        </Col>
                    </Row>

                </Col>
            </Row>

        </div>
    );
    
  }
}

SamProfileComponent.propTypes = {
    triggerFormIframe: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
})

export default connect(mapStatesToProps, { triggerFormIframe })(SamProfileComponent);