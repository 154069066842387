import React from 'react';
import './App.css';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

//importing component for website
import HomeComponent from "./components/website/HomeComponent";
import SolutionsComponent from "./components/website/SolutionsComponent";
import ObjectivesComponent from "./components/website/ObjectivesComponent";
import PartnersComponent from "./components/website/PartnersComponent";
import TwentyfirstCentrurySkillComponent from "./components/website/TwentyfirstCentrurySkillComponent";
import AssessmentsComponent from "./components/website/AssessmentsComponent";
import FaqComponent from "./components/website/grant/FaqComponent";
import PtmiRegionalStatsComponent from "./components/stats/PtmiRegionalStatsComponent";
import IndividualRegionComponent from "./components/stats/IndividualRegionComponent";
import GrantApplicationFormStatsComponent from "./components/stats/grantApplicationStats/GrantApplicationFormStatsComponent";
import TalentMapStatsComponent from "./components/stats/talentmap/TalentMapStatsComponent";
import SkillStatsComponent from "./components/stats/talentmap/SkillStatsComponent";
import TempSchoolsComponent from "./components/TempSchoolsComponent";
import AdoptACityComponent from "./components/website/AdoptACityComponent";
import AdoptACityUSAComponent from "./components/website/AdoptACityUSAComponent";
import GetInTouchComponent from "./components/website/GetInTouchComponent";

import PagcorStatsComponent from "./components/stats/talentmap/PagcorStatsComponent";


function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={HomeComponent} />
            <Route path="/solutions" component={SolutionsComponent} />
            <Route path="/objectives" component={ObjectivesComponent} />
            <Route path="/partners" component={PartnersComponent} />
            <Route path="/21st-century-skills" component={TwentyfirstCentrurySkillComponent} />
            <Route path="/assessments/:id" component={AssessmentsComponent} />
            <Route path="/grant/faq" component={FaqComponent} />
            <Route path="/dashboard" component={PtmiRegionalStatsComponent} />
            <Route path="/region/:regionId" component={IndividualRegionComponent} />
            <Route path="/grant-form-reports" component={GrantApplicationFormStatsComponent} />
            {/* routes for statistics for generic data */}
            <Route path="/skill/:skillId" component={TalentMapStatsComponent} />
            
            {/* Route is specifically for pagCor dashboard */}
            <Route path="/branch/:branchId" component={PagcorStatsComponent} />

            {/* <Route path="/skill/:skillId" component={SkillStatsComponent} /> */}
            <Route path="/all-institutes" component={TempSchoolsComponent} />
            <Route path="/adopt-a-city-philippines" component={AdoptACityComponent} />
            <Route path="/adopt-a-city-usa" component={AdoptACityUSAComponent} />
            <Route path="/get-in-touch" component={GetInTouchComponent} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;