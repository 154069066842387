import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { getSpecificCitySkills, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg,  getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg} from '../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

  
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class CitiesListSidebarComponent extends Component {

  constructor(props) {
      super(props);

      this.state = {
          // isCitiesLoading : false,
          cityUpdateLoader : false,
          verifiedCities : [],
          allCities : [{
            cityid : 0,
            cityname: "Manila"
          }],
          filteredCities : [],
          selectedSkillId : this.props.selectedSkillId,
          selectedRegion : this.props.selectedRegion,
          citySearchText : ""
      };

  }

  componentDidMount() {

  }

  updateCity = (city, cityId) => {
    console.log('checking the component changes Faheem');
    if(!this.state.cityUpdateLoader) { // if city data is already populated so they

      this.setState({
        selectedCity : city,
        selectedCityId : cityId,
        selectedRegion : city // mapping region with city also as both are same things now
      },
      () => {
        console.log(this.state.selectedCity, this.state.selectedCityId);

        this.setState({
          cityUpdateLoader : true
        }, () => {
          
          //call selected city/region Demographic data
          this.props.getDemographicAvg(this.state.selectedSkillId, this.state.selectedRegion);
          
          //call selected city/region gender data
          this.props.getGenderClassificationAvg(this.state.selectedSkillId, this.state.selectedRegion);
          
          //call selected city/region age data
          this.props.getAgeClassificationAvg(this.state.selectedRegion);

          //call selected city/region position data
          this.props.getPositionClassificationAvg(this.state.selectedRegion);

          //call selected city/region medium data
          this.props.getMediumsClassificationAvg(this.state.selectedRegion);

          //call selected city/region transportation data
          this.props.getTransportClassificationAvg(this.state.selectedRegion);

          // calls an action for getting skills/situations against each specific city.
          this.props.getSpecificCitySkills(this.state.selectedRegion, this.state.selectedCityId);
        
        })
        
      })

    }

  }


  componentWillReceiveProps(nextProps, thisProps) {

    if(this.props.selectedSkillId != nextProps.selectedSkillId) {
      this.setState({
        selectedSkillId : nextProps.selectedSkillId
      }, () => {
        console.log("selectedSkillId: " + this.state.selectedSkillId)
      })
    }

    if(this.props.selectedRegion != nextProps.selectedRegion) {
      this.setState({
        selectedRegion : nextProps.selectedRegion
      }, () => {
        console.log("selectedRegion: " + this.state.selectedRegion)
      })
    }

    if(this.props.citiesDataResponse != nextProps.citiesDataResponse) {
      this.setState({
        allCities : nextProps.citiesDataResponse.result,
        verifiedCities : nextProps.citiesDataResponse.filteredCities
      }, () => {
        console.log(this.state.allCities);
        console.log(this.state.verifiedCities);
        
        this.state.allCities.map(city => {
          if(this.state.selectedRegion == city.cityname) { // if it matches the city name with selected city/region name

            // calls an action for getting skills/situations against each specific city.
            this.props.getSpecificCitySkills(city.cityname, city.cityid);
          }
        })

      })
    }

    if(this.props.genderRegionResponse != nextProps.genderRegionResponse) {
      
      this.setState({
        cityUpdateLoader : false
      })

    }

  }

  filterCities = (e) => {
    this.setState({
      citySearchText : e.target.value
    }, () => {
      
      var cityFilterArray = [];

      this.state.allCities.map(city => {
        
        var cityName = city.cityname.toLowerCase();

        if(cityName.match(this.state.citySearchText)) {
          cityFilterArray.push(city);
        }

      })

      this.setState({
        filteredCities : cityFilterArray
      }, () => {
        console.log(this.state.filteredCities);
      })

    })
  }
  
    
  render() {

    let cities = this.state.filteredCities.length ? this.state.filteredCities : this.state.allCities;
      
    return (
      <Col md={{ size: 12 }}>
        <h6 style={{margin: '10px 0', color: '#fff', borderBottom: '1px #23344c solid', padding: '10px 3px', fontSize: '18px'}}>Select a city</h6>
        
        <FormGroup>
            <Input type="text" name="citySearchField" id="citySearchField" value={this.state.citySearchText} placeholder="Quick search" onChange={(e) => this.filterCities(e)}/>
        </FormGroup>

        <div className="mainCitiesBox" style={{color: '#fff', position: 'relative'}}>
          {
            this.state.citySearchText.length && !this.state.filteredCities.length ?
              <span style={{textAlign: 'center', width: '100%', fontSize: '12px'}}>we couldn't find any matches for "{this.state.citySearchText}"</span>
            :
            cities.map((city, key) => {

              var isCity = false;
              if(city.cityname == this.state.selectedRegion){ // compare with skill id from url
                isCity = true;
              }

              return (
                <span style={{
                  opacity : !isCity && this.state.cityUpdateLoader ? '0.4': '1',
                  color : this.state.verifiedCities.includes(city.cityid) ? "#fff" : "#636f82"
                }} key={key} className={isCity ? 'cityHere highlighted' : 'cityHere'} onClick={e => this.updateCity(city.cityname, city.cityid)}>
                  {
                    isCity && this.state.cityUpdateLoader ? 
                      <Spinner className="citySpinner" size="sm" color="light" />
                    : null
                  }
                  {city.cityname}
                </span>
              )
            })
          }  
        </div>

      </Col>
    );
    
  }
}

CitiesListSidebarComponent.propTypes = {
  // getAllCitiesData: PropTypes.func.isRequired
  getSpecificCitySkills: PropTypes.func.isRequired,
  getDemographicAvg: PropTypes.func.isRequired,
  getGenderClassificationAvg: PropTypes.func.isRequired,
  getAgeClassificationAvg: PropTypes.func.isRequired,
  getPositionClassificationAvg : PropTypes.func.isRequired,
  getMediumsClassificationAvg : PropTypes.func.isRequired,
  getTransportClassificationAvg : PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  citiesDataResponse: state.webRed.citiesDataResponse,
  genderRegionResponse: state.webRed.genderRegionResponse,
  // positionsAvgRegionResponse: state.webRed.positionsAvgRegionResponse,
  // mediumRegionResponse: state.webRed.mediumRegionResponse,
  // transportRegionResponse: state.webRed.transportRegionResponse

})

export default connect(mapStatesToProps, { getSpecificCitySkills, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg, getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg })(CitiesListSidebarComponent);