import React, { Component } from "react";
import "../../public/scss/AdoptACityComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
    Media,
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";

import BannerWithText from './snippets/bannerWithText';
import AdoptCitySpecific from './snippets/AdoptCitySpecific';

import adoptcity1 from "./../../public/images/adoptacity/1.png";
import adoptcity2 from "./../../public/images/adoptacity/2.png";
import adoptcity3 from "./../../public/images/adoptacity/3.png";
import adoptcity4 from "./../../public/images/adoptacity/4.png";
import adoptcity5 from "./../../public/images/adoptacity/5.png";
import adoptcity6 from "./../../public/images/adoptacity/6.png";
import adoptcity7 from "./../../public/images/adoptacity/7.png";
import adoptcity8 from "./../../public/images/adoptacity/8.png";
import adoptcity9 from "./../../public/images/adoptacity/9.png";
import adoptcity10 from "./../../public/images/adoptacity/10.png";

class AdoptACityComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cityBoxArray : [{
                heading : "ADOPT A CITY",
                para : "to promote quality education",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity3,
                theme : "yellow",
                align : "left",
                position : "top"
            },{
                heading : "ADOPT A CITY",
                para : "to up-skill the Filipino youth",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity4,
                theme : "yellow",
                align : "left",
                position : "bottom"
            },{
                heading : "ADOPT A CITY",
                para : "to increase EMPLOYABILITY",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity5,
                theme : "yellow",
                align : "left",
                position : "top"
            },{
                heading : "ADOPT A CITY",
                para : "to drive GENDER EQUALITY",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity6,
                theme : "yellow",
                align : "left",
                position : "bottom"
            },{
                heading : "ADOPT A CITY",
                para : "to make an IMPACT INVESTMENT",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity7,
                theme : "yellow",
                align : "right",
                position : "top"
            }],
            cityBoxArray2 : [{
                heading : "ADOPT A CITY",
                para : "to support UN SDGs",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity8,
                theme : "blue",
                align : "left",
                position : "top"
            },{
                heading : "ADOPT A CITY",
                para : "to create a shared VISION WITH THE YOUTH",
                btn_text : "",
                btn_text_url : "",
                btn_text2 : "",
                btn_text_url2 : "",
                image_url : adoptcity9,
                theme : "yellow",
                align : "left",
                position : "top"
            }],
            bannerData : {
                heading: "",
                subheading: "",
                btn_text: "Watch our kids<br/><strong>Create Vlogs</strong><br/>for their conferences",
                btn_text_url: "https://youtu.be/GZsPZJECtqw",
                video_url: "http://comptonrising.org/wp-content/uploads/2018/12/WhatsApp-Video-2018-12-07-at-10.56.29-AM.mp4",
                themeColor: "yellow"
            },
            video_url : "http://info.lincolnmethod.com/wp-content/uploads/2020/02/adoptaCity.mp4",
            video_url2 : "http://info.lincolnmethod.com/wp-content/uploads/2020/02/AmBisyon.mp4"
        };
    }
    
  render() {
    return (
      <div className="AdoptACityComponent">
        <Header />

            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ adoptcity1 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <h1>
                                Use Talentmap to<br/>
                                <span className="yellow">Adopt a city</span>
                            </h1>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>
                        <Col md={{ size: 4, offset: 2 }}>
                            <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button className="yellow" color="warning" size="lg">Choose a pledge</Button></a>
                        </Col>
                        <Col md={{ size: 4 }}>
                            <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="titleContainer">
                {
                    this.state.video_url && this.state.video_url !== '' ?
                
                    <video className="slider-video" width="100%" autoPlay controls>
                        {/* <source src={this.state.video_url} type="video/webm" /> */}
                        <source type="video/mp4" src={this.state.video_url} />
                    </video>
                
                    : null

                }
            </div>

            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ adoptcity2 +')', minHeight: '110vh', borderBottom : '10px #fff solid'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h1>
                                A CORPORATE SOCIAL<br/>RESPONSIBILITY<br/>INITIATIVE
                            </h1>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>
                        <Col md={{ size: 4, offset: 2 }}>
                            <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button className="yellow" color="warning" size="lg">Choose a pledge</Button></a>
                        </Col>
                        <Col md={{ size: 4 }}>
                            <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                this.state.cityBoxArray.map((city, key) => 
                    <AdoptCitySpecific key={key} propData={city}></AdoptCitySpecific>
                )
            }

            <BannerWithText playPress={true} bannerData={this.state.bannerData} />

            {
                this.state.cityBoxArray2.map((city, key) => 
                    <AdoptCitySpecific key={key} propData={city}></AdoptCitySpecific>
                )
            }

            <div className="titleContainer lastContainer" style={{backgroundImage: 'url('+ adoptcity10 +')'}}>
                <Container>
                    <Row>
                        <Col md={{ size: 5 }}>
                            <h1>
                                <span className="yellow">ADOPT A CITY</span>
                            </h1>
                            <p>
                                to be a part of AMBISYON 2040
                            </p>
                        </Col>
                    </Row>
                    <Row style={{padding: '3vh 0'}}>
                        <Col md={{ size: 12 }}>
                            <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button style={{marginBottom: '3vh'}} className="yellow" color="warning" size="lg">Choose a pledge</Button></a><br/>
                            <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="titleContainer">
                
                {
                    this.state.video_url2 && this.state.video_url2 !== '' ?
                
                    <video className="slider-video" width="100%" loop controls>
                        {/* <source src={this.state.video_url2} type="video/webm" /> */}
                        <source type="video/mp4" src={this.state.video_url2} />
                    </video>
                
                    : null

                }

            </div>

            
            <div className="bottomButtons" style={{marginTop: '-6px'}}>
                <Container fluid>
                    <Row style={{padding: '5vh 0', background: '#333'}}>
                        <Col md={{ size: 12 }}>
                            <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button className="yellow" color="warning" size="lg">Choose a pledge</Button></a>
                            <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                        </Col>
                    </Row>
                </Container>
            </div>
            
        
        <Footer />
      </div>
    );
  }
}

export default AdoptACityComponent;
