import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
// import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
// import { triggerFormIframe } from '../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Tooltip,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

  import { ResponsiveBar } from '@nivo/bar';

//   import samProfile from './../../../public/images/stats/samProfile.jpg';
  import samProfileBack from './../../../public/images/stats/samProfileBack.jpg';
  import samprofilebackground from './../../../public/images/stats/samprofilebackground.png';
//   import downTriangle from './../../../public/images/stats/downTriangle.png';
  import './../../../public/scss/DMEProfile.scss';
  
import devisionicon from './../../../public/images/branches/divisionIcon2.png';
import sectionicon from './../../../public/images/branches/sectionIcon2.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class DMEProfileComponent extends Component {

  constructor(props) {
      super(props);

      this.state = {
            // data : this.props.data,
            respondentsCatogorizeDMEData : '',
            
            dataMappingArray : [{
                name : 'Deficient'
            },{
                name : 'Matching'
            },{
                name : 'Exceeding'
            }],
            allBranches : this.props.allData.allBranches,
            branches : this.props.allData.branches,
            branchIndex : this.props.allData.branchIndex,
            competencyIndex : this.props.allData.competencyIndex,
            divisionIndex : this.props.allData.divisionIndex,
            sectionIndex : this.props.allData.sectionIndex,

            compComparisonDataDME : {
                keys : [],
                data : []
            },
            compComparisonDataDivisionWiseDME : {
                keys : [],
                data : []
            },
            compComparisonDataBranchWiseTempDivisionArrayDME : [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
            compComparisonDataDivisionWiseTempDivisionArrayDME : [],
            compComparisonDataSectionWiseDME : {
                keys : [],
                data : []
            },
      };

  }

  componentDidMount() {
    // console.log(this.state.data);
    // var tempTooltipArray = [];
    // for(var i=0; i<=7; i++){
    //     tempTooltipArray[i] = false;
    // }
    // this.setState({
    //     tooltipOpen : tempTooltipArray
    // })
    
    this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);

  }

  componentWillReceiveProps(nextProps, thisProps) {
      
    this.setState({
        // data : nextProps.data,

        allBranches : nextProps.allData.allBranches,
        branches : nextProps.allData.branches,
        branchIndex : nextProps.allData.branchIndex,
        competencyIndex : nextProps.allData.competencyIndex,
        divisionIndex : nextProps.allData.divisionIndex,
        sectionIndex : nextProps.allData.sectionIndex

    }, () => {
        console.log(nextProps);

        this.averageWithAllBranchesMappingDME();

        this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);

    })

  }

  DMEDataMapping = (branchIndex, competencyIndex) => {

    console.log(branchIndex, competencyIndex);
      
    const { branches } = this.state;
    // mapping of DME pie charts data starts here
    var tempArrayData = [];
    // var tempArrayKeys = [];
    // var d_array = [];
    // var d_totalNumber = 0;

    // var m_array = [];
    // var m_totalNumber = 0;
    
    // var e_array = [];
    // var e_totalNumber = 0;

    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {
        tempArrayData.push({
          "competency" : comp.name,
          "Deficient" : comp.d_total,
          "Matching" : comp.m_total,
          "Exceeding" : comp.e_total
        })
        // d_array.push({
        //     "id" : comp.name,
        //     "label" : comp.name,
        //     "value" : comp.d_total ? comp.d_total : 1
        // });
        // d_totalNumber += comp.d_total ? comp.d_total : 1;
        
        // m_array.push({
        //     "id" : comp.name,
        //     "label" : comp.name,
        //     "value" : comp.m_total ? comp.m_total : 1
        // });
        // m_totalNumber += comp.m_total ? comp.m_total : 1;
        
        // e_array.push({
        //     "id" : comp.name,
        //     "label" : comp.name,
        //     "value" : comp.e_total ? comp.e_total : 1
        // });
        // e_totalNumber += comp.e_total ? comp.e_total : 1;
    })

    setTimeout(() => {

      this.setState({
          // respondentsCatogorizeDMEData : [{
          //         name : "deificient",
          //         heading : "<span>D</span>eficient",
          //         para : "Participants whose average score lies under required competencies score",
          //         data : d_array,
          //         total : d_totalNumber,
          //     },{
          //         name : "matching",
          //         heading : "<span>M</span>atching",
          //         para : "Participants whose average score is matching the required competencies score",
          //         data : m_array,
          //         total : m_totalNumber
          //     },{
          //         name : "exceeding",
          //         heading : "<span>E</span>xceeding",
          //         para : "Participants whose average score is exceeding the required competencies score",
          //         data : e_array,
          //         total : e_totalNumber
          //     }]
          respondentsCatogorizeDMEData : {
            data : tempArrayData,
            keys : ['Deficient', 'Matching', 'Exceeding'],
            indexBy : 'competency'
          }
      },
      () => {
        console.log(this.state.respondentsCatogorizeDMEData);
      })

    }, 200);
    
    // mapping of DME pie charts data ends here

  }

  getRandomDMEValue = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
  }

  averageWithAllBranchesMappingDME = () => {

    // mapping data for comparison all branches chart for DME
    
    const { branches, branchIndex, competencyIndex, allBranches, divisionIndex, sectionIndex } = this.state;
    
    var dataArray = [];
    var tempArrayRandomVal = [];
    this.state.dataMappingArray.map((type, key) => {
        var randomValue = this.getRandomDMEValue(10, 43);
        tempArrayRandomVal.push(randomValue);
        // if(key == 1) {
        //     randomValue = this.getRandomDMEValue(10, 43);
        // }
        // if(key == 2) {
        //     randomValue = this.getRandomDMEValue(10, 43);
        // }
        dataArray.push({
            "type" : type.name,
            [ branches[branchIndex].name ] : randomValue,
            "All Branches" : key == 0 ? 36 : key == 1 ? 21 : 42
        });
    })
    
    this.setState({
        compComparisonDataDME : {
            keys : [ "All Branches", branches[branchIndex].name ],
            data : dataArray
        }
    }, () => {
        console.log(this.state.compComparisonDataDME);
        
        this.setState({
            compComparisonDataBranchWiseTempDivisionArrayDME : tempArrayRandomVal
        }, () => {
            this.averageWithBranchesMappingForDivisionsDME(); // reflects the divisions chart
        })
    })
    
  }


  averageWithBranchesMappingForDivisionsDME = () => {

    // mapping data for comparison all branches chart
    
    const { branches, branchIndex, allBranches, divisionIndex } = this.state;

    var dataArrayDivision = [];
    var tempArrayRandomVal = [];
    this.state.dataMappingArray.map((type, key) => {
      
      //temperory work done starts here
      var randomValue = this.getRandomDMEValue(10, 43);
      tempArrayRandomVal.push(randomValue);
      //temperory work done stops here

      dataArrayDivision.push({
            "type" : type.name,
            [ branches[branchIndex].divisions[divisionIndex].name ] : randomValue, // temporary random average score
            [ branches[branchIndex].name ] : this.state.compComparisonDataBranchWiseTempDivisionArrayDME[key], // temporary random average score,
            "All Branches" : key == 0 ? 36 : key == 1 ? 21 : 42
        });
    })
    
    this.setState({
      compComparisonDataDivisionWiseDME : {
            keys : [ "All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name ],
            data : dataArrayDivision
        }
    }, () => {
        console.log(this.state.compComparisonDataDivisionWiseDME);
        
        this.setState({
          compComparisonDataDivisionWiseTempDivisionArrayDME : tempArrayRandomVal
        }, () => {
          this.averageWithBranchesMappingForSectionsDME(); // reflects the sections chart
        })
    })

    
  }

  averageWithBranchesMappingForSectionsDME = () => {
    
    const { branches, branchIndex, allBranches, divisionIndex, sectionIndex } = this.state;

    var dataArraySection = [];
    this.state.dataMappingArray.map((type, key) => {
      dataArraySection.push({
            "type" : type.name,
            [ branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name ] : this.getRandomDMEValue(10, 43), // temporary random average score,
            [ branches[branchIndex].divisions[divisionIndex].name ] : this.state.compComparisonDataDivisionWiseTempDivisionArrayDME[key], // temporary random average score
            [ branches[branchIndex].name ] : this.state.compComparisonDataBranchWiseTempDivisionArrayDME[key],
            "All Branches" : key == 0 ? 36 : key == 1 ? 21 : 42
        });
    })
    
    this.setState({
      compComparisonDataSectionWiseDME : {
            keys : [ "All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name, branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name ],
            data : dataArraySection
        }
    }, () => {
        console.log(this.state.compComparisonDataSectionWiseDME);
    })
    
  }
  
    
  render() {

    // let dataCompelete = this.state.data;
    
    const { branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex } = this.state;
      
    return (
        <div style={{textAlign: 'center'}}>
            
            {/* <Media style={{width: '100%'}} object src={samProfile} alt="" /> */}

            <Row className="DMEProfileBox" style={{background: '#0d1623'}}>
                {/* <Col md={{size: '8', offset: '2'}} style={{color: '#fff', fontSize: '1rem', marginBottom: '7vh'}}>
                    A SAM profile can be created for each city. The SAM profile is brokendown into 12 different archetypes, each one of which describe an individuals skill level, how actively there are looking for a job, how motivated they are excel in their career. The graph below (illustrates what our city's SAM profile could look like). if you would like a SAM profile to be created for your city please<br/><span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick=
                    {e => this.props.triggerFormIframe('info')} >schedule an appointment to meet with us</span>
                </Col> */}

                {/* {
                    dataCompelete.map((item, key) => 
                    <Col md={{size: 12}} className="col" key={key}>
                        <h3 dangerouslySetInnerHTML={{__html: item.heading}}></h3>
                        <p style={{fontSize: '1.2rem', marginBottom: '15px'}} dangerouslySetInnerHTML={{__html: item.para}}></p>

                        <div style={{height: '500px', marginBottom: '30px'}}>
                            <ResponsivePie
                                data={item.data}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={{ scheme: key == 0 ? 'spectral' : key == 1 ? 'category10' : 'nivo' }}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor="#333333"
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                sliceLabel={function(e){return parseFloat((e.value / item.total)*100).toFixed(1) + '%'}}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#333333"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'ruby'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'c'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'go'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'python'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'scala'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'lisp'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'elixir'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'javascript'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                            />
                        </div>

                    </Col>
                    )
                } */}


                        {/* <Col md={{size: 8, offset: 1}}>
                            <p style={{color: '#fff', textAlign: 'left', marginBottom: '20px'}}>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.. 
                            </p>
                        </Col> */}

                        <Col md={{ size: 12 }}>

                            <div className="chartBox" style={{background: 'transparent'}}>
                                <div style={{height: '400px', position: 'relative'}}>

                                {/* {
                                    this.state.skillUpdateLoader ? 
                                    <div className="customSpinnerChart">
                                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                    : null
                                } */}
                                <ResponsiveBar
                                    data={this.state.compComparisonDataDME.data}
                                    keys={this.state.compComparisonDataDME.keys}
                                    indexBy="type"
                                    margin={{ top: 60, right: 100, bottom: 60, left: 100 }}
                                    padding={0.6}
                                    innerPadding={10}
                                    label={d => `${d.value}%`}
                                    // onClick={e => this.barChartClicked(e)}
                                    groupMode="grouped"
                                    colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                    // colors={["#f706d5", "#770367" ]}
                                    // colors={["#68366f", "#3d1f42" ]}
                                    layout="vertical"
                                    enableGridY= {false}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#38bcb2',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#eed312',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'fries'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'sandwich'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: 50
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: -60
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'top-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: -15,
                                        translateY: -60,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 25,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                />

                            </div>
                        </div>
                    </Col>



                    <div className="seperatorHeading">
                        Average score of {branches[branchIndex].divisions[divisionIndex].name} with in {branches[branchIndex].name}

                        <select className="customDropDown" onChange={e => {this.setState({
                            sectionIndex : 0, // reset the sectionIndex
                            divisionIndex : e.target.value
                        }, () => {
                            this.averageWithBranchesMappingForDivisionsDME();
                        })}}>
                        {
                            branches[branchIndex].divisions.map((division, key) => 
                            <option key={key} value={key}>{division.name}</option>
                            )
                        }
                        </select>

                    </div>

                    {/* <Col md={{ size: 8, offset: 1 }}>
                        
                        <p style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                        This bar chart highlights the performance for the Divisions in {branches[branchIndex].name} for each of the four main competencies and has three points of relevance and comparison:
                        <ol style={{marginTop: '20px'}}>
                            <li>Average percentage score of each of the {branches[branchIndex].totalDivisions} divisions for {branches[branchIndex].name}</li>
                            <li>Average percentage score of each Division in {branches[branchIndex].name} as compared to the {branches[branchIndex].name}'s overall scores</li>
                            <li>Average percentage score of each Division in {branches[branchIndex].name} as compared to overall result for this assessment</li>
                        </ol>
                        </p>

                    </Col> */}

                    <Col md={{ size: 9 }} style={{paddingTop: '25px'}}>
                        <div className="chartBox" style={{background: 'transparent'}}>
                            <div style={{height: '400px', position: 'relative'}}>

                                <ResponsiveBar
                                  data={this.state.compComparisonDataDivisionWiseDME.data}
                                  keys={this.state.compComparisonDataDivisionWiseDME.keys}
                                  indexBy="type"
                                  margin={{ top: 100, right: 0, bottom: 60, left: 100 }}
                                  padding={0.3}
                                  innerPadding={10}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                  // colors={["#f706d5", "#770367" ]}
                                  // colors={["#68366f", "#3d1f42" ]}
                                  layout="vertical"
                                  enableGridY= {false}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: 50
                                  }}
                                  axisLeft={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  legends={[
                                  {
                                      dataFrom: 'keys',
                                      anchor: 'top-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: -100,
                                      translateY: -100,
                                      itemsSpacing: 2,
                                      itemWidth: 150,
                                      itemHeight: 25,
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 0.85,
                                      symbolSize: 20,
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />

                            </div>
                        </div>
                    </Col>

                    <Col md={{ size: 3 }}>
                      <div className="schoolBox topSchoolBox bottomBox">
                        <Row>
                          <Col>
                          
                            <div className="oneInfo">
                              <div className="iconBox">
                                
                                <Row>
                                  <Col>
                                    <h3>{branches[branchIndex].totalDivisions}</h3>
                                    <p style={{color: '#68366f'}}>Divisions in<br/>{branches[branchIndex].name}</p>
                                  </Col>
                                  <Col>
                                    <img src={devisionicon} />
                                  </Col>
                                </Row>

                              </div>
                            </div>
                            
                            <div className="oneInfo">
                              <div className="iconBox">

                                <Row>
                                  <Col>
                                    <h3>{branches[branchIndex].divisions[divisionIndex].sections.length}</h3>
                                    <p style={{color: '#68366f'}}>Sections in<br/>{branches[branchIndex].divisions[divisionIndex].name}</p>
                                  </Col>
                                  <Col>
                                    <img src={sectionicon} />
                                  </Col>
                                </Row>
                                
                              </div>
                            </div>

                          </Col>
                        </Row>
                      </div>
                    </Col>



                    <div className="seperatorHeading">
                        Average score of {branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name} with in {branches[branchIndex].divisions[divisionIndex].name}

                        <select className="customDropDown" onChange={e => {this.setState({
                            sectionIndex : e.target.value
                        }, () => {
                            this.averageWithBranchesMappingForSectionsDME();
                        })}}>
                        {
                            branches[branchIndex].divisions[divisionIndex].sections.map((section, key) => 
                            <option key={key} value={key}>{section.name}</option>
                            )
                        }
                        </select>

                    </div>

                    {/* <Col md={{ size: 8, offset: 1 }}>
                        
                        <p style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                        This bar chart is a further breakdown of each Division and highlights the performance for the Sections in each Division in {branches[branchIndex].name} for each of the four main competencies and has four points of relevance and comparison:
                        <ol style={{marginTop: '20px'}}>
                            <li>Average percentage score of each of the Section in the selected Division for {branches[branchIndex].name}</li>
                            <li>Average percentage score of each Section as compared to the overall scores for the selected Division in {branches[branchIndex].name} as compared to the {branches[branchIndex].name}'s overall scores</li>
                            <li>Average percentage score of each Section for the selected Division as compared to the {branches[branchIndex].name}'s overall scores</li>
                            <li>Average percentage score of each Section for the selected Division in {branches[branchIndex].name} as compared to overall result for this assessment </li>
                        </ol>
                        </p>

                    </Col> */}

                    <Col md={{ size: 9 }} style={{paddingTop: '25px'}}>
                        <div className="chartBox" style={{background: 'transparent'}}>
                        <div style={{height: '500px', position: 'relative'}}>

                            <ResponsiveBar
                                data={this.state.compComparisonDataSectionWiseDME.data}
                                keys={this.state.compComparisonDataSectionWiseDME.keys}
                                indexBy="type"
                                margin={{ top: 140, right: 0, bottom: 60, left: 100 }}
                                padding={0.25}
                                innerPadding={10}
                                label={d => `${d.value}%`}
                                // onClick={e => this.barChartClicked(e)}
                                groupMode="grouped"
                                colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                                // colors={["#f706d5", "#770367" ]}
                                // colors={["#68366f", "#3d1f42" ]}
                                layout="vertical"
                                enableGridY= {false}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 50
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -60
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'top-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: -100,
                                    translateY: -135,
                                    itemsSpacing: 2,
                                    itemWidth: 150,
                                    itemHeight: 25,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            />

                        </div>
                    </div>
                </Col>

                <Col md={{ size: 3 }}>
                    <div className="schoolBox topSchoolBox bottomBox">
                    <Row>
                        <Col>
                        <div className="oneInfo">
                            <div className="iconBox">

                            <Row>
                                <Col>
                                <h3>{branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].count}</h3>
                                <p style={{color: '#68366f'}}>People<br/>in {branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name}</p>
                                </Col>
                                <Col>
                                <div style={{margin: '30px 0px 10px 0'}}>
                                    <FontAwesomeIcon className="iconHere" icon={faMale} size="2x" />
                                    <FontAwesomeIcon className="iconHere" icon={faFemale} size="2x" />
                                </div>
                                </Col>
                            </Row>
                            
                            </div>
                        </div>
                        </Col>
                    </Row>
                    </div>
                </Col>

                <Col md={{size: 12}}>
                    
                    <hr />
                    
                </Col>
                
                <Col md={{size: 8, offset: 1}}>
                    <p style={{color: '#fff', textAlign: 'left', marginBottom: '20px', borderTop: '2px #316ea1 solid', paddingTop: '15px'}}>
                        The graph below shows the frequency of Candidates in { branches[branchIndex].name } whose scores for { branches[branchIndex].mainCompetencies[competencyIndex].name } competencies, in comparison to the required level, are either deficient, matching or exceeding the benchmark. 
                    </p>
                </Col>

                <Col md={{size: 2}}>
                    <select className="customDropDown" onChange={e => {this.setState({
                        competencyIndex : e.target.value
                    }, () => {
                        this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
                    })}}>
                    {
                        branches[branchIndex].mainCompetencies.map((comp, key) => 
                        <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.name}</option>
                        )
                    }
                    </select>
                </Col>

                {
                    this.state.respondentsCatogorizeDMEData && this.state.respondentsCatogorizeDMEData != '' ?

                        <Col md={{size: 7, offset: 3}}>
                            <div style={{height: this.state.respondentsCatogorizeDMEData.data.length > 10 ? '1000px' : '400px'}}>
                                <ResponsiveBar
                                    data={this.state.respondentsCatogorizeDMEData.data}
                                    keys={this.state.respondentsCatogorizeDMEData.keys}
                                    indexBy={this.state.respondentsCatogorizeDMEData.indexBy}
                                    margin={{ top: 60, right: 0, bottom: 50, left: 100 }}
                                    padding={0.3}
                                    layout="horizontal"
                                    colors={{ scheme: 'set1' }}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#38bcb2',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#eed312',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'fries'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'sandwich'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={null}
                                    axisRight={null}
                                    // axisBottom={{
                                    //     tickSize: 5,
                                    //     tickPadding: 5,
                                    //     tickRotation: 0,
                                    //     legend: 'country',
                                    //     legendPosition: 'middle',
                                    //     legendOffset: 32
                                    // }}
                                    // axisLeft={{
                                    //     tickSize: 5,
                                    //     tickPadding: 5,
                                    //     tickRotation: 0,
                                    //     legend: 'food',
                                    //     legendPosition: 'middle',
                                    //     legendOffset: -40
                                    // }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'top-left',
                                            direction: 'row',
                                            justify: false,
                                            translateX: -250,
                                            translateY: -50,
                                            itemsSpacing: 2,
                                            itemWidth: 110,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />
                            </div>
                        </Col>
                    : null
                }

                
                    

                
                {/* <Col>
                    <h3><span>M</span>atching</h3>
                    <p>Participants whose average score is matching the required competencies score</p>
                </Col>
                <Col>
                    <h3><span>E</span>xceeding</h3>
                    <p>Participants whose average score is exceeding the required competencies score</p>
                </Col> */}
            </Row>

        </div>
    );
    
  }
}

DMEProfileComponent.propTypes = {
    // triggerFormIframe: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
})

export default connect(mapStatesToProps, {  })(DMEProfileComponent);