import {
    
	_getSkills,
	_getSpecificSkills,
	_getSpecificSkillsMoreInfo,
	_getSpecificSkillsAvg,
	_getSpecificSkillsDemoPurpose,
	_getDemographicAvg,
	_getDemographicRegionAvg,
	_getGenderAvg,
	_getGenderRegionAvg,
	_getAgeAvg,
	_getAgeRegionAvg,
	_getCenturySkills,
	_getSchools,
	_getCitiesData,
	_triggerForm,
	_getPositionAvg,
	_getPositionRegionAvg,
	_getMediumAvg,
	_getMediumRegionAvg,
	_getTransportRegionAvg,
	_getTransportAvg,
	_switchBranchFromSidebar

	
} from './types';

import axios from 'axios';
import { getBaseUrl } from './utility';
import { async } from 'q';

// import ndjsonStream from 'can-ndjson-stream';
// import { platform } from 'os';

// import tokenProvider from 'axios-token-interceptor';

	// const instance = axios.create({
	// 	baseURL: 'https://iponym.com/getdata'
	// });
   
// 	// Configure the provider with the necessary options.
// 	const options = {
// 		token: 'abc',
// 		headers: {
// 			'Content-Type': 'application/json'
// 		}
// 	};
// 	instance.interceptors.request.use(tokenProvider(options));

export const getAllSkills = () => async (dispatch) => {

	axios.get( getBaseUrl() + 'talentmap/skills')
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: _getSkills,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: _getSkills,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: _getSkills,
			payload: error
        });
	});
};

export const getSpecificCitySkills = (cityname, cityId) => async (dispatch) => {

	axios.get( getBaseUrl() + 'talentmap/skill/situation/city/avg?city_id=' + cityId)
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: _getSpecificSkills,
				payload: {
					clickedRegion: cityname,
					data: res.data.result
				}
			});
			if(res.data.citymoreinfo && res.data.citymoreinfo != "") {
				dispatch({
					type: _getSpecificSkillsMoreInfo,
					payload: {
						clickedRegion: cityname,
						data: res.data.citymoreinfo
					}
				});
			}else {
				dispatch({
					type: _getSpecificSkillsMoreInfo,
					payload: {
						clickedRegion: cityname,
						data: ""
					}
				});
			}

			if(res.data.skillAvg && res.data.skillAvg != "") {
				dispatch({
					type: _getSpecificSkillsAvg,
					payload: {
						clickedRegion: cityname,
						data: res.data.skillAvg
					}
				});
			}else {
				dispatch({
					type: _getSpecificSkillsAvg,
					payload: {
						clickedRegion: cityname,
						data: ""
					}
				});
			}
		}
		else {
			dispatch({
				type: _getSpecificSkills,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: _getSpecificSkills,
			payload: error
        });
	});
};

export const getSpecificCitySkillsForDemo = (cityname, cityId) => async (dispatch) => {

	axios.get( getBaseUrl() + 'talentmap/skill/situation/city/avg?city_id=' + cityId)
	.then((res) => {

        // console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: _getSpecificSkillsDemoPurpose,
				payload: {
					clickedRegion: cityname,
					data: res.data.result
				}
			});
		}
		else {
			dispatch({
				type: _getSpecificSkillsDemoPurpose,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: _getSpecificSkillsDemoPurpose,
			payload: error
        });
	});
};

export const getDemographicAvg = (skill_id, region) => async (dispatch) => {
	
	var url, dispatchType;
	if(region && region != ''){ // check if we need to get all regions data or specific
		url = getBaseUrl() + 'talentmap/skillbased/avg?skill_id=' + skill_id + '&region=' + region;
		dispatchType = _getDemographicRegionAvg;
	}
	else{
		url = getBaseUrl() + 'talentmap/skillbased/avg?skill_id=' + skill_id;
		dispatchType = _getDemographicAvg;
	}

	axios.get( url )
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: dispatchType,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: dispatchType,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: dispatchType,
			payload: error
        });
	});
};


export const getGenderClassificationAvg = (skill_id, region) => async (dispatch) => {
	
	var url, dispatchType;
	if(region && region != ''){ // check if we need to get all regions data or specific
		url = getBaseUrl() + 'talentmap/skillbased/genderreport?skill_id=' + skill_id + '&region=' + region;
		dispatchType = _getGenderRegionAvg;
	}
	else{
		url = getBaseUrl() + 'talentmap/skillbased/genderreport?skill_id=' + skill_id;
		dispatchType = _getGenderAvg;
	}
		
	axios.get( url )
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: dispatchType,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: dispatchType,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: dispatchType,
			payload: error
        });
	});
};

export const getCenturySkillsData = (clientid) => async (dispatch) => {
	
	var url = "https://dashboard.lincolnmethod.com/api/v1/twentyfirstcentuy/skills/courses?clientid=" + clientid;

	axios.get( url )
	.then((res) => {

        // console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: _getCenturySkills,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: _getCenturySkills,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: _getCenturySkills,
			payload: error
        });
	});
};

export const getAllSchools = () => async (dispatch) => {

	axios.get( getBaseUrl() + 'talentmap/ph/institute')
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: _getSchools,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: _getSchools,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: _getSchools,
			payload: error
        });
	});
};

export const getAllCitiesData = () => async (dispatch) => {

	axios.get( getBaseUrl() + 'talentmap/cities')
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: _getCitiesData,
				payload: res.data
			});
		}
		else {
			dispatch({
				type: _getCitiesData,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: _getCitiesData,
			payload: error
        });
	});
};


export const triggerFormIframe = (type) => async (dispatch) => {

	dispatch({
		type: _triggerForm,
		payload: {
			type : type,
			random : Math.random() * 90
		}
	});

};

export const getAgeClassificationAvg = (region) => async (dispatch) => {
	var url, dispatchType;
	if(region && region != ''){ // check if we need to get all regions data or specific
		url = getBaseUrl() + 'talentmap/age/stats?region=' + region;
		dispatchType = _getAgeRegionAvg;
	}
	else{
		url = getBaseUrl() + 'talentmap/age/stats';
		dispatchType = _getAgeAvg;
	}
		
	axios.get( url )
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: dispatchType,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: dispatchType,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: dispatchType,
			payload: error
        });
	});
};

export const getPositionClassificationAvg = (region) => async (dispatch) => {

	var url, dispatchType;
	if(region && region != ''){ // check if we need to get all regions data or specific
		url = getBaseUrl() + 'talentmap/positions/stats?region=' + region;
		dispatchType = _getPositionRegionAvg;
	}
	else{
		url = getBaseUrl() + 'talentmap/positions/stats';
		dispatchType = _getPositionAvg;
	}
		
	axios.get( url )
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: dispatchType,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: dispatchType,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: dispatchType,
			payload: error
        });
	});
};

export const getMediumsClassificationAvg = (region) => async (dispatch) => {
	console.log('i am in medium classification');
	var url, dispatchType;
	if(region && region != ''){ // check if we need to get all regions data or specific
		url = getBaseUrl() + 'talentmap/meduims/stats?region=' + region;
		dispatchType = _getMediumRegionAvg;
	}
	else{
		url = getBaseUrl() + 'talentmap/meduims/stats';
		dispatchType = _getMediumAvg;
	}
		
	axios.get( url )
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: dispatchType,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: dispatchType,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: dispatchType,
			payload: error
        });
	});
};

export const getTransportClassificationAvg = (region) => async (dispatch) => {
	console.log('here i m transport classification');
	var url, dispatchType;
	if(region && region != ''){ // check if we need to get all regions data or specific
		url = getBaseUrl() + 'talentmap/transport/stats?region=' + region;
		dispatchType = _getTransportRegionAvg;
	}
	else{
		url = getBaseUrl() + 'talentmap/transport/stats';
		dispatchType = _getTransportAvg;
	}
		
	axios.get( url )
	.then((res) => {

        console.log(res)

		if (res.data.status == "success") { // if success only
			dispatch({
				type: dispatchType,
				payload: res.data.result
			});
		}
		else {
			dispatch({
				type: dispatchType,
				payload: 'error'
			})
        }

	})
	.catch((error) => {
		dispatch({
			type: dispatchType,
			payload: error
        });
	});
};

export const updateBranchAction = (selectedBranchId, selectedBranch) => async (dispatch) => {
	
	dispatch({
		type: _switchBranchFromSidebar,
		payload: {
			selectedBranch : {
				id : selectedBranchId,
				name : selectedBranch,
				random : Math.random()
			}
		}
	});

};
