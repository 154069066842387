import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./../../../public/scss/snippets/SkillContainer.scss";

import {
//   Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class SkillContainer extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        data: [],
        colorArray : ["rgb(255, 37, 0)", "rgb(105, 158, 35)", "rgb(46, 176, 241)", "rgb(239, 185, 4)","rgb(255, 37, 0)", "rgb(105, 158, 35)", "rgb(46, 176, 241)", "rgb(239, 185, 4)","rgb(255, 37, 0)", "rgb(105, 158, 35)", "rgb(46, 176, 241)", "rgb(239, 185, 4)","rgb(255, 37, 0)", "rgb(105, 158, 35)", "rgb(46, 176, 241)", "rgb(239, 185, 4)","rgb(255, 37, 0)", "rgb(105, 158, 35)", "rgb(46, 176, 241)", "rgb(239, 185, 4)"]
    };
  }

  componentDidMount(){
    console.log(this.state.data);
  }

  componentWillReceiveProps(nextProps, thisProps){
    if(this.props.data != nextProps.data) {
      this.setState({
        data : nextProps.data
      })
    }
  }

  render() {

    return (
      <div className="SkillContainer">
            <Container fluid>

                {
                    this.state.data.map((validity, key) =>
                        <Row key={key} id={'validity'+ (key+1)} style={{backgroundImage: 'url(' + validity.bgImage + ')'}}>
                            <Col style={{textAlign: 'left'}} className="box" key={key} md={{ size: 12 }}>
                                
                              <Row>

                                {
                                  ((key + 1) % 2) == 0 ?
                                    <Col md={{size: 7}} style={{backgroundImage: 'url(' + validity.bgImage + ')', backgroundSize: 'cover'}}></Col>
                                  : null
                                }

                                <Col style={{background: this.state.colorArray[key], textAlign: 'left', padding: '5vh 30px'}} key={key} md={{ size: 5 }}>
                                    <h3 dangerouslySetInnerHTML={{__html: validity.heading}}></h3>
                                    {
                                      Array.isArray(validity) ? 
                                      <div>
                                        <p>In this assessment, you will be assessed on the following situations:</p>
                                      
                                        <ul style={{paddingLeft: '22px'}}>

                                          {
                                            validity.text.map((list, key) => 
                                              <li key={key} dangerouslySetInnerHTML={{__html: list}}></li>
                                            )
                                          }
                                          <li>Did you know that we also have training available on {validity.heading} organized in {validity.chapters} Chapters with {validity.videos} training videos</li>

                                        </ul>
                                      </div>
                                    : 
                                      <div>
                                        <p>In this assessment, you will be assessed on the following situations:</p>
                                        <p className="skillContent" dangerouslySetInnerHTML={{__html: validity.text}}></p>
                                        {
                                          validity.chapters != 0 && validity.videos != 0 ?
                                          <p style={{marginBottom: 0, marginTop: '12px'}}>
                                            {/* <li> */}
                                              Did you know that we also have training available on {validity.heading} organized in {validity.chapters} Chapters with {validity.videos} training videos                                            
                                            {/* </li> */}
                                          </p>
                                          : null
                                        }
                                      </div>

                                    }
                                    {/* <br/>
                                    <a style={{color: '#fff', marginTop: '10px'}} target="_new" href={validity.button.link}>
                                        {validity.button.label}
                                    </a> */}
                                </Col>
                                {
                                  ((key + 1) % 2) == 0 ?
                                    <Col md={{size: 7}} style={{backgroundImage: 'url(' + validity.bgImage + ')', backgroundSize: 'cover'}}></Col>
                                  : null
                                }
                                
                              </Row>
                            </Col>
                        </Row>
                    )
                }

            </Container>
      </div>
    );
  }
}

export default SkillContainer;
