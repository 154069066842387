export const _getSkills = '_getSkills';
export const _getSpecificSkills = '_getSpecificSkills';
export const _getSpecificSkillsMoreInfo = '_getSpecificSkillsMoreInfo';
export const _getSpecificSkillsAvg = '_getSpecificSkillsAvg';

export const _getSpecificSkillsDemoPurpose = '_getSpecificSkillsDemoPurpose';

export const _getDemographicAvg = '_getDemographicAvg';
export const _getDemographicRegionAvg = '_getDemographicRegionAvg';
export const _getGenderAvg = '_getGenderAvg';
export const _getGenderRegionAvg = '_getGenderRegionAvg';
export const _getAgeAvg = '_getAgeAvg';
export const _getAgeRegionAvg = '_getAgeRegionAvg';
export const _getCenturySkills = '_getCenturySkills';
export const _getSchools = '_getSchools';
export const _getCitiesData = '_getCitiesData';
export const _triggerForm = '_triggerForm';


export const _getPositionAvg = '_getPositionAvg';
export const _getPositionRegionAvg = '_getPositionRegionAvg';
export const _getMediumAvg = '_getMediumAvg';
export const _getMediumRegionAvg = '_getMediumRegionAvg';
export const _getTransportAvg = '_getTransportAvg';
export const _getTransportRegionAvg = '_getTransportRegionAvg';

export const _switchBranchFromSidebar = '_switchBranchFromSidebar';