import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./../../../public/scss/snippets/BannerWithText.scss";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class BannerWithText extends Component {
  
  constructor(props) {
    super(props);

    console.log(props)

    this.state = {
        heading: props.bannerData.heading,
        subheading: props.bannerData.subheading,
        para: props.bannerData.para,
        btn_text: props.bannerData.btn_text,
        btn_text_url: props.bannerData.btn_text_url,
        image_url: props.bannerData.image_url,
        video_url: props.bannerData.video_url,
        theme: props.bannerData.themeColor,
        playPress : props.playPress
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {
    return (
      <div className={"BannerWithText " + this.state.theme} style={{ backgroundColor: !this.state.video_url || this.state.video_url === '' ? "" : this.state.playPress ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.3)", backgroundImage: this.state.image_url ? "url("+ this.state.image_url +")" : "", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundAttachment: "fixed", backgroundSize: "cover" }}>

        <Container>
            <Row>
              {
                this.state.playPress ?
                  <Col md={{ size: 6, offset: 3 }}>                    
                    <a href={this.state.btn_text_url} target="_new">
                      {
                        this.state.btn_text || this.state.btn_text !== '' ?
                          <div className="playPressBtn">
                            <Media object src={"https://comptonrising.org/wp-content/uploads/2018/12/playbtn-white.png"} alt="play press" />
                            <p dangerouslySetInnerHTML={{__html: this.state.btn_text}}></p>
                          </div>
                          :
                          null
                      }
                    </a>
                  </Col>
                  :
                  <Col md={{ size: 12 }}>
                    
                    <h1 className="BannerWithText_heading" dangerouslySetInnerHTML={{__html: this.state.heading}}></h1>
                    <h2 className="BannerWithText_subheading" dangerouslySetInnerHTML={{__html: this.state.subheading}}></h2>
                    {
                      this.state.para && this.state.para != '' ?
                        <p className="BannerWithText_paragraph" dangerouslySetInnerHTML={{__html: this.state.para}}></p>
                      : null
                    }
                    <a href={this.state.btn_text_url}>
                      {
                        this.state.btn_text || this.state.btn_text !== '' ?
                          <Button className="BannerWithText_btn" size="lg" color="primary">{this.state.btn_text}</Button>
                          :
                          null
                      }
                    </a>
                  </Col>
              }
                
            </Row>
        </Container>

        {
            this.state.video_url && this.state.video_url !== '' ?
        
            <video className="slider-video" width="100%" loop autoPlay muted>
                {/* <source src={this.state.video_url} type="video/webm" /> */}
                <source type="video/mp4" src={this.state.video_url} />
            </video>
        
            : null

        }

      </div>
    );
  }
}

export default BannerWithText;
