import React, { Component } from "react";
import "../../public/scss/AdoptACityComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
    Media,
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";


import git1 from "./../../public/images/getInTouch/git1.png";
import git2 from "./../../public/images/getInTouch/git2.png";
import git3 from "./../../public/images/getInTouch/git3.png";
import adoptcity1 from "./../../public/images/adoptacity/1.png";

class GetInTouchComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
  render() {
    return (
      <div className="AdoptACityComponent">
        <Header />

            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ git1 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{paddingTop: '40vh'}}>
                        <Col md={{ size: 12 }}>
                            <h1>
                                GET IN TOUCH WITH US
                            </h1>
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ git2 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{textAlign: 'left'}}>
                        <Col md={{ size: 12 }}>
                            <h1>
                            Get in touch with us by choosing a pledge to support
                            </h1>
                            <a href="https://form.assessmentfund.com/ODY5NA==" target="_blank"><Button className="yellow" color="warning" size="lg">Choose a pledge</Button></a>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>

                    </Row>
                </Container>
            </div>

            
            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ adoptcity1 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{textAlign: 'left'}}>
                        <Col md={{ size: 12 }}>
                            <h1>
                            Get in touch with us by choosing a city to adopt
                            </h1>
                            <a href="https://form.assessmentfund.com/ODY5Ng==" target="_blank"><Button className="blue" color="primary" size="lg">Choose a city to adopt</Button></a>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>

                    </Row>
                </Container>
            </div>

            
            <div className="titleContainer firstContainer" style={{backgroundImage: 'url('+ git3 +')', borderBottom: "10px #fff solid"}}>
                <Container>
                    <Row style={{textAlign: 'left'}}>
                        <Col md={{ size: 12 }}>
                            <h1>
                            How can we help you?
                            </h1>
                            <a href="https://form.assessmentfund.com/ODY5OA==" target="_blank"><Button className="yellow" color="warning" size="lg">Get in touch</Button></a>
                        </Col>
                    </Row>
                    <Row style={{padding: '20vh 0 6vh 0'}}>

                    </Row>
                </Container>
            </div>

        
        <Footer />
      </div>
    );
  }
}

export default GetInTouchComponent;
