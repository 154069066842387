import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { getAllSkills, getAllCitiesData, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg, getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg } from './../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import CitiesListSidebarComponent from './CitiesListSidebarComponent';
import DistributionAndComparisonTopCitiesComponent from './DistributionAndComparisonTopCitiesComponent';
import SamProfileComponent from './SamProfileComponent';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
  } from "reactstrap";

  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faTimes, faBars, faSchool, faInfo, faComment, faCalendarCheck, faCalendar } from '@fortawesome/free-solid-svg-icons';

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
// import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';

import manilaLogo from './../../../public/images/stats/cityLogos/manilalogo.png';
import manilaMayer from './../../../public/images/stats/mayerImages/manilamayer.jpg';

// import $ from 'jquery'; 

class TalentMapStatsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cityLogo : '',
            mayerImage : '',
            mayerName : '',
            totalInstitutes : 0,
            ourSkills : [],
            skillsAvgData : {},
            skillsAvgDataSpecificCity : {},
            skillSituationsData : {},
            situationsAvgData : {},
            situationsAvgDataSpecificCity : {},
            selectedSkill : this.props.match.params.skillId,
            selectedSkillId : 0,
            selectedSkillAvg : 0,
            selectedSkillAvgSpecificCity : 0,
            selectedRegion : 'Sarangani',
            regionToCity : {
              "NCR" : "Manila",
              "Region 7" : "SIQUIJOR",
              "Calabarzon" : "Laguna",
              "Region 10" : "CAYAGAN DE ORO",
              "Region 3" : "Bulacan",
              "Region 11" : "Davao",
              "Region 5" : "Albay",
              "Region 13" : "Dinagat Island",
              "Region 1" : "La Union",
              "Region 12" : "Sarangani"
            },
            situations : [''],
            situationsAvg : [],
            situationsAvgSpecificCity : [],
            userTypeDataAllRegions: '',
            userTypeDataSpecificRegion: '',
            genderDataAllRegions: '',
            genderDataSpecificRegion: '',
            ageDataAllRegions: '',
            ageDataSpecificRegion: '',
            positionsDataAllRegions: '',
            positionsDataSpecificRegion: '',
            mediumsDataAllRegions: '',
            mediumsDataSpecificRegion: '',
            transportDataAllRegions: '',
            transportDataSpecificRegion: '',
            userTypeScoresData : {
              keys : [],
              data : []
            },
            skillUpdateLoader : false,
            GenderUpdateLoader : false,
            userTypeScoresDataPie1 : [[],[]],
            userTypeScoresDataPie1Loc : 0,
            genderScoresData : [],
            genderScoresDataLoc : 0,
            ageScoreDataLoc : 0,
            userTypeAgeScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            positionScoreDataLoc : 0,
            userTypePositionsScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            mediumScoreDataLoc : 0,
            userTypeMediumsScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            transportScoreDataLoc : 0,
            userTypeTransportsScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            swarmDataAllRegions : [],
            // swarmDataAllRegions2 : [{
            //   "id": "Region 10" + " - candidates: "+ 6500 + ", avg. scrore",
            //   "group": "Sorting jumbled words logically",
            //   "score": 89,
            //   "volume": 6500
            // },{
            //   "id": "Calabarzon" + " - candidates: "+ 3200 + ", avg. scrore",
            //   "group": "Sorting jumbled words logically",
            //   "score": 80,
            //   "volume": 3200
            // },{
            //   "id": "NCR" + " - candidates: "+ 1600 + ", avg. scrore",
            //   "group": "Sorting jumbled words logically",
            //   "score": 90,
            //   "volume": 1600
            // },{
            //   "id": "Region 7" + " - candidates: "+ 2452 + ", avg. scrore",
            //   "group": "Sorting jumbled words logically",
            //   "score": 23,
            //   "volume": 2452
            // }],
            // swarmDataAllRegions3 : [{
            //   "id": "Region 7" + " - candidates: "+ 800 + ", avg. scrore",
            //   "group": "Solving criteria based problems",
            //   "score": 32,
            //   "volume": 11800
            // }],
            citiesListToggle : false,
            isCitiesLoading : false,
            allCities : [{
              cityid : 0,
              cityname: "Manila"
            }],
            cityUpdateLoader : false,
            situationsAvgDataLoc : 0,
            citySearchText : '',
            selectedMayer : "Isko Moreno",
            // showModalInfo : false,
            // showModalChat : false,
            integratedFormId : "",
            integratedFormToggle : false,
            formSource : "",
            respondentsCatogorizeSamData : {
              "top" : {
                "left": [{
                  name : "Disenchanted",
                  avg : 9,
                  skilled : "Under skilled"
                },{
                  name : "Capable",
                  avg : 10,
                  skilled : "Adequately skilled"
                },{
                  name : "Eager",
                  avg : 3,
                  skilled : "Highly skilled"
                }],
                "right" : [{
                  name : "Work horses",
                  avg : 13,
                  skilled : "Under skilled"
                },{
                  name : "Stars",
                  avg : 11,
                  skilled : "Adequately skilled"
                },{
                  name : "Superstars",
                  avg : 4,
                  skilled : "Highly skilled"
                }]
              },
              "bottom" : {
                "left": [{
                  name : "Dormant",
                  avg : 4,
                  skilled : "Under skilled"
                },{
                  name : "Relaxed",
                  avg : 10,
                  skilled : "Adequately skilled"
                },{
                  name : "Disempowered",
                  avg : 3,
                  skilled : "Highly skilled"
                }],
                "right" : [{
                  name : "Believers",
                  avg : 15,
                  skilled : "Under skilled"
                },{
                  name : "Hopefuls",
                  avg : 14,
                  skilled : "Adequately skilled"
                },{
                  name : "High potentials",
                  avg : 4,
                  skilled : "Highly skilled"
                }]
              }
            }
        };

      // this.toggleModal = this.toggleModal.bind(this);

    }

  componentDidMount() {

    // var userTypeScoresDataPieArray = [];
    // this.state.userTypeScoresData.map((data, key) => {
    //   userTypeScoresDataPieArray.push({
    //     "id": data.type,
    //     "label": data.type,
    //     "value": data.All,
    //     "color": "hsl(172, 70%, 50%)"
    //   })
    // })

    window.scrollTo(0, 0); // scroll to top after rendering the component

    // var localSelectedRegion = localStorage.getItem('selectedRegion');
    // if(localSelectedRegion && localSelectedRegion != undefined) {
    //   this.setState({
    //     selectedRegion : localSelectedRegion
    //   })
    // }
    

    // this.state.situations.map(situation => {
    //   var situationArray = this.state.swarmDataSituations;
    //   situationArray.push(situation.value);
      
    //   this.setState({
    //     swarmDataSituations : situationArray
    //   })
    // })

    var selectedRegoin = localStorage.getItem('selectedRegion');
    if(selectedRegoin && selectedRegoin != '') {
      this.setState({
        selectedRegion : this.state.regionToCity[selectedRegoin]
      }, () => {
        
        // calls all skills data
        this.props.getAllSkills();

        // calls an action for getting list of all cities.
        this.props.getAllCitiesData();
        
      })
    }

  }

  updateSkill = (skill, skill_id) => {

    if(!this.state.skillUpdateLoader) { // if skill data is already populated so they

      this.setState({
        selectedSkill : skill,
        selectedSkillId : skill_id
      },
      () => {
        var selectedSkillObj = this.state.skillsAvgData[this.state.selectedSkillId];
        this.setState({
          selectedSkillAvg : selectedSkillObj.avg,
          selectedSkillAvgSpecificCity : this.state.skillsAvgDataSpecificCity[this.state.selectedSkillId].avg,
          situationsAvg : this.state.situationsAvgData[this.state.selectedSkillId],
          situationsAvgSpecificCity : this.state.situationsAvgDataSpecificCity[this.state.selectedSkillId],
        }, () => {
          this.setState({
            situations : this.state.skillSituationsData[this.state.selectedSkillId],
            skillUpdateLoader : true,
            GenderUpdateLoader : true
          }, () => {

              // this.regionwiseSituationsMapping();

              console.log(this.state.situationsAvg);
              console.log(this.state.situationsAvgSpecificCity);
              //call all regions Demographic data
              this.props.getDemographicAvg(this.state.selectedSkillId);
              //call all regions Gender classification data
              this.props.getGenderClassificationAvg(this.state.selectedSkillId);

              // //call all regions Age classification data
              // this.props.getAgeClassificationAvg();

              // //call selected city/region position data
              // this.props.getPositionClassificationAvg();

              // //call selected city/region medium data
              // this.props.getMediumsClassificationAvg();

              // //call selected city/region transportation data
              // this.props.getTransportClassificationAvg();
              
          })
        })

      })

    }

  }

  // updateCity = (city, cityId) => {

  //   if(!this.state.skillUpdateLoader) { // if city data is already populated so they

  //     this.setState({
  //       selectedCity : city,
  //       selectedCityId : cityId,
  //       selectedRegion : city // mapping region with city also as both are same things now
  //     },
  //     () => {
  //       console.log(this.state.selectedCity, this.state.selectedCityId);

  //       this.setState({
  //         skillUpdateLoader : true,
  //         GenderUpdateLoader : true
  //       }, () => {
          
  //         //call selected city/region Demographic data
  //         this.props.getDemographicAvg(this.state.selectedSkillId, this.state.selectedRegion);
          
  //         //call selected city/region gender data
  //         this.props.getGenderClassificationAvg(this.state.selectedSkillId, this.state.selectedRegion);
          
  //         // calls an action for getting skills/situations against each specific city.
  //         this.props.getSpecificCitySkills(this.state.selectedCityId);
        
  //       })
        
  //     })

  //   }

  // }


  componentWillReceiveProps(nextProps, thisProps) {


    // if(this.props.citiesDataResponse != nextProps.citiesDataResponse) {
    //   this.setState({
    //     allCities : nextProps.citiesDataResponse
    //   }, () => {
    //     console.log(nextProps.citiesDataResponse)
    //   })
    // }

    if(this.props.skillsResponse != nextProps.skillsResponse) {
      console.log(nextProps.skillsResponse);
      this.setState({
        ourSkills : nextProps.skillsResponse.skills,
        skillsAvgData : nextProps.skillsResponse.skillsAVGData,
        skillSituationsData : nextProps.skillsResponse.skillsData,
        situationsAvgData : nextProps.skillsResponse.situationAVGData
      }, () => {

        this.state.ourSkills.map((skill) => {
          if(this.state.selectedSkill == skill.skill_name){
            this.setState({
              selectedSkillId : skill.id
            }, () => {

              var selectedSkillObj = this.state.skillsAvgData[this.state.selectedSkillId];
              this.setState({
                selectedSkillAvg : selectedSkillObj.avg,
                situationsAvg : this.state.situationsAvgData[this.state.selectedSkillId]
              }, () => {

                this.setState({
                  situations : this.state.skillSituationsData[skill.id],
                  skillUpdateLoader : true,
                  GenderUpdateLoader : true
                }, () => {
                  
                    // this.regionwiseSituationsMapping();

                    console.log(this.state.situationsAvg);
                    //call all regions Demographic data
                    this.props.getDemographicAvg(this.state.selectedSkillId);
                    //call all regions Gender classification data
                    this.props.getGenderClassificationAvg(this.state.selectedSkillId); 

                    //call all regions Age classification data
                    this.props.getAgeClassificationAvg();

                    //call selected city/region position data
                    this.props.getPositionClassificationAvg();

                    //call selected city/region medium data
                    this.props.getMediumsClassificationAvg();

                    //call selected city/region transportation data
                    this.props.getTransportClassificationAvg();                   
                  
                })

              })

            })
          }
        })

      })
    }

    if(this.props.skillsCitySpecificResponse != nextProps.skillsCitySpecificResponse) {

      try {
        // console.log(nextProps.skillsCitySpecificResponse);
        this.setState({
          situationsAvgDataSpecificCity : nextProps.skillsCitySpecificResponse.data,
          selectedRegion: nextProps.skillsCitySpecificResponse.clickedRegion
        }, () => {
          this.setState({
            situationsAvgSpecificCity : this.state.situationsAvgDataSpecificCity[this.state.selectedSkillId]
          }, () => {
            console.log(this.state.situationsAvgDataSpecificCity);
            console.log(this.state.situationsAvgSpecificCity);
          })
        })
      }
      catch (e){
        console.log(e);
      }
      
    }

    if(this.props.skillsCitySpecificResponseMoreInfo != nextProps.skillsCitySpecificResponseMoreInfo) {
      this.setState({
        cityLogo : "http://api.testedtalent.com/cityLogo/" + nextProps.skillsCitySpecificResponseMoreInfo.data.city_logo,
        mayerImage : "http://api.testedtalent.com/mayorImages/" + nextProps.skillsCitySpecificResponseMoreInfo.data.mayor_image,
        mayerName : nextProps.skillsCitySpecificResponseMoreInfo.data.mayor_name,
        totalInstitutes : nextProps.skillsCitySpecificResponseMoreInfo.data.total_institutes,
      })
    }

    if(this.props.skillsCitySpecificAvgScoreResponse != nextProps.skillsCitySpecificAvgScoreResponse) {
      // console.log(nextProps.skillsCitySpecificAvgScoreResponse.data);
      this.setState({
        skillsAvgDataSpecificCity : JSON.parse(nextProps.skillsCitySpecificAvgScoreResponse.data[0].avg_data)
      }, () => {
        console.log(this.state.skillsAvgDataSpecificCity);
        this.setState({
          selectedSkillAvgSpecificCity : this.state.skillsAvgDataSpecificCity[this.state.selectedSkillId].avg
        })
      })
    }

    if(this.props.demographicResponse != nextProps.demographicResponse) {
      this.setState({
        userTypeDataAllRegions : nextProps.demographicResponse[this.state.selectedSkillId]
      }, () => {
        
          //call selected region Demographic data
          this.props.getDemographicAvg(this.state.selectedSkillId, this.state.selectedRegion);

      })
    }
    
    if(this.props.demographicRegionResponse != nextProps.demographicRegionResponse) {
      this.setState({
        userTypeDataSpecificRegion : nextProps.demographicRegionResponse[this.state.selectedSkillId]
      }, () => {
        
        this.demographicDataMapping();

      })
    }

    if(this.props.genderResponse != nextProps.genderResponse) {
      this.setState({
        genderDataAllRegions : nextProps.genderResponse
      }, () => {
        
          //call selected region gender data
          this.props.getGenderClassificationAvg(this.state.selectedSkillId, this.state.selectedRegion);

      })
    }
    
    if(this.props.genderRegionResponse != nextProps.genderRegionResponse) {
      this.setState({
        genderDataSpecificRegion : nextProps.genderRegionResponse
      }, () => {
        
        this.genderDataMapping();

      })
    }

    if(this.props.ageResponse != nextProps.ageResponse) {
      this.setState({
        ageDataAllRegions : nextProps.ageResponse
      }, () => {
        
          //call selected region age data
          this.props.getAgeClassificationAvg(this.state.selectedRegion);

      })
    }
    
    if(this.props.ageRegionResponse != nextProps.ageRegionResponse) {
      this.setState({
        ageDataSpecificRegion : nextProps.ageRegionResponse
      }, () => {
        
        this.ageDataMapping();

      })
    }

    if(this.props.positionsAvgResponse != nextProps.positionsAvgResponse) {
      this.setState({
        positionsDataAllRegions : nextProps.positionsAvgResponse
      }, () => {
        
          //call selected region age data
          this.props.getPositionClassificationAvg(this.state.selectedRegion);

      })
    }
    
    if(this.props.positionsAvgRegionResponse != nextProps.positionsAvgRegionResponse) {
      this.setState({
        positionsDataSpecificRegion : nextProps.positionsAvgRegionResponse
      }, () => {
        
        this.positionDataMapping();

      })
    }

    if(this.props.mediumAvgResponse != nextProps.mediumAvgResponse) {
      this.setState({
        mediumsDataAllRegions : nextProps.mediumAvgResponse
      }, () => {
        
          //call selected region age data
          this.props.getMediumsClassificationAvg(this.state.selectedRegion);

      })
    }
    
    if(this.props.mediumAvgRegionResponse != nextProps.mediumAvgRegionResponse) {
      this.setState({
        mediumsDataSpecificRegion : nextProps.mediumAvgRegionResponse
      }, () => {
        
        this.mediumDataMapping();

      })
    }

    if(this.props.transportAvgResponse != nextProps.transportAvgResponse) {
      this.setState({
        transportDataAllRegions : nextProps.transportAvgResponse
      }, () => {
        
          //call selected region age data
          this.props.getTransportClassificationAvg(this.state.selectedRegion);

      })
    }
    
    if(this.props.transportAvgRegionResponse != nextProps.transportAvgRegionResponse) {
      this.setState({
        transportDataSpecificRegion : nextProps.transportAvgRegionResponse
      }, () => {
        
        this.transportDataMapping();

      })
    }

    


    //checking if form iframe is triggered
    if(this.props.formTriggerResponse != nextProps.formTriggerResponse) {
      console.log(nextProps.formTriggerResponse);
      if(nextProps.formTriggerResponse.type && nextProps.formTriggerResponse.type == 'info'){
        this.toggleModalInfo();
      }
      else{
        this.toggleModalChat();
      }
    }


  }

  situationsAverageMapping = (correctAns, totalAns) => {
    return parseInt((parseInt(correctAns) / parseInt(totalAns)) * 100);
  }

  demographicDataMapping = () => {

    try {

      console.log(this.state.userTypeDataAllRegions);
      var keys = Object.keys(this.state.userTypeDataAllRegions);
      keys = this.sortingForDemographicKeys(keys);
      console.log(keys);
  
      var dataArray = [];
      var dataArrayPie = [], dataArrayPieSpecific = [], dataArrayPieAll = [];
  
      keys.map((value, key) => {
        if(key < 6) {
          dataArray.push({
            "type" : value,
            [this.state.selectedRegion] : this.state.userTypeDataSpecificRegion[value] ? this.state.userTypeDataSpecificRegion[value].avg : 0,
            "All Cities" : this.state.userTypeDataAllRegions[value].avg
          });
          dataArrayPieSpecific.push({
            "id" : value,
            "label" : value,
            "value" : this.state.userTypeDataSpecificRegion[value] ? this.state.userTypeDataSpecificRegion[value].cnt : 0
          });
          dataArrayPieAll.push({
            "id" : value,
            "label" : value,
            "value" : this.state.userTypeDataAllRegions[value].cnt
          });
        }
        
      })
  
      dataArrayPie = [dataArrayPieSpecific, dataArrayPieAll]; //setting up final array for participation pie chart for both specific and all regions
  
      this.setState({
        userTypeScoresData : {
          keys : [this.state.selectedRegion, "All Cities"],
          data : dataArray
        },
        userTypeScoresDataPie1 : dataArrayPie
      }, () => {
        
        console.log(this.state.userTypeScoresData);
        console.log(this.state.userTypeScoresDataPie1);
  
        this.setState({
          skillUpdateLoader : false
        })
  
      })
    }
    catch(e) {
      console.log("error in demographicDataMapping");
      console.log(e);
    }

  }

  sortingForDemographicKeys = (keys) => {
    var sortedKeys = ["Employed Graduate", "Unemployed Graduate", "Employed Undergraduate", "Unemployed Undergraduate", "Trainee", "Student", "all_user", "overall_avg", "user_type", "totall_avg"];
    return sortedKeys;
  }

  genderDataMapping = () => {

    try {
      var tempArray = this.state.genderScoresData;
      
      var genderDataSpecificRegionCount0 = this.state.genderDataSpecificRegion[0] ? this.state.genderDataSpecificRegion[0].cnt : 0;

      var genderDataSpecificRegionCount1 = this.state.genderDataSpecificRegion[1] ? this.state.genderDataSpecificRegion[1].cnt : 0;

      tempArray[0] = {
        gender1 : this.state.genderDataSpecificRegion[0] ? this.state.genderDataSpecificRegion[0].gender : '',
        gender2 : this.state.genderDataSpecificRegion[1] ? this.state.genderDataSpecificRegion[1].gender : '',
        average1 : (genderDataSpecificRegionCount0 / parseInt(genderDataSpecificRegionCount0 + genderDataSpecificRegionCount1) * 100),
        count1 : genderDataSpecificRegionCount0,
        average2 : (genderDataSpecificRegionCount1 / parseInt(genderDataSpecificRegionCount0 + genderDataSpecificRegionCount1) * 100),
        count2 : genderDataSpecificRegionCount1
      };
      
      tempArray[1] = {
        gender1 : this.state.genderDataAllRegions[0].gender,
        gender2 : this.state.genderDataAllRegions[1].gender,
        average1 : (this.state.genderDataAllRegions[0].cnt / parseInt(this.state.genderDataAllRegions[0].cnt + this.state.genderDataAllRegions[1].cnt) * 100),
        count1 : this.state.genderDataAllRegions[0].cnt,
        average2 : (this.state.genderDataAllRegions[1].cnt / parseInt(this.state.genderDataAllRegions[0].cnt + this.state.genderDataAllRegions[1].cnt) * 100),
        count2 : this.state.genderDataAllRegions[1].cnt
      };

      this.setState({
        genderScoresData : tempArray
      }, () => {
        this.setState({
          GenderUpdateLoader : false
        }, () => {
          console.log(this.state.genderScoresData);
        })
      })
    }
    catch(e) {
      console.log("error in genderDataMapping");
      console.log(e);
    }

  }

  ageDataMapping = () => {
    
    try {
      
      var tempAgeArray = this.state.userTypeAgeScoresData;
      var allRegionsData = [];
      var specificRegionData = [];

      var keys = Object.keys(this.state.ageDataAllRegions);
      var keyLenth = keys.length;
      
      keys.map(key => {
        if(key < keyLenth) {
          var name = "";
          if(key == 0) {
            name = "Less than 18 yrs";
          } else if(key == 1) {
            name = "18-20";
          } else if(key == 2) {
            name = "20-25";
          } else if(key == 3) {
            name = "25-30";
          } else if(key == 4) {
            name = "30-32";
          } else if(key == 5) {
            name = "35 +";
          }
          allRegionsData.push({
            "name" : name,
            "respondents" : this.state.ageDataAllRegions[key].cnt,
            "percentage" : this.state.ageDataAllRegions[key].per
          })
        }
      });

      var keys2 = Object.keys(this.state.ageDataSpecificRegion);
      var keyLenth2 = keys2.length;
      
      keys2.map(key => {
        if(key < keyLenth2) {
          var name = "";
          if(key == 0) {
            name = "Less than 18 yrs";
          } else if(key == 1) {
            name = "18-20";
          } else if(key == 2) {
            name = "20-25";
          } else if(key == 3) {
            name = "25-30";
          } else if(key == 4) {
            name = "30-32";
          } else if(key == 5) {
            name = "35 +";
          }
          specificRegionData.push({
            "name" : name,
            "respondents" : this.state.ageDataSpecificRegion[key].cnt,
            "percentage" : this.state.ageDataSpecificRegion[key].per
          })
        }
      });

      tempAgeArray = [{
        "keys" : ["percentage"],
        "data" : specificRegionData
      },{
        "keys" : ["percentage"],
        "data" : allRegionsData
      }];

      this.setState({
        userTypeAgeScoresData : tempAgeArray
      }, () => {
        console.log(this.state.userTypeAgeScoresData)
      })
    }
    catch(e) {
      console.log("error in ageDataMapping");
      console.log(e);
    }

  }

  positionDataMapping = () => {
    console.log(this.state.positionsDataAllRegions);
    console.log(this.state.positionsDataSpecificRegion);

    try {
      
      var tempAgeArray = this.state.userTypePositionsScoresData;
      var allRegionsData = [];
      var specificRegionData = [];

      var keys = Object.keys(this.state.positionsDataAllRegions.all);
      // var keyLenth = keys.length;
      
      keys.map(key => {
        // if(key < keyLenth) {
          var name = "";
          if(key == 0) {
            name = "Entry level";
          } else if(key == 1) {
            name = "Assistant level";
          } else if(key == 2) {
            name = "Officer level";
          } else if(key == 3) {
            name = "Manager level";
          } else if(key == 4) {
            name = "Director level";
          } else if(key == 5) {
            name = "Any level";
          }
          allRegionsData.push({
            "name" : name,
            "respondents" : this.state.positionsDataAllRegions.all[key].tcnt,
            "percentage" : this.state.positionsDataAllRegions.all[key].tper
          })
        // }
      });

      var keys2 = Object.keys(this.state.positionsDataSpecificRegion.all);
      // var keyLenth2 = keys2.length;
      
      keys2.map(key => {
        // if(key < keyLenth2) {
          var name = "";
          if(key == 0) {
            name = "Entry level";
          } else if(key == 1) {
            name = "Assistant level";
          } else if(key == 2) {
            name = "Officer level";
          } else if(key == 3) {
            name = "Manager level";
          } else if(key == 4) {
            name = "Director level";
          } else if(key == 5) {
            name = "Any level";
          }
          specificRegionData.push({
            "name" : name,
            "respondents" : this.state.positionsDataSpecificRegion.all[key].tcnt,
            "percentage" : this.state.positionsDataSpecificRegion.all[key].tper
          })
        // }
      });

      tempAgeArray = [{
        "keys" : ["respondents"],
        "data" : specificRegionData
      },{
        "keys" : ["respondents"],
        "data" : allRegionsData
      }];

      this.setState({
        userTypePositionsScoresData : tempAgeArray
      }, () => {
        console.log(this.state.userTypePositionsScoresData)
      })
    }
    catch(e) {
      console.log("error in positionDataMapping");
      console.log(e);
    }
  }

  mediumDataMapping = () => {
    console.log(this.state.mediumsDataAllRegions);
    console.log(this.state.mediumsDataSpecificRegion);

    try {
      
      var tempAgeArray = this.state.userTypeMediumsScoresData;
      var allRegionsData = [];
      var specificRegionData = [];

      var keys = Object.keys(this.state.mediumsDataAllRegions.all);
      // var keyLenth = keys.length;
      
      keys.map(key => {
        // if(key < keyLenth) {
          var name = "";
          if(key == 0) {
            name = "Personal refrences";
          } else if(key == 1) {
            name = "Direct to HR";
          } else if(key == 2) {
            name = "Newspaper";
          } else if(key == 3) {
            name = "Job portal";
          } else if(key == 4) {
            name = "Social media";
          } else if(key == 5) {
            name = "Employment agencies";
          } else if(key == 6) {
            name = "Freelance job";
          }
          allRegionsData.push({
            "name" : name,
            "respondents" : this.state.mediumsDataAllRegions.all[key].tcnt,
            "percentage" : this.state.mediumsDataAllRegions.all[key].tper,
            "employed" : this.state.mediumsDataAllRegions.all[key].ecnt,
            "employedPerc" : this.state.mediumsDataAllRegions.all[key].eper,
            "unemployed" : this.state.mediumsDataAllRegions.all[key].ucnt,
            "unemployedPerc" : this.state.mediumsDataAllRegions.all[key].uper
          })
        // }
      });

      var keys2 = Object.keys(this.state.mediumsDataSpecificRegion.all);
      // var keyLenth2 = keys2.length;
      
      keys2.map(key => {
        // if(key < keyLenth2) {
          var name = "";
          if(key == 0) {
            name = "Personal refrences";
          } else if(key == 1) {
            name = "Direct to HR";
          } else if(key == 2) {
            name = "Newspaper";
          } else if(key == 3) {
            name = "Job portal";
          } else if(key == 4) {
            name = "Social media";
          } else if(key == 5) {
            name = "Employment agencies";
          } else if(key == 6) {
            name = "Freelance job";
          }
          specificRegionData.push({
            "name" : name,
            "respondents" : this.state.mediumsDataSpecificRegion.all[key].tcnt,
            "percentage" : this.state.mediumsDataSpecificRegion.all[key].tper,
            "employed" : this.state.mediumsDataSpecificRegion.all[key].ecnt,
            "employedPerc" : this.state.mediumsDataSpecificRegion.all[key].eper,
            "unemployed" : this.state.mediumsDataSpecificRegion.all[key].ucnt,
            "unemployedPerc" : this.state.mediumsDataSpecificRegion.all[key].uper
          })
        // }
      });

      tempAgeArray = [{
        "keys" : ["percentage"],
        "data" : specificRegionData
      },{
        "keys" : ["percentage"],
        "data" : allRegionsData
      }];

      this.setState({
        userTypeMediumsScoresData : tempAgeArray
      }, () => {
        console.log(this.state.userTypeMediumsScoresData)
      })
    }
    catch(e) {
      console.log("error in mediumDataMapping");
      console.log(e);
    }

  }


  transportDataMapping = () => {
    console.log(this.state.transportDataAllRegions);
    console.log(this.state.transportDataSpecificRegion);

    try {
      
      var tempAgeArray = this.state.userTypeTransportsScoresData;
      var allRegionsData = [];
      var specificRegionData = [];

      var keys = Object.keys(this.state.transportDataAllRegions.all);
      // var keyLenth = keys.length;
      
      keys.map(key => {
        // if(key < keyLenth) {
          var name = "";
          if(key == 0) {
            name = "Public transport";
          } else if(key == 1) {
            name = "Taxi";
          } else if(key == 2) {
            name = "Get a lift";
          } else if(key == 3) {
            name = "Own bicycle";
          } else if(key == 4) {
            name = "Own motorbike";
          } else if(key == 5) {
            name = "Own car";
          } 
          allRegionsData.push({
            "name" : name,
            "respondents" : this.state.transportDataAllRegions.all[key].tcnt,
            "percentage" : this.state.transportDataAllRegions.all[key].tper,
            "employed" : this.state.transportDataAllRegions.all[key].ecnt,
            "employedPerc" : this.state.transportDataAllRegions.all[key].eper,
            "unemployed" : this.state.transportDataAllRegions.all[key].ucnt,
            "unemployedPerc" : this.state.transportDataAllRegions.all[key].uper
          })
        // }
      });

      var keys2 = Object.keys(this.state.transportDataSpecificRegion.all);
      // var keyLenth2 = keys2.length;
      
      keys2.map(key => {
        // if(key < keyLenth2) {
          var name = "";
          if(key == 0) {
            name = "Public transport";
          } else if(key == 1) {
            name = "Taxi";
          } else if(key == 2) {
            name = "Get a lift";
          } else if(key == 3) {
            name = "Own bicycle";
          } else if(key == 4) {
            name = "Own motorbike";
          } else if(key == 5) {
            name = "Own car";
          } 
          specificRegionData.push({
            "name" : name,
            "respondents" : this.state.transportDataSpecificRegion.all[key].tcnt,
            "percentage" : this.state.transportDataSpecificRegion.all[key].tper,
            "employed" : this.state.transportDataSpecificRegion.all[key].ecnt,
            "employedPerc" : this.state.transportDataSpecificRegion.all[key].eper,
            "unemployed" : this.state.transportDataSpecificRegion.all[key].ucnt,
            "unemployedPerc" : this.state.transportDataSpecificRegion.all[key].uper
          })
        // }
      });

      tempAgeArray = [{
        "keys" : ["percentage"],
        "data" : specificRegionData
      },{
        "keys" : ["percentage"],
        "data" : allRegionsData
      }];

      this.setState({
        userTypeTransportsScoresData : tempAgeArray
      }, () => {
        console.log(this.state.userTypeTransportsScoresData)
      })
    }
    catch(e) {
      console.log("error in transportDataMapping");
      console.log(e);
    }

  }

  // regionwiseSituationsMapping = () => {
    

  // }


  toggleCitiesBar = () => {
    this.setState({
      citiesListToggle : !this.state.citiesListToggle
    }, () => {
      // if(this.state.citiesListToggle) {
      //   $('body').css({overflow: 'hidden'})
      // }else{
      //   $('body').css({overflow: ''})
      // }
    })
  }

  toggleModalInfo = () => {
    this.setState({
      integratedFormId : "ODY5Mg==",
      integratedFormToggle : true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);

      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  toggleModalChat = () => {
    this.setState({
      integratedFormId : "ODY5Mw==",
      integratedFormToggle : true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);
      
      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  closeModalForm = () => {
    this.setState({
      integratedFormToggle : false,
      formSource : ""
    })
  }
  
    
  render() {

    var situationsDataArray = []; // for situations with average bar chart
    
    this.state.situations.map((situation, key) => {

      var averageValue = 0;
      
      try{
        if(this.state.situationsAvgDataLoc == 0) { // if specific selected city data needs to map for situations tag
          averageValue = this.situationsAverageMapping(this.state.situationsAvgSpecificCity[situation].totallCorrectAns, this.state.situationsAvgSpecificCity[situation].totallCandidates);
        }
        else{
          averageValue = this.situationsAverageMapping(this.state.situationsAvg[situation].totallCorrectAns, this.state.situationsAvg[situation].totallCandidates);
        }
      }
      catch(error){
        // console.log(error);
      }

      situationsDataArray.push({
        score : averageValue,
        value : situation
      })


    });
      
    return (
      <div className="TalentMapStatsComponent">
        {/* <Header /> */}

        <div>

            {/* <Modal size="md" centered={true} isOpen={this.state.showModalInfo} toggle={this.toggleModalInfo} className="customModal">
              <ModalHeader toggle={this.toggleModalInfo}>Arrange a meeting with your city’s officials</ModalHeader>
              <ModalBody>
                <p>
                These dashboards have been created specifically to help city officials understand the skill-set available within their jurisdiction. These dashboards can be made available to city officials to facilitate evidence-based policymaking, which will lead to up-skilling and job creation.
                <br/><br/>
                We would like to schedule an appointment and meet with the relevant officials within your city and discuss how these dashboards can be made available to you. Please tell us about yourself and your city.
                </p>
                <a href="https://form.assessmentfund.com/ODY5Mg==" target="blank">
                  <Button block size="md" color="primary">
                    <FontAwesomeIcon style={{marginRight: '10px'}} icon={faCalendarCheck} size="1x" />
                    Let's start
                  </Button>
                </a>
              </ModalBody>
            </Modal> */}

            {/* <Modal size="md" centered={true} isOpen={this.state.showModalChat} toggle={this.toggleModalChat} className="customModal">
              <ModalHeader toggle={this.toggleModalChat}>Become a policy advisor for Digital Transformation</ModalHeader>
              <ModalBody>
                <p>
                We welcome individuals from academia, the development sector, and consultants to recommend strategies for the city based on the data that is available through these dashboards.<br/><br/>Strategies that are recommended will be made available to city officials who can choose to implement them as a part of their digital transformation initiatives. Please tell us about yourself.
                </p>
                <a href="https://form.assessmentfund.com/ODY5Mw==" target="blank">
                  <Button block size="md" color="primary">
                    <FontAwesomeIcon style={{marginRight: '10px'}} icon={faComment} size="1x" />
                    Let's start
                  </Button>
                </a>
              </ModalBody>
            </Modal> */}

            <Container fluid>
                <Row style={{ padding: '3vh 0' }}>
                    {/* <Col style={{padding: '5vh 0'}} md={{ size: 12 }}>
                      <h3>Students Results - Overall</h3>
                    </Col> */}





                    {/* section for mayer information and city additional info - starts here */}
                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div>
                          <Row style={{padding: '25px'}}>

                            <Col md={{ size: 4 }}>
                              <div className="schoolBox topSchoolBox">
                                <Row>
                                  <Col md={{size: 12}}>
                                    <div style={{verticalAlign: 'middle', padding: '0vh 0'}}>
                                      <div className="circleBox imageBack" style={{backgroundImage: "url("+ this.state.cityLogo +")"}}>
                                        {/* <Media className="cityLogo" object src={this.state.cityLogo} alt="" /> */}
                                      </div>
                                      <p style={{margin: 0, color: '#7a8596', position: 'relative', top: '8px'}}>City or District</p>
                                      <h3 className="cityName">{this.state.selectedRegion}</h3>
                                    </div>
                                  </Col>
                                </Row>
                                {/* <p className="titleTag">City</p> */}
                              </div>
                            </Col>

                            <Col md={{ size: 4 }}>
                              <div className="schoolBox topSchoolBox">
                                <Row>
                                  <Col md={{size: 12}}>
                                    <div style={{verticalAlign: 'middle', padding: '0vh 0'}}>
                                      <div className="circleBox imageBack" style={{backgroundImage: "url("+ this.state.mayerImage +")"}}>
                                        {/* <Media className="cityLogo" object src={this.state.mayerImage} alt="" /> */}
                                      </div>
                                      <p style={{margin: 0, color: '#7a8596', position: 'relative', top: '8px'}}>Special thanks to city official</p>
                                      <h3 className="cityName">{this.state.mayerName}</h3>
                                    </div>
                                  </Col>
                                </Row>
                                {/* <p className="titleTag">Mayer</p> */}
                              </div>
                            </Col>

                            <Col md={{ size: 4 }}>
                              <div className="schoolBox topSchoolBox">
                                <Row>
                                  <Col>
                                    <h3 className="cityName">TalentMap in {this.state.selectedRegion}</h3>
                                    <div className="oneInfo">
                                      <div className="iconBox">
                                        <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                        <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" />
                                      </div>
                                      <p style={{color: '#7a8596'}}>People assessed</p>
                                      <h3>{this.state.genderScoresData[0] ? (this.state.genderScoresData[0].count1 + this.state.genderScoresData[0].count2) : 0}</h3>
                                    </div>
                                    <div className="oneInfo">
                                      <div className="iconBox">
                                        <FontAwesomeIcon className="iconHere" icon={faSchool} size="4x" />
                                      </div>
                                      <p style={{color: '#7a8596', maxWidth: "140px"}}>Edu institutions</p>
                                      <h3>{this.state.totalInstitutes ? this.state.totalInstitutes : 0}</h3>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>

                            
                                    
                          </Row>
                        </div>
                      </div>
                    </Col>
                    {/* section for mayer information and city additional info - ends here */}







                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Overview of 21st skills' proficiency </h5>
                            </Col>
                            
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.situationsAvgDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ situationsAvgDataLoc : this.state.situationsAvgDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.situationsAvgDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row style={{padding: '25px'}}>

                            <Col md={{ size: 6 }}>
                              <div className="schoolBox">
                                <Row>
                                  <Col md={{size: 7}}>
                                    <div style={{verticalAlign: 'middle', padding: '5vh 0'}}>
                                      <div className="circleBox">
                                        <h2>15</h2>
                                        <p>*Skills tested for<br/>the TalentMap</p>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={{size: 5}} style={{textAlign: 'left'}}>
                                    <div style={{color: '#fff', position: 'relative', top: '12px'}}>

                                      {
                                        this.state.ourSkills.map((skill, key) => {

                                          var isSkill = false;
                                          if(skill.skill_name == this.state.selectedSkill){ // compare with skill id from url
                                            isSkill = true;
                                          }

                                          return (
                                            <span style={{
                                              opacity : !isSkill && this.state.skillUpdateLoader ? '0.4': '1'
                                              // color : !isSkill && this.state.skillUpdateLoader ? '#000': '#fff'
                                            }} key={key} className={isSkill ? 'skillHere highlighted' : 'skillHere'} onClick={e => this.updateSkill(skill.skill_name, skill.id)}>
                                              {
                                                isSkill && this.state.skillUpdateLoader ? 
                                                  <Spinner className="skillSpinner" size="sm" color="light" />
                                                : null
                                              }
                                              {skill.skill_name}
                                            </span>
                                          )
                                        })
                                      }

                                    </div>
                                    
                                  </Col>
                                  <Col className="bottomLabel" md={{size: 12}}>
                                      <p>* Skills recommended by the World Bank<br />for 21st century workforce</p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>

                            <Col md={{ size: 6 }}>
                              <div className="schoolBox">
                                <Row>
                                  <Col md={{size: 6}}>
                                    <div style={{verticalAlign: 'middle', padding: '7vh 0'}}>
                                      <div className="circleBox2" style={{width: '280px', height: '280px'}}>
                                        <h2 style={{lineHeight: '190px'}}>{this.state.situations.length}</h2>
                                        <p>*Situations tested in
                                          <br/>
                                          <strong style={{fontSize: '18px'}}>{this.state.selectedSkill}</strong>
                                        </p>
                                        {/* animated infinite bounce delay-2s */}
                                        <div className="smallCircle">
                                          { this.state.situationsAvgDataLoc == 0 ? this.state.selectedSkillAvgSpecificCity : this.state.selectedSkillAvg }<span>%</span>
                                          <p>*Average<br />score</p>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={{size: 6}} style={{textAlign: 'left', paddingRight: '0', position: 'relative'}}>
                                    <div className="situationsParent">

                                      {
                                        this.state.situations.map((situation, key) => {

                                          // var isSkill = false;
                                          var dotColor = "red";
                                          var averageValue = 0;
                                          
                                          try{
                                            if(this.state.situationsAvgDataLoc == 0) {
                                              averageValue = this.situationsAverageMapping(this.state.situationsAvgSpecificCity[situation].totallCorrectAns, this.state.situationsAvgSpecificCity[situation].totallCandidates);
                                            } else {
                                              averageValue = this.situationsAverageMapping(this.state.situationsAvg[situation].totallCorrectAns, this.state.situationsAvg[situation].totallCandidates);
                                            }

                                            if(averageValue >= 60 && averageValue <=79){
                                              dotColor = "yellow";
                                            }
                                            if(averageValue >= 80 && averageValue <=100){
                                              dotColor = "#2cf32c";
                                            }

                                          }
                                          catch(error){
                                            // console.log(error);
                                          }

                                          return (
                                            // <div key={key} className={isSkill ? 'skillHere small highlighted' : 'small skillHere'}>
                                            //   { situation.value }
                                            //   <span className="dot" style={{background: dotColor, color: dotColor}}>
                                            //     <span className="dotText">
                                            //       {situation.score}<span style={{fontSize: '10px'}}>%</span>
                                            //     </span>
                                            //   </span>
                                            // </div>

                                            <div key={key} className='small skillHere'>
                                              { situation }
                                              {
                                                averageValue != 0 ?
                                                  <span className="dot" style={{background: dotColor, color: dotColor}}>
                                                    <span className="dotText">
                                                      { averageValue }
                                                      <span style={{fontSize: '10px'}}>%</span>
                                                    </span>
                                                  </span>
                                                : null
                                              }
                                            </div>
                                          )
                                        })
                                      }  
                                    </div>
                                    
                                  </Col>
                                  <Col className="bottomLabel" md={{size: 12}}>
                                      
                                      <p>* Average score of all the people<br />who took the test for {this.state.selectedSkill}</p>
                                      
                                      {
                                        this.state.situationsAvgDataLoc == 0 ?
                                          <p className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                                        : null
                                      }

                                  </Col>
                                </Row>
                              </div>
                            </Col>
                                    
                          </Row>
                        </div>
                      </div>
                    </Col>

                    
                    

                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead" >
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>
                                <strong>{this.state.selectedSkill}</strong> skill average scores comparison for 6 demographics
                              </h5>
                            </Col>
                            <Col md={{ size : 4 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: 'auto'}}>
                          

                          <Row style={{padding: '25px'}}>
                          <Col md={{ size: 12 }}>
                      <div className="chartBox" style={{background: 'transparent'}}>
                        {/* <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5><strong>Critical Thinking</strong> skill average scores comparison for 6 demographics</h5>
                            </Col>
                          </Row>
                        </div> */}
                        <div style={{height: '400px', position: 'relative'}}>

                          {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }
                              <ResponsiveBar
                                data={this.state.userTypeScoresData.data}
                                keys={this.state.userTypeScoresData.keys}
                                indexBy="type"
                                margin={{ top: 60, right: 30, bottom: 60, left: 100 }}
                                padding={0.5}
                                innerPadding={10}
                                label={d => `${d.value}%`}
                                // onClick={e => this.barChartClicked(e)}
                                groupMode="grouped"
                                // colors={{ scheme: 'paired' }}
                                // colors={["#f706d5", "#770367" ]}
                                colors={["#68366f", "#3d1f42" ]}
                                layout="vertical"
                                enableGridY= {false}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 50
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -60
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                legends={[
                                  {
                                      dataFrom: 'keys',
                                      anchor: 'top-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: -15,
                                      translateY: -60,
                                      itemsSpacing: 2,
                                      itemWidth: 100,
                                      itemHeight: 25,
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 0.85,
                                      symbolSize: 20,
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]}
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                            />

                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox" style={{border: '1px #0544a2 solid', borderRadius: '0'}}>
                        <div className="titleHead" style={{background: '#0b2548', borderBottom: '1px #0544a2 solid'}}>
                          <Row>
                            <Col md={{ size : 10 }}>
                              <h5><strong>{this.state.selectedSkill}</strong> skill average scores comparison for 6 demographics</h5>
                            </Col>
                            <Col md={{ size : 2 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col>
                            
                          </Row>
                        </div>
                        <div style={{height: '400px', background: '#05162d', position: 'relative'}}>
                          
                          {
                            this.state.skillUpdateLoader ?
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }
                              <ResponsiveRadar
                                data={this.state.userTypeScoresData.data}
                                keys={this.state.userTypeScoresData.keys}
                                indexBy="type"
                                maxValue="auto"
                                margin={{ top: 70, right: 120, bottom: 80, left: 150 }}
                                curve="linearClosed"
                                borderWidth={2}
                                borderColor={{ from: 'color', modifiers: [] }}
                                gridLevels={1}
                                gridShape="circular"
                                gridLabelOffset={25}
                                enableDots={true}
                                dotSize={12}
                                dotColor={{ from: 'color', modifiers: [] }}
                                dotBorderWidth={0}
                                dotBorderColor={{ from: 'color' }}
                                enableDotLabel={false}
                                dotLabel="value"
                                dotLabelYOffset={-12}
                                colors={["#93519c", "#e84f9a" ]}
                                fillOpacity={0}
                                blendMode="normal"
                                animate={true}
                                motionStiffness={200}
                                motionDamping={7}
                                isInteractive={true}
                                legends={[
                                  {
                                      anchor: 'top-left',
                                      direction: 'column',
                                      translateX: -90,
                                      translateY: -50,
                                      itemWidth: 80,
                                      itemHeight: 25,
                                      itemTextColor: '#999',
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: '#000'
                                              }
                                          }
                                      ]
                                  }
                              ]}
                            />

                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox"  style={{border: '1px #0544a2 solid', borderRadius: '0'}}>
                        <div className="titleHead" style={{background: '#0b2548', borderBottom: '1px #0544a2 solid'}}>
                          <Row>
                            <Col md={{ size : 7 }}>
                              <h5><strong>{this.state.selectedSkill}</strong> skill assessment participation</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 5 }}>
                                
                                <div className="quickIcons">
                                  <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                  <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                                </div>
                                
                                <Switch
                                  checked={this.state.userTypeScoresDataPie1Loc == 1 ? true : false}
                                  onChange={e => this.setState({ userTypeScoresDataPie1Loc : this.state.userTypeScoresDataPie1Loc == 0 ? 1 : 0 }) }
                                  uncheckedIcon={
                                    <div className="switchLabel">
                                      {this.state.selectedRegion}
                                    </div>
                                  }
                                  checkedIcon={
                                    <div className="switchLabel">
                                      All Cities
                                    </div>
                                  }
                                  className={this.state.userTypeScoresDataPie1Loc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                  id="icon-switch"
                                />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '400px', background: '#05162d', position: 'relative'}}>
                          {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }
                          <ResponsivePie
                            data={this.state.userTypeScoresDataPie1[this.state.userTypeScoresDataPie1Loc]}
                            margin={{ top: 80, right: 180, bottom: 80, left: 180 }}
                            innerRadius={0.5}
                            padAngle={0}
                            // sliceLabel={function(e){return e.value+"%"}}
                            sliceLabel={function(e){return e.value}}
                            cornerRadius={0}
                            colors={["#3d1f42", "#542b5a", "#68366f", "#93519c", "#68356f", "#93519c"]}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                            // enableRadialLabels={false}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkOffset={-24}
                            radialLabelsLinkDiagonalLength={16}
                            radialLabelsLinkHorizontalLength={24}
                            radialLabelsLinkStrokeWidth={1}
                            radialLabelsLinkColor={{ from: 'color' }}
                            slicesLabelsSkipAngle={10}
                            slicesLabelsTextColor="#333333"
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            // legends={[
                            //     {
                            //         anchor: 'top-left',
                            //         direction: 'column',
                            //         translateY: -25,
                            //         translateX: -160,
                            //         itemWidth: 200,
                            //         itemHeight: 25,
                            //         itemsSpacing: 10,
                            //         itemTextColor: '#999',
                            //         symbolSize: 16,
                            //         symbolShape: 'circle',
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemTextColor: '#000'
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                          />
                        </div>
                      </div>
                    </Col>
                          </Row>


                        </div>
                      </div>
                    </Col>                    
                    

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              {/* <h5><strong>Critical Thinking</strong> skill assessment participation</h5> */}

                              <h5>Avg. score for 10 situations assessed within <strong>{this.state.selectedSkill}</strong></h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.situationsAvgDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ situationsAvgDataLoc : this.state.situationsAvgDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.situationsAvgDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"

                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '440px', position: 'relative'}}>
                          {/* {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" />
                              </div>
                            : null
                          } */}
                          
                          <ResponsiveBar
                              data={situationsDataArray}
                              keys={[ 'score' ]}
                              indexBy="value"
                              margin={{ top: 50, right: 40, bottom: 60, left: 400 }}
                              padding={0.2}
                              innerPadding={5}
                              label={d => `${d.value}%`}
                              // onClick={e => this.barChartClicked(e)}
                              groupMode="stacked"
                              // colors={{ scheme: 'paired' }}
                              colors={["#d63333", "#f706d5" ]}
                              layout="horizontal"
                              enableGridY= {false}
                              defs={[
                                  {
                                      id: 'dots',
                                      type: 'patternDots',
                                      background: 'inherit',
                                      color: '#38bcb2',
                                      size: 4,
                                      padding: 1,
                                      stagger: true
                                  },
                                  {
                                      id: 'lines',
                                      type: 'patternLines',
                                      background: 'inherit',
                                      color: '#eed312',
                                      rotation: -45,
                                      lineWidth: 6,
                                      spacing: 10
                                  }
                              ]}
                              fill={[
                                  {
                                      match: {
                                          id: 'fries'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'sandwich'
                                      },
                                      id: 'lines'
                                  }
                              ]}
                              borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              axisTop={null}
                              axisRight={null}
                              axisBottom={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: 50
                              }}
                              axisLeft={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: -60
                              }}
                              labelSkipWidth={12}
                              labelSkipHeight={12}
                              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              // legends={[
                              //     {
                              //         dataFrom: 'keys',
                              //         anchor: 'top-right',
                              //         direction: 'column',
                              //         justify: false,
                              //         translateX: -15,
                              //         translateY: -40,
                              //         itemsSpacing: 2,
                              //         itemWidth: 100,
                              //         itemHeight: 25,
                              //         itemDirection: 'left-to-right',
                              //         itemOpacity: 0.85,
                              //         symbolSize: 20,
                              //         effects: [
                              //             {
                              //                 on: 'hover',
                              //                 style: {
                              //                     itemOpacity: 1
                              //                 }
                              //             }
                              //         ]
                              //     }
                              // ]}
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                          />

                          {
                            this.state.situationsAvgDataLoc == 0 ?
                              <p style={{fontSize: '12px', color: '#435f84', left: '5px'}} className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                            : null
                          }
                          
                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 7 }}>
                              {/* <h5><strong>Critical Thinking</strong> skill assessment participation</h5> */}

                              <h5>Gender participation in <strong>{this.state.selectedSkill}</strong></h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 5 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.genderScoresDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ genderScoresDataLoc : this.state.genderScoresDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.genderScoresDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '440px', position: 'relative'}}>
                          {
                            this.state.GenderUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }
                          <div className="schoolBox" style={{border: 'none', background: 'transparent'}}>
                            {
                              this.state.genderScoresData && this.state.genderScoresData.length ?
                              <Row>
                                <Col md={{size: 6}}>
                                  <div style={{verticalAlign: 'middle', padding: '5vh 0'}}>
                                    <div className="circleBox2 extend">

                                      <FontAwesomeIcon className="iconHere" icon={faFemale} size="8x" />

                                      <div className="smallCircle">
                                        {
                                          Math.floor(this.state.genderScoresData[this.state.genderScoresDataLoc].average1)
                                        }<span>%</span>
                                        <p>*gender<br />distribution</p>
                                      </div>
                                    </div>
                                    <p style={{marginTop: '20px', color: '#fff', fontSize: '1.3rem'}}>
                                      <span style={{fontSize: '2rem'}}>
                                        {
                                          this.state.genderScoresData[this.state.genderScoresDataLoc].count1
                                        }
                                      </span>
                                      <br/>people assessed
                                    </p>
                                  </div>
                                </Col>
                                <Col md={{size: 6}}>
                                  <div style={{verticalAlign: 'middle', padding: '5vh 0'}}>
                                    <div className="circleBox2 extend extend2">
                                      
                                      <FontAwesomeIcon className="iconHere" icon={faMale} size="8x" />

                                      <div className="smallCircle">
                                        {
                                          Math.ceil(this.state.genderScoresData[this.state.genderScoresDataLoc].average2)
                                        }
                                        <span>%</span>
                                        <p>*gender<br />distribution</p>
                                      </div>
                                    </div>
                                    <p style={{marginTop: '20px', color: '#fff', fontSize: '1.3rem'}}>
                                      <span style={{fontSize: '2rem'}}>
                                        {
                                          this.state.genderScoresData[this.state.genderScoresDataLoc].count2
                                        }
                                      </span>
                                      <br/>people assessed
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              : null
                            }
                          </div>
                          
                        </div>
                      </div>
                    </Col>


                    {/* <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>Avg. score for 10 situations assessed with in <strong>Critical Thinking</strong></h5>
                            </Col>
                          </Row>
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <div style={{width: '100%', height: '800px'}}s>
                            <ResponsiveSwarmPlot
                              data={this.state.swarmDataAllRegions}
                              groups={this.state.swarmDataSituations}
                              value="volume"
                              valueFormat={d => `${d}`}
                              // valueScale={{ type: 'linear', min: 0, max: 100 }}
                              size={{ key: 'score', values: [ 1, 100 ], sizes: [ 6, 20 ] }}
                              layout="horizontal"
                              forceStrength={4}
                              simulationIterations={100}
                              borderColor={{
                                  from: 'color',
                                  modifiers: [
                                      [
                                          'darker',
                                          0.6
                                      ],
                                      [
                                          'opacity',
                                          0.5
                                      ]
                                  ]
                              }}
                              margin={{ top: 80, right: 400, bottom: 80, left: 400 }}
                              // axisTop={{
                              //     orient: 'top',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'group if vertical, price if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: -80
                              // }}
                              // axisRight={{
                              //     orient: 'right',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'price if vertical, group if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: 76
                              // }}
                              // axisBottom={{
                              //     orient: 'bottom',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'group if vertical, price if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: 46
                              // }}
                              // axisLeft={{
                              //     orient: 'left',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'price if vertical, group if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: -76
                              // }}
                              motionStiffness={50}
                              motionDamping={10}
                            />
                          </div>
                        </div>
                      </div>
                    </Col> */}





                    {/* <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>Distribution and comparison of scores in multiple regions, that tested for <strong>{this.state.selectedSkill}</strong></h5>
                            </Col>
                          </Row>
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <div style={{width: '100%', height: '800px'}}s>
                            <ResponsiveSwarmPlot
                              data={this.state.swarmDataAllRegions}
                              groups={this.state.swarmDataSituations}
                              value="score"
                              valueFormat={d => `${d}%`}
                              valueScale={{ type: 'linear', min: 0, max: 100 }}
                              size={{ key: 'volume', values: [ 1, 3000 ], sizes: [ 6, 20 ] }}
                              layout="horizontal"
                              forceStrength={4}
                              simulationIterations={100}
                              borderColor={{
                                  from: 'color',
                                  modifiers: [
                                      [
                                          'darker',
                                          0.6
                                      ],
                                      [
                                          'opacity',
                                          0.5
                                      ]
                                  ]
                              }}
                              margin={{ top: 80, right: 400, bottom: 80, left: 400 }}
                              // axisTop={{
                              //     orient: 'top',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'group if vertical, price if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: -80
                              // }}
                              // axisRight={{
                              //     orient: 'right',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'price if vertical, group if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: 76
                              // }}
                              // axisBottom={{
                              //     orient: 'bottom',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'group if vertical, price if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: 46
                              // }}
                              // axisLeft={{
                              //     orient: 'left',
                              //     tickSize: 10,
                              //     tickPadding: 5,
                              //     tickRotation: 0,
                              //     legend: 'price if vertical, group if horizontal',
                              //     legendPosition: 'middle',
                              //     legendOffset: -76
                              // }}
                              motionStiffness={50}
                              motionDamping={10}
                            />
                          </div>
                        </div>
                      </div>
                    </Col> */}


                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Respondent's Age Brackets</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.ageScoreDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ ageScoreDataLoc : this.state.ageScoreDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.ageScoreDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"

                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '440px', position: 'relative'}}>
                          {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }

                          <Row>
                            <Col md={{size : 6}}>
                              <Table className="tableForData">
                                <thead>
                                  <tr>
                                    <th>Age brackets</th>
                                    <th>Respondents</th>
                                    <th>Percentage</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.userTypeAgeScoresData[this.state.ageScoreDataLoc].data && this.state.userTypeAgeScoresData[this.state.ageScoreDataLoc].data.map((data, key) => 
                                      <tr key={key}>
                                        <td>{data.name}</td>
                                        <td>{data.respondents}</td>
                                        <td>{data.percentage}%</td>
                                      </tr>
                                    )
                                  }

                                </tbody>
                              </Table>
                            </Col>
                            <Col md={{size : 6}} style={{height: '440px'}}>
                              <ResponsiveBar
                                  data={this.state.userTypeAgeScoresData[this.state.ageScoreDataLoc].data}
                                  keys={this.state.userTypeAgeScoresData[this.state.ageScoreDataLoc].keys}
                                  indexBy="name"
                                  margin={{ top: 60, right: 30, bottom: 80, left: 60 }}
                                  padding={0.25}
                                  innerPadding={5}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  // colors={{ scheme: 'paired' }}
                                  // colors={["#f706d5", "#770367" ]}
                                  colors={[ "#dc8700", "#3d1f42" ]}
                                  layout="vertical"
                                  enableGridY= {false}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: 'Respondents',
                                      legendPosition: 'middle',
                                      legendOffset: 50
                                  }}
                                  axisLeft={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: 'Percent',
                                      legendPosition: 'middle',
                                      legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                //   legends={[
                                //     {
                                //         dataFrom: 'keys',
                                //         anchor: 'top-right',
                                //         direction: 'column',
                                //         justify: false,
                                //         translateX: -15,
                                //         translateY: -60,
                                //         itemsSpacing: 2,
                                //         itemWidth: 100,
                                //         itemHeight: 25,
                                //         itemDirection: 'left-to-right',
                                //         itemOpacity: 0.85,
                                //         symbolSize: 20,
                                //         effects: [
                                //             {
                                //                 on: 'hover',
                                //                 style: {
                                //                     itemOpacity: 1
                                //                 }
                                //             }
                                //         ]
                                //     }
                                // ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </Col>
                          </Row>                          
                          
                        </div>
                      </div>
                    </Col>


                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Respondents applying for positions</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.positionScoreDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ positionScoreDataLoc : this.state.positionScoreDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.positionScoreDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"

                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '440px', position: 'relative'}}>
                          {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }

                          <Row>
                            <Col md={{size : 6}}>
                              <Table className="tableForData">
                                <thead>
                                  <tr>
                                    <th>Position level</th>
                                    <th>Respondents</th>
                                    <th>Percentage</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.userTypePositionsScoresData[this.state.positionScoreDataLoc].data && this.state.userTypePositionsScoresData[this.state.positionScoreDataLoc].data.map((data, key) => 
                                      <tr key={key}>
                                        <td>{data.name}</td>
                                        <td>{data.respondents}</td>
                                        <td>{data.percentage}%</td>
                                      </tr>
                                    )
                                  }

                                </tbody>
                              </Table>
                            </Col>
                            <Col md={{size : 6}} style={{height: '440px'}}>
                              <ResponsiveRadar
                                  data={this.state.userTypePositionsScoresData[this.state.positionScoreDataLoc].data}
                                  keys={this.state.userTypePositionsScoresData[this.state.positionScoreDataLoc].keys}
                                  indexBy="name"
                                  maxValue="auto"
                                  margin={{ top: 70, right: 120, bottom: 80, left: 150 }}
                                  curve="linearClosed"
                                  borderWidth={2}
                                  borderColor={{ from: 'color', modifiers: [] }}
                                  gridLevels={2}
                                  gridShape="circular"
                                  gridLabelOffset={25}
                                  enableDots={true}
                                  dotSize={15}
                                  dotColor={{ from: 'color', modifiers: [] }}
                                  dotBorderWidth={0}
                                  dotBorderColor={{ from: 'color' }}
                                  enableDotLabel={false}
                                  dotLabel="value"
                                  dotLabelYOffset={-12}
                                  colors={["#2dade8", "#2dade8" ]}
                                  fillOpacity={0}
                                  blendMode="normal"
                                  animate={true}
                                  motionStiffness={200}
                                  motionDamping={7}
                                  isInteractive={true}
                                  legends={[
                                    {
                                        anchor: 'top-left',
                                        direction: 'column',
                                        translateX: -90,
                                        translateY: -50,
                                        itemWidth: 80,
                                        itemHeight: 25,
                                        itemTextColor: '#999',
                                        symbolSize: 12,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                              />
                            </Col>
                          </Row>                          
                          
                        </div>
                      </div>
                    </Col>


                    
                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>People using various job search mediums</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.mediumScoreDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ mediumScoreDataLoc : this.state.mediumScoreDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.mediumScoreDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"

                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '440px', position: 'relative'}}>
                          {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }

                          <Row>
                            <Col md={{size: 5}}>
                              <Table className="tableForData">
                                <thead>
                                  <tr>
                                    <th>Job mediums</th>
                                    <th>Respondents</th>
                                    <th>Percentage</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.userTypeMediumsScoresData[this.state.mediumScoreDataLoc].data && this.state.userTypeMediumsScoresData[this.state.mediumScoreDataLoc].data.map((data, key) => 
                                      <tr key={key}>
                                        <td>{data.name}</td>
                                        <td>{data.respondents}</td>
                                        <td>{data.percentage}%</td>
                                      </tr>
                                    )
                                  }

                                </tbody>
                              </Table>
                            </Col>
                            <Col md={{size: 7}} style={{height: '440px'}}>
                              <ResponsiveBar
                                  data={this.state.userTypeMediumsScoresData[this.state.mediumScoreDataLoc].data}
                                  keys={this.state.userTypeMediumsScoresData[this.state.mediumScoreDataLoc].keys}
                                  indexBy="name"
                                  margin={{ top: 30, right: 30, bottom: 60, left: 140 }}
                                  padding={0.25}
                                  innerPadding={5}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  // colors={{ scheme: 'paired' }}
                                  // colors={["#f706d5", "#770367" ]}
                                  colors={["#d41b5a", "#d41b5a" ]}
                                  layout="horizontal"
                                  enableGridY= {false}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: 50
                                  }}
                                  axisLeft={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                //   legends={[
                                //     {
                                //         dataFrom: 'keys',
                                //         anchor: 'top-right',
                                //         direction: 'column',
                                //         justify: false,
                                //         translateX: -15,
                                //         translateY: -60,
                                //         itemsSpacing: 2,
                                //         itemWidth: 100,
                                //         itemHeight: 25,
                                //         itemDirection: 'left-to-right',
                                //         itemOpacity: 0.85,
                                //         symbolSize: 20,
                                //         effects: [
                                //             {
                                //                 on: 'hover',
                                //                 style: {
                                //                     itemOpacity: 1
                                //                 }
                                //             }
                                //         ]
                                //     }
                                // ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </Col>
                          </Row>                          
                          
                        </div>
                      </div>
                    </Col>


                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>People using various transportation to get to jobs</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              <Switch
                                checked={this.state.transportScoreDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ transportScoreDataLoc : this.state.transportScoreDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.transportScoreDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"

                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '440px', position: 'relative'}}>
                          {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                {/* <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" /> */}
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          }

                          <Row>
                            <Col md={{size: 5}}>
                              <Table className="tableForData">
                                <thead>
                                  <tr>
                                    <th>Reasons</th>
                                    <th>Respondents</th>
                                    <th>Percentage</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.userTypeTransportsScoresData[this.state.transportScoreDataLoc].data && this.state.userTypeTransportsScoresData[this.state.transportScoreDataLoc].data.map((data, key) => 
                                      <tr key={key}>
                                        <td>{data.name}</td>
                                        <td>{data.respondents}</td>
                                        <td>{data.percentage}%</td>
                                      </tr>
                                    )
                                  }

                                </tbody>
                              </Table>
                            </Col>
                            <Col md={{size: 7}} style={{height: '440px'}}>
                              <ResponsiveBar
                                  data={this.state.userTypeTransportsScoresData[this.state.transportScoreDataLoc].data}
                                  keys={this.state.userTypeTransportsScoresData[this.state.transportScoreDataLoc].keys}
                                  indexBy="name"
                                  margin={{ top: 30, right: 30, bottom: 60, left: 140 }}
                                  padding={0.25}
                                  innerPadding={5}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  // colors={{ scheme: 'paired' }}
                                  // colors={["#f706d5", "#770367" ]}
                                  colors={["#e89e2e"]}
                                  layout="horizontal"
                                  enableGridY= {false}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: 50
                                  }}
                                  axisLeft={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                //   legends={[
                                //     {
                                //         dataFrom: 'keys',
                                //         anchor: 'top-right',
                                //         direction: 'column',
                                //         justify: false,
                                //         translateX: -15,
                                //         translateY: -60,
                                //         itemsSpacing: 2,
                                //         itemWidth: 100,
                                //         itemHeight: 25,
                                //         itemDirection: 'left-to-right',
                                //         itemOpacity: 0.85,
                                //         symbolSize: 20,
                                //         effects: [
                                //             {
                                //                 on: 'hover',
                                //                 style: {
                                //                     itemOpacity: 1
                                //                 }
                                //             }
                                //         ]
                                //     }
                                // ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </Col>
                          </Row>                          
                          
                        </div>
                      </div>
                    </Col>

                    

                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Respondents categorized into 12 profiles based on Skill, Active and Motivated (SAM)</h5>
                            </Col>
                            <Col md={{ size: 4 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        {/* Here we'll call component for SAM profile */}
                        {
                          this.state.respondentsCatogorizeSamData ?
                            <SamProfileComponent data={this.state.respondentsCatogorizeSamData} />
                          : null
                        }

                      </div>
                    </Col>


                    <Col md={{ size: 12 }}>

                      {/* Here we'll call component for swarm data chart for comparison for top 10 cities */}
                      {
                        this.state.situations && this.state.situations.length ?
                          <DistributionAndComparisonTopCitiesComponent selectedRegion={this.state.selectedRegion} selectedSkill={this.state.selectedSkill} selectedSkillId={this.state.selectedSkillId} situations={this.state.situations} situationsAvg={this.state.situationsAvg} situationsAvgSpecificCity={this.state.situationsAvgSpecificCity} />
                        : null
                      }

                    </Col>






                   
                </Row>
            </Container>
        </div>

        <div className={this.state.citiesListToggle ? "citiesListBarContainer active" : 'citiesListBarContainer'}>
          <Container fluid>
            <Row style={{textAlign: 'left'}}>
              {
                this.state.isCitiesLoading ? 
                  <div className="customSpinnerChart">
                    {/* <Spinner style={{width: '2rem', height: '2rem'}} size="lg" color="light" /> */}
                    
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                : 
                  <CitiesListSidebarComponent selectedSkillId={this.state.selectedSkillId} selectedRegion={this.state.selectedRegion}></CitiesListSidebarComponent>
              }
              
            </Row>
          </Container>
          
          <Button onClick={e => this.toggleCitiesBar()} className={ this.state.citiesListToggle ? "citiesListBarSwitch opened" : "citiesListBarSwitch"} color="primary">
            {
              this.state.citiesListToggle ? 
                <FontAwesomeIcon className="iconHere" icon={this.state.citiesListToggle ? faTimes : faBars} size="1x" />
              : 
                <span>change<br/>city</span>
            }
          </Button>
        </div>

        <div className={this.state.integratedFormToggle ? "integratedFormSideBar active" : 'integratedFormSideBar'}>
          {
            this.state.formSource !== "" ?
              <iframe src={this.state.formSource}></iframe>
            : null
          }

          <Button onClick={e => this.closeModalForm()} className={ this.state.integratedFormToggle ? this.state.integratedFormId == "ODY5Mg==" ? "citiesListBarSwitch green opened" : "citiesListBarSwitch yellow opened" : "citiesListBarSwitch"} color="primary">
                <FontAwesomeIcon className="iconHere" icon={faTimes} size="1x" />
          </Button>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
    
  }
}

TalentMapStatsComponent.propTypes = {
  getAllSkills: PropTypes.func.isRequired,
  // getSpecificCitySkills: PropTypes.func.isRequired,
  getDemographicAvg: PropTypes.func.isRequired,
  getGenderClassificationAvg: PropTypes.func.isRequired,
  getAgeClassificationAvg: PropTypes.func.isRequired,
  getAllCitiesData: PropTypes.func.isRequired,
  getPositionClassificationAvg : PropTypes.func.isRequired,
  getMediumsClassificationAvg : PropTypes.func.isRequired,
  getTransportClassificationAvg : PropTypes.func.isRequired

}

const mapStatesToProps = state => ({
  skillsResponse: state.webRed.skillsResponse,
  skillsCitySpecificResponse: state.webRed.skillsCitySpecificResponse,
  skillsCitySpecificResponseMoreInfo: state.webRed.skillsCitySpecificResponseMoreInfo,
  skillsCitySpecificAvgScoreResponse: state.webRed.skillsCitySpecificAvgScoreResponse,
  demographicResponse: state.webRed.demographicResponse,
  demographicRegionResponse: state.webRed.demographicRegionResponse,
  genderResponse: state.webRed.genderResponse,
  genderRegionResponse: state.webRed.genderRegionResponse,
  ageResponse: state.webRed.ageResponse,
  ageRegionResponse: state.webRed.ageRegionResponse,
  positionsAvgResponse: state.webRed.positionsAvgResponse,
  positionsAvgRegionResponse: state.webRed.positionsAvgRegionResponse,
  mediumAvgResponse: state.webRed.mediumAvgResponse,
  mediumAvgRegionResponse: state.webRed.mediumAvgRegionResponse,
  transportAvgResponse: state.webRed.transportAvgResponse,
  transportAvgRegionResponse: state.webRed.transportAvgRegionResponse,
  // citiesDataResponse: state.webRed.citiesDataResponse,
  formTriggerResponse : state.webRed.formTriggerResponse
})

export default connect(mapStatesToProps, { getAllSkills, getAllCitiesData, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg, getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg })(TalentMapStatsComponent);