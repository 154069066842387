import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./../../../public/scss/snippets/BannerWithSlider.scss";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
//   Button,
  Container,
  Row,
  Col
} from "reactstrap";

class BannerWithSlider extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        sliderArray: props.sliderData
    };
  }

  componentDidMount(){
      console.log(this.state.sliderArray);
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {

    // var settings = {
    //     this.props.sliderData.settings
    // };

    return (
      <div className="BannerWithSlider">

        <Slider {...this.state.sliderArray.settings}>
            {
                this.state.sliderArray.data.map((item, key) =>
                <div key={key}>
                    <div className="slideContainer" style={{ height: item.height ? item.height : '', backgroundColor: item.backgroundColor, backgroundImage: "url("+ item.backgroundImage +")"}} >
                        <Container>
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <h1 className="BannerWithText_heading" dangerouslySetInnerHTML={{__html: item.text}}></h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                )
            }
        </Slider>

      </div>
    );
  }
}

export default BannerWithSlider;
