import {
    
	_getSkills,
	_getSpecificSkills,
	_getSpecificSkillsMoreInfo,
	_getSpecificSkillsAvg,
	_getSpecificSkillsDemoPurpose,
	_getDemographicAvg,
	_getDemographicRegionAvg,
	_getGenderAvg,
	_getGenderRegionAvg,
	_getAgeAvg,
	_getAgeRegionAvg,
	_getCenturySkills,
	_getSchools,
	_getCitiesData,
	_triggerForm,
	_getPositionAvg,
	_getPositionRegionAvg,
	_getMediumAvg,
	_getMediumRegionAvg,
	_getTransportAvg,
	_getTransportRegionAvg,
	_switchBranchFromSidebar
    
} from '../actions/types';

const initialState = {

	skillsResponse: '',
	skillsCitySpecificResponse: '',
	skillsCitySpecificResponseMoreInfo : '',
	skillsCitySpecificAvgScoreResponse : '',
	skillsCitySpecificResponseDemo : '',
	demographicResponse: '',
	demographicRegionResponse: '',
	genderResponse: '',
	genderRegionResponse: '',
	ageResponse: '',
	ageRegionResponse: '',
	getCenturySkills: '',
	schoolsResponse : '',
	citiesDataResponse : '',
	formTriggerResponse : '',
	positionsAvgResponse : '',
	positionsAvgRegionResponse : '',
	mediumAvgResponse : '',
	mediumAvgRegionResponse : '',
	transportAvgResponse : '',
	transportAvgRegionResponse : '',
	switchedBranch: ''
};

export default function(state = initialState, action) {
	switch (action.type) {
		case _getSkills:
			return {
				...state,
				skillsResponse: action.payload
			};
		case _getSpecificSkills:
			return {
				...state,
				skillsCitySpecificResponse: action.payload
			};
		case _getSpecificSkillsMoreInfo:
			return {
				...state,
				skillsCitySpecificResponseMoreInfo: action.payload
			};
		case _getSpecificSkillsAvg:
			return {
				...state,
				skillsCitySpecificAvgScoreResponse: action.payload
			};
		case _getSpecificSkillsDemoPurpose:
			return {
				...state,
				skillsCitySpecificResponseDemo: action.payload
			};
		case _getDemographicAvg:
			return {
				...state,
				demographicResponse: action.payload
			};
		case _getDemographicRegionAvg:
			return {
				...state,
				demographicRegionResponse: action.payload
			};
		case _getGenderAvg:
			return {
				...state,
				genderResponse: action.payload
			};
		case _getGenderRegionAvg:
			return {
				...state,
				genderRegionResponse: action.payload
			};
		case _getAgeAvg:
			return {
				...state,
				ageResponse: action.payload
			};
		case _getAgeRegionAvg:
			return {
				...state,
				ageRegionResponse: action.payload
			};
		case _getCenturySkills:
			return {
				...state,
				getCenturySkills: action.payload
			}
		case _getSchools:
			return {
				...state,
				schoolsResponse: action.payload
			}
		case _getCitiesData:
			return {
				...state,
				citiesDataResponse: action.payload
			}
		case _triggerForm:
			return {
				...state,
				formTriggerResponse: action.payload
			}
		case _getPositionAvg:
			return {
				...state,
				positionsAvgResponse: action.payload
			}
		case _getPositionRegionAvg:
			return {
				...state,
				positionsAvgRegionResponse: action.payload
			}
		case _getMediumAvg:
			return {
				...state,
				mediumAvgResponse: action.payload
			}
		case _getMediumRegionAvg:
			return {
				...state,
				mediumAvgRegionResponse: action.payload
			}
		case _getTransportAvg:
			return {
				...state,
				transportAvgResponse: action.payload
			}
		case _getTransportRegionAvg:
			return {
				...state,
				transportAvgRegionResponse: action.payload
			}
		case _switchBranchFromSidebar:
			return {
				...state,
				switchedBranch: action.payload
			};
		default:
			return state;
	}
}
