import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { updateBranchAction } from '../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

  
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class BranchesListSidebar extends Component {

  constructor(props) {
      super(props);

      this.state = {
          // isCitiesLoading : false,
        //   cityUpdateLoader : false,
        //   verifiedCities : [],
          allBranches : [{
            branchid : 0,
            branchname: "BACOLOD"
          },{
            branchid : 1,
            branchname: "CEBU"
          }],
          filteredBranches : [],
          selectedBranchId : this.props.selectedBranchId,
          selectedBranch : this.props.selectedBranch,
          branchSearchText : ""
      };

  }

  componentDidMount() {

  }

  updateBranch = (branch, branchId) => {
    // console.log('checking the component changes Faheem');
    // if(!this.state.cityUpdateLoader) { // if city data is already populated so they

      this.setState({
        selectedBranch : branch,
        selectedBranchId : branchId,
        // selectedBranch : branch // mapping region with city also as both are same things now
      },
      () => {

        // this.setState({
        //   cityUpdateLoader : true
        // }, () => {
          //call updateBranch action so that it'll reflect the branch change in main component
          this.props.updateBranchAction(this.state.selectedBranchId, this.state.selectedBranch);
          
        //   //call selected city/region gender data
        //   this.props.getGenderClassificationAvg(this.state.selectedSkillId, this.state.selectedRegion);
          
        //   //call selected city/region age data
        //   this.props.getAgeClassificationAvg(this.state.selectedRegion);

        //   //call selected city/region position data
        //   this.props.getPositionClassificationAvg(this.state.selectedRegion);

        //   //call selected city/region medium data
        //   this.props.getMediumsClassificationAvg(this.state.selectedRegion);

        //   //call selected city/region transportation data
        //   this.props.getTransportClassificationAvg(this.state.selectedRegion);

        //   // calls an action for getting skills/situations against each specific city.
        //   this.props.getSpecificCitySkills(this.state.selectedRegion, this.state.selectedCityId);
        
        // })
        
      })

    // }

  }


  componentWillReceiveProps(nextProps, thisProps) {

    if(this.props.selectedBranchId != nextProps.selectedBranchId) {
      this.setState({
        selectedBranchId : nextProps.selectedBranchId
      }, () => {
        console.log("selectedBranchId: " + this.state.selectedBranchId)
      })
    }

    if(this.props.selectedBranch != nextProps.selectedBranch) {
      this.setState({
        selectedBranch : nextProps.selectedBranch
      }, () => {
        console.log("selectedBranch: " + this.state.selectedBranch)
      })
    }


  }

  filterBranches = (e) => {
    this.setState({
      branchSearchText : e.target.value
    }, () => {
        
      var branchFilterArray = [];

      this.state.allBranches.map(branch => {
        
        var bName = branch.branchname.toLowerCase();

        if(bName.match(this.state.branchSearchText)) {
            branchFilterArray.push(branch);
        }

      })

      this.setState({
        filteredBranches : branchFilterArray
      }, () => {
        console.log(this.state.filteredBranches);
      })

    })
  }
  
    
  render() {

    let branches = this.state.filteredBranches.length ? this.state.filteredBranches : this.state.allBranches;
      
    return (
      <Col md={{ size: 12 }}>
        <h6 style={{margin: '10px 0', color: '#fff', borderBottom: '1px #23344c solid', padding: '10px 3px', fontSize: '18px'}}>Select a branch</h6>
        
        <FormGroup>
            <Input type="text" name="citySearchField" id="citySearchField" value={this.state.branchSearchText} placeholder="Quick search" onChange={(e) => this.filterBranches(e)}/>
        </FormGroup>

        <div className="mainCitiesBox" style={{color: '#fff', position: 'relative'}}>
          {
            this.state.branchSearchText.length && !this.state.filteredBranches.length ?
              <span style={{textAlign: 'center', width: '100%', fontSize: '12px'}}>we couldn't find any matches for "{this.state.branchSearchText}"</span>
            :
            branches.map((branch, key) => {

              var isBranch = false;
              if(branch.branchname == this.state.selectedBranch){ // compare with skill id from url
                isBranch = true;
              }

              return (
                <span style={{ opacity : '1', color : "#fff" }} key={key} className={isBranch ? 'cityHere highlighted' : 'cityHere'} onClick={e => this.updateBranch(branch.branchname, branch.branchid)}>
                  {/* {
                    isBranch && this.state.cityUpdateLoader ? 
                      <Spinner className="citySpinner" size="sm" color="light" />
                    : null
                  } */}
                  {branch.branchname}
                </span>
              )
            })
          }  
        </div>

      </Col>
    );
    
  }
}

BranchesListSidebar.propTypes = {
  updateBranchAction: PropTypes.func.isRequired,
}

const mapStatesToProps = state => ({
})

export default connect(mapStatesToProps, { updateBranchAction })(BranchesListSidebar);