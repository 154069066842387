import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {browserHistory} from "react-router";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { ResponsivePie } from '@nivo/pie';

class PieChartComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data : [
                {
                    "id": "loading",
                    "label": "loading",
                    "value": 0,
                    "color": "hsl(288, 70%, 50%)"
                }
            ],
            selectedColor : "#f1621c"
        };


    }

  componentDidMount() {

    if(this.props.color) {
        this.setState({
            data : this.props.data,
            selectedColor : this.props.color
        })
    }
    else{
        this.setState({
            data : this.props.data
        })
    }

  }

    
  render() {
      
    return (
        <div className="PieChartComponent">
            
            <h3 style={{position: 'absolute', left: 'calc(50% - 20px)', top: '30%', width: '40px', textAlign: 'center'}}>
                {this.state.data[0].value}
            </h3>
            
            <div style={{height: '95px'}}>
                <ResponsivePie
                    data={this.state.data}
                    margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
                    innerRadius={0.85}
                    padAngle={0}
                    sliceLabel={''}
                    cornerRadius={0}
                    colors={[this.state.selectedColor, "#2a4977"]}
                    borderWidth={0}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                    radialLabelsSkipAngle={0}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    // radialLabel={false}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={0}
                    radialLabelsLinkColor={{ from: 'color' }}
                    slicesLabelsSkipAngle={0}
                    slicesLabelsTextColor="#333333"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    
                />
            </div>

        </div>
    );
    
  }
}

export default PieChartComponent;