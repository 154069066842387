import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import "../../public/scss/ObjectivesComponent.scss";
import Header from './Header';
import Footer from "./Footer";
import MethodologyComponent from "./assessments/MethodologyComponent";
import ValidityComponent from "./assessments/ValidityComponent";
import ReportsComponent from "./assessments/ReportsComponent";
// import ReportsComponent from "./assessments/ReportsComponent";

import bannerImageObj from './../../public/images/21stCenturyBanner.png';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class AssessmentsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }
    
  render() {
    const { params } = this.props.match;
    return (
      <div className="AssessmentsComponent">
        <Header />

          {
            params.id == 'methodology' ?
              <MethodologyComponent></MethodologyComponent>
            : null
          }
          {
            params.id == 'validity-reliability' ?
              <ValidityComponent></ValidityComponent>
            : null
          }
          {
            params.id == 'reports-analytics' ?
              <ReportsComponent></ReportsComponent>
            : null
          }
        
        <Footer />
      </div>
    );
  }
}

export default AssessmentsComponent;
