import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {browserHistory} from "react-router";
import "./../../public/scss/reports.scss";
import Header from './../website/Header';
import Footer from "./../website/Footer";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import {
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsiveRadar } from '@nivo/radar';


const skills = [
  "Math Functional Skill",
  "English Functional Skill",
  "English Comprehension",
  "Workplace Ethics",
  "Decision Making",
  "Planning and Organizing",
  "Creative Problem Solving",
  "Innovation"
]

class PtmiRegionalStatsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            regionData: [
              {
                region : "NCR",
                total : 12583,
                topSkill_1 : 74,
                topSkill_2 : 75,
                // topSkill_3 : 74,
                // topSkill_4 : 0,
                topSkill_3 : 0,
                topSkill_4 : 66,
                // lowSkill_1 : 60,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                // lowSkill_4 : 0
                lowSkill_4 : 52
              },
              {
                region : "Region 7",
                total : 3604,
                topSkill_1 : 75,
                topSkill_2 : 73,
                topSkill_3 : 0,
                topSkill_4 : 72,
                lowSkill_1 : 0,
                lowSkill_2 : 56,
                lowSkill_3 : 56,
                lowSkill_4 : 58
              },
              {
                region : "Calabarzon",
                total : 3293,
                // topSkill_1 : 0,
                topSkill_1 : 72,
                topSkill_2 : 77,
                // topSkill_3 : 76,
                topSkill_3 : 0,
                topSkill_4 : 76,
                // lowSkill_1 : 60,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                // lowSkill_4 : 0
                lowSkill_4 : 55
              },
              {
                region : "Region 10",
                total : 3205,
                topSkill_1 : 65,
                topSkill_2 : 69,
                topSkill_3 : 0,
                topSkill_4 : 65,
                // lowSkill_1 : 51,
                lowSkill_1 : 0,
                lowSkill_2 : 51,
                lowSkill_3 : 51,
                // lowSkill_4 : 0
                lowSkill_4 : 48
              },
              {
                region : "Region 3",
                total : 2988,
                topSkill_1 : 80,
                topSkill_2 : 80,
                topSkill_3 : 0,
                topSkill_4 : 78,
                lowSkill_1 : 0,
                lowSkill_2 : 63,
                lowSkill_3 : 60,
                lowSkill_4 : 63
              },
              {
                region : "Region 12",
                total : 801,
                topSkill_1 : 68,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 64,
                lowSkill_1 : 0,
                lowSkill_2 : 52,
                lowSkill_3 : 50,
                lowSkill_4 : 53
              },
              {
                region : "Region 1",
                total : 872,
                topSkill_1 : 70,
                topSkill_2 : 71,
                topSkill_3 : 0,
                topSkill_4 : 71,
                lowSkill_1 : 0,
                lowSkill_2 : 55,
                lowSkill_3 : 53,
                lowSkill_4 : 57
              },
              {
                region : "Region 13",
                total : 902,
                topSkill_1 : 67,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 0,
                lowSkill_2 : 50,
                lowSkill_3 : 52,
                lowSkill_4 : 52
              },
              {
                region : "Region 5",
                total : 932,
                topSkill_1 : 76,
                topSkill_2 : 77,
                topSkill_3 : 0,
                topSkill_4 : 74,
                // lowSkill_1 : 59,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 56,
                // lowSkill_4 : 0
                lowSkill_4 : 54
              },
              {
                region : "Region 11",
                total : 2412,
                topSkill_1 : 76,
                topSkill_2 : 74,
                topSkill_3 : 0,
                topSkill_4 : 75,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 57
              }
            ],
            regionDataAscending: [
              {
                region : "Region 12",
                total : 801,
                topSkill_1 : 68,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 64,
                lowSkill_1 : 0,
                lowSkill_2 : 52,
                lowSkill_3 : 50,
                lowSkill_4 : 53
              },
              {
                region : "Region 1",
                total : 872,
                topSkill_1 : 70,
                topSkill_2 : 71,
                topSkill_3 : 0,
                topSkill_4 : 71,
                lowSkill_1 : 0,
                lowSkill_2 : 55,
                lowSkill_3 : 53,
                lowSkill_4 : 57
              },
              {
                region : "Region 13",
                total : 902,
                topSkill_1 : 67,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 0,
                lowSkill_2 : 50,
                lowSkill_3 : 52,
                lowSkill_4 : 52
              },
              {
                region : "Region 5",
                total : 932,
                topSkill_1 : 76,
                topSkill_2 : 77,
                topSkill_3 : 0,
                topSkill_4 : 74,
                lowSkill_1 : 59,
                lowSkill_2 : 59,
                lowSkill_3 : 56,
                lowSkill_4 : 0
              },
              {
                region : "Region 11",
                total : 2412,
                topSkill_1 : 76,
                topSkill_2 : 74,
                topSkill_3 : 0,
                topSkill_4 : 75,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 57
              },
              {
                region : "Region 3",
                total : 2988,
                topSkill_1 : 80,
                topSkill_2 : 80,
                topSkill_3 : 0,
                topSkill_4 : 78,
                lowSkill_1 : 0,
                lowSkill_2 : 63,
                lowSkill_3 : 60,
                lowSkill_4 : 63
              },
              {
                region : "Region 10",
                total : 3205,
                topSkill_1 : 65,
                topSkill_2 : 69,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 51,
                lowSkill_2 : 51,
                lowSkill_3 : 51,
                lowSkill_4 : 0
              },
              {
                region : "Calabarzon",
                total : 3293,
                topSkill_1 : 0,
                topSkill_2 : 77,
                topSkill_3 : 76,
                topSkill_4 : 76,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              },
              {
                region : "Region 7",
                total : 3604,
                topSkill_1 : 75,
                topSkill_2 : 73,
                topSkill_3 : 0,
                topSkill_4 : 72,
                lowSkill_1 : 0,
                lowSkill_2 : 56,
                lowSkill_3 : 56,
                lowSkill_4 : 58
              },
              {
                region : "NCR",
                total : 12583,
                topSkill_1 : 74,
                topSkill_2 : 75,
                topSkill_3 : 74,
                topSkill_4 : 0,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              }
            ],
            regionDataDescending: [
              {
                region : "NCR",
                total : 12583,
                topSkill_1 : 74,
                topSkill_2 : 75,
                topSkill_3 : 74,
                topSkill_4 : 0,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              },
              {
                region : "Region 7",
                total : 3604,
                topSkill_1 : 75,
                topSkill_2 : 73,
                topSkill_3 : 0,
                topSkill_4 : 72,
                lowSkill_1 : 0,
                lowSkill_2 : 56,
                lowSkill_3 : 56,
                lowSkill_4 : 58
              },
              {
                region : "Calabarzon",
                total : 3293,
                topSkill_1 : 0,
                topSkill_2 : 77,
                topSkill_3 : 76,
                topSkill_4 : 76,
                lowSkill_1 : 60,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 0
              },
              {
                region : "Region 10",
                total : 3205,
                topSkill_1 : 65,
                topSkill_2 : 69,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 51,
                lowSkill_2 : 51,
                lowSkill_3 : 51,
                lowSkill_4 : 0
              },
              {
                region : "Region 3",
                total : 2988,
                topSkill_1 : 80,
                topSkill_2 : 80,
                topSkill_3 : 0,
                topSkill_4 : 78,
                lowSkill_1 : 0,
                lowSkill_2 : 63,
                lowSkill_3 : 60,
                lowSkill_4 : 63
              },
              {
                region : "Region 11",
                total : 2412,
                topSkill_1 : 76,
                topSkill_2 : 74,
                topSkill_3 : 0,
                topSkill_4 : 75,
                lowSkill_1 : 0,
                lowSkill_2 : 59,
                lowSkill_3 : 57,
                lowSkill_4 : 57
              },
              {
                region : "Region 5",
                total : 932,
                topSkill_1 : 76,
                topSkill_2 : 77,
                topSkill_3 : 0,
                topSkill_4 : 74,
                lowSkill_1 : 59,
                lowSkill_2 : 59,
                lowSkill_3 : 56,
                lowSkill_4 : 0
              },
              {
                region : "Region 13",
                total : 902,
                topSkill_1 : 67,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 65,
                lowSkill_1 : 0,
                lowSkill_2 : 50,
                lowSkill_3 : 52,
                lowSkill_4 : 52
              },
              {
                region : "Region 1",
                total : 872,
                topSkill_1 : 70,
                topSkill_2 : 71,
                topSkill_3 : 0,
                topSkill_4 : 71,
                lowSkill_1 : 0,
                lowSkill_2 : 55,
                lowSkill_3 : 53,
                lowSkill_4 : 57
              },
              {
                region : "Region 12",
                total : 801,
                topSkill_1 : 68,
                topSkill_2 : 68,
                topSkill_3 : 0,
                topSkill_4 : 64,
                lowSkill_1 : 0,
                lowSkill_2 : 52,
                lowSkill_3 : 50,
                lowSkill_4 : 53
              }
            ],
            regionSample1: [{
              "region": "loading",
              "sample": 0,
              "sampleColor": "hsl(219, 70%, 50%)"
            }],
            regionSample2: {
              "name": "loading",
              "color": "hsl(241, 70%, 50%)",
              "loc": 1
            },
            regionSampleGroupedTop: {},
            regionSampleGroupedLow: {},
            regionSampleGrouped: [{
              name : "Top Skills",
              data : [{
                "region": "loading",
                "Math Functional Skill": 0,
                "English Functional Skill": 0,
                "English Comprehension": 0,
                "Workplace Ethics": 0
              }],
              keys : [
                      "Math Functional Skill",
                      "English Functional Skill",
                      "English Comprehension",
                      "Workplace Ethics"
                    ],
              indexBy : "region"
            },{
              name : "Low Skills",
              data : [{
                "region": "loading",
                "Math Functional Skill": 0,
                "English Functional Skill": 0,
                "English Comprehension": 0,
                "Workplace Ethics": 0
              }],
              keys : [
                      "Decision Making",
                      "Planning and Organizing",
                      "Creative Problem Solving",
                      "Innovation"
                    ],
              indexBy : "region"
            }],
            orderOption : 'lowest',
            // skillChartOption : 'top',
            skilldataindex : 1,
            regionSample3Top: {},
            regionSample3Low: {},
            regionSample3: [{
              "data" : [{
                "region": "loading",
                "Math Functional Skill": 0,
                "English Functional Skill": 0,
                "English Comprehension": 0,
                "Workplace Ethics":0
              }],
              "keys" : ["Math Functional Skill", "English Functional Skill", "English Comprehension", "Workplace Ethics"],
              "indexBy" : "region"
            },{
              "data" : [{
                "region": "loading",
                "Decision Making": 0,
                "Planning and Organizing": 0,
                "Creative Problem Solving": 0,
                "Innovation":0
              }],
              "keys" : ["Decision Making", "Planning and Organizing", "Creative Problem Solving", "Innovation"],
              "indexBy" : "region"
            }],
            regionSample3New: {
              "data" : [{
                "skill": "loading",
                "value": 0
              }],
              "keys" : ["value"],
              "indexBy" : "skill"
            },
            comparisonRegion1 : 3,
            comparisonRegion2 : 4,
            skilldataindexRadar : 0,
            // modal: false,
            // skillCompareArray : [
            //   "Region 10",
            //   "Calabarzon"
            // ]
        };

      // this.toggleModal = this.toggleModal.bind(this);

    }


  // toggleModal() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }

  componentDidMount() {

    setTimeout(()=> {
      this.mapBarChartData(this.state.regionDataAscending); //ini the first bar chart
    }, 800);

    //mapping data for sample size grouped with skills for regions
    var dataArray2 = [];
    this.state.regionData.map((data, key) => { //loop for extracting data for regions
      console.log(data, key);
      // var field = this.formatChartData(data, 'top');
      dataArray2.push({
        "region": data.region,
        "Math Functional Skill": parseInt(data.topSkill_1),
        "English Functional Skill": parseInt(data.topSkill_2),
        "English Comprehension": parseInt(data.topSkill_3),
        "Workplace Ethics": parseInt(data.topSkill_4)
      });
    })
    this.setState({
      regionSampleGroupedTop : dataArray2
    },
    () => {
      console.log(this.state.regionSampleGroupedTop);
    })

    var dataArray2_1 = [];
    this.state.regionData.map((data, key) => { //loop for extracting data for regions
      console.log(data, key);
      // var field = this.formatChartData(data, 'top');
      dataArray2_1.push({
        "region": data.region,
        "Decision Making": parseInt(data.lowSkill_1),
        "Planning and Organizing": parseInt(data.lowSkill_2),
        "Creative Problem Solving": parseInt(data.lowSkill_3),
        "Innovation": parseInt(data.lowSkill_4)
      });
    })
    this.setState({
      regionSampleGroupedLow : dataArray2_1
    },
    () => {
      console.log(this.state.regionSampleGroupedLow);

      setTimeout(() => {
        this.setRegionSkillsData();
      }, 1000);
    })

    
    var dataArray3 = [];
    this.state.regionData.map((data, key) => { //loop for extracting data for regions
      console.log(data, key);
      dataArray3.push({
        "name": data.region,
        "color": "hsl(178, 70%, 50%)",
        "loc": data.total
      });
    })

    setTimeout(() => {

      this.setState({
        regionSample2 : {
          "name": "Philippines",
          "color": "hsl(241, 70%, 50%)",
          "children": dataArray3
        }
      },
      () => {
        console.log(this.state.regionSample2);
      })

    }, 500)

    this.randomizeCompareRegionsRadar();

  }

  randomizeCompareRegionsRadar(){
    
    // this is for radar data.
    // var dataArray4_1 = [];
    // this.state.regionData.map((data, key) => { //loop for extracting data for regions
    //   console.log(data, key);
    //   dataArray4_1.push({
    //     "region": data.region,
    //     "Math Functional Skill": data.topSkill_1,
    //     "English Functional Skill": data.topSkill_2,
    //     "English Comprehension": data.topSkill_3,
    //     "Workplace Ethics": data.topSkill_4
    //   });
    // });

    // var dataArray4_2 = [];
    // this.state.regionData.map((data, key) => { //loop for extracting data for regions
    //   console.log(data, key);
    //   dataArray4_2.push({
    //     "region": data.region,
    //     "Decision Making": data.lowSkill_1,
    //     "Planning and Organizing": data.lowSkill_2,
    //     "Creative Problem Solving": data.lowSkill_3,
    //     "Innovation": data.lowSkill_4
    //   });
    // });


    // dummy work for temperary basis
    var dataArray4_3 = [];
    // for(var i = 0; i <= 1; i++){
    var data = this.state.regionData[this.state.comparisonRegion1];
    var data2 = this.state.regionData[this.state.comparisonRegion2];
    dataArray4_3.push({
      "skill": "Planning and Organizing",
      [data.region]: data.lowSkill_2,
      [data2.region]: data2.lowSkill_2
    },{
      "skill": "Creative Problem Solving",
      [data.region]: data.lowSkill_3,
      [data2.region]: data2.lowSkill_3
    },{
      "skill": "Innovation",
      [data.region]: data.lowSkill_4,
      [data2.region]: data2.lowSkill_4
    },{
      "skill": "Math Functional Skill",
      [data.region]: data.topSkill_1,
      [data2.region]: data2.topSkill_1
    },{
      "skill": "English Functional Skill",
      [data.region]: data.topSkill_2,
      [data2.region]: data2.topSkill_2
    },{
      "skill": "Workplace Ethics",
      [data.region]: data.topSkill_4,
      [data2.region]: data2.topSkill_4
    });
    // }


    this.setState({
      // regionSample3Top : dataArray4_1,
      // regionSample3Low : dataArray4_2
    },
    () => {
      // console.log(this.state.regionSample3Top);
      // console.log(this.state.regionSample3Low);

      // setTimeout(() => {
        // this.setRegionSkillsDataRadar();
        this.setState({
          regionSample3New: {
            "data" : dataArray4_3,
            "keys" : [data.region, data2.region],
            "indexBy" : "skill"
          }
        })
      // }, 1000);

    })
  }

  mapBarChartData(regionData){
    //mapping data for sample size for regions
    var dataArray = [];
    regionData.map((data, key) => {
      dataArray.push(
        {
          "region": data.region,
          "sample": data.total,
          "sampleColor": "hsl(219, 70%, 50%)"
        }
      )
    })

    this.setState({
      regionSample1 : dataArray
    })

  }

  setRegionSkillsData(){
    this.setState({ //setting the main groupedBarChart State
      regionSampleGrouped: [{
        name : "Top Skills",
        data : this.state.regionSampleGroupedTop,
        keys : [
                "Math Functional Skill",
                "English Functional Skill",
                "English Comprehension",
                "Workplace Ethics"
              ],
        indexBy : "region"
      },{
        name : "Low Skills",
        data : this.state.regionSampleGroupedLow,
        keys : [
                "Decision Making",
                "Planning and Organizing",
                "Creative Problem Solving",
                "Innovation"
              ],
        indexBy : "region"
      }]
    })
  }

  setRegionSkillsDataRadar(){
    this.setState({ //setting the main groupedBarChart State
      regionSample3: [{
        "data" : this.state.regionSample3Top,
        "keys" : ["Math Functional Skill", "English Functional Skill", "English Comprehension", "Workplace Ethics"],
        "indexBy" : "region"
      },{
        "data" : this.state.regionSample3Low,
        "keys" : ["Decision Making", "Planning and Organizing", "Creative Problem Solving", "Innovation"],
        "indexBy" : "region"
      }]
    })
  }

  barChartClicked = (e) => {
    console.log(e);
    this.props.history.push('region/' + e.indexValue);
  }
  
  changeComparableRegions = (e) => {
    console.log(e);
    this.setState({
      comparisonRegion1 : Math.floor(Math.random() * 10)
    },
    () => {
      var randomNum = Math.floor(Math.random() * 9);
      if(this.state.comparisonRegion1 == randomNum){
        this.setState({
          comparisonRegion2 : randomNum + 1
        },
        () => { this.randomizeCompareRegionsRadar() })
      }
      else{
        this.setState({
          comparisonRegion2 : randomNum
        },
        () => { this.randomizeCompareRegionsRadar() })
      }
    })
  }

  changeChartOrder = (e, value) => {
    console.log(e, value);
    this.setState({
      orderOption : value
    },
    () => {
      if(value == 'lowest'){
        this.mapBarChartData(this.state.regionDataAscending);
      }
      else{
        this.mapBarChartData(this.state.regionDataDescending);
      }
    })
  }
  
  changeSkillChartOrder = (e, value) => {
    console.log(e, value);
    this.setState({
      skilldataindex : value
    })
  }
  changeSkillChartOrderRadar = (e, value) => {
    console.log(e, value);
    this.setState({
      skilldataindexRadar : value
    })
  }

  // formatChartData(format, type) {
  //   console.log(format)
  //   if(format.topSkill_1 == 0){
  //     return {
  //       "region": format.region,
  //       "English Functional Skill": format.topSkill_2,
  //       "English Comprehension": format.topSkill_3,
  //       "Workplace Ethics": format.topSkill_4
  //     };
  //   }
  //   else if(format.topSkill_2 == 0){
  //     return {
  //       "region": format.region,
  //       "Math Functional Skill": format.topSkill_1,
  //       "English Comprehension": format.topSkill_3,
  //       "Workplace Ethics": format.topSkill_4
  //     };
  //   }
  //   else if(format.topSkill_3 == 0){
  //     return {
  //       "region": format.region,
  //       "Math Functional Skill": format.topSkill_1,
  //       "English Functional Skill": format.topSkill_2,
  //       "Workplace Ethics": format.topSkill_4
  //     };
  //   }
  //   else if(format.topSkill_4 == 0){
  //     return {
  //       "region": format.region,
  //       "Math Functional Skill": format.topSkill_1,
  //       "English Functional Skill": format.topSkill_2,
  //       "English Comprehension": format.topSkill_3,
  //     };
  //   }
  //   else{
  //     return {
  //       "region": format.region,
  //       "Math Functional Skill": format.topSkill_1,
  //       "English Functional Skill": format.topSkill_2,
  //       "English Comprehension": format.topSkill_3,
  //       "Workplace Ethics": format.topSkill_4
  //     };
  //   }
  // }
    
  render() {
      
    return (
      <div className="PtmiRegionalStatsComponent">
        {/* <Header /> */}

        <div>

          {/* <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="selectRegionsModal">
            <ModalHeader toggle={this.toggleModal}>Select Regions for Comparison</ModalHeader>
            <ModalBody>
              {
                this.state.regionData
              }
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleModal}>Compare</Button>
            </ModalFooter>
          </Modal> */}

            <Container fluid>
                <Row style={{ padding: '3vh 0' }}>
                    {/* <Col style={{padding: '5vh 0'}} md={{ size: 12 }}>
                      <h3>Students Results - Overall</h3>
                    </Col> */}
                    <Col md={{ size: 7 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Number of participants from each region</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                              <Switch
                                checked={this.state.orderOption === 'highest'}
                                onChange={e => this.changeChartOrder(e, this.state.orderOption == 'lowest' ? 'highest' : 'lowest')}
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    Lowest
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    Highest
                                  </div>
                                }
                                className={this.state.orderOption == 'lowest' ? 'react-switch lowest' : 'react-switch highest'}
                                id="icon-switch"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '400px'}}>
                          <ResponsiveBar
                              data={this.state.regionSample1}
                              keys={[ 'sample' ]}
                              indexBy="region"
                              margin={{ top: 20, right: 30, bottom: 60, left: 120 }}
                              padding={0.3}
                              onClick={e => this.barChartClicked(e)}
                              groupMode="grouped"
                              colors={this.state.orderOption == 'lowest' ? ['#d63333'] : ['#008800']}
                              layout="horizontal"
                              enableGridY= {false}
                              defs={[
                                  {
                                      id: 'dots',
                                      type: 'patternDots',
                                      background: 'inherit',
                                      color: '#38bcb2',
                                      size: 4,
                                      padding: 1,
                                      stagger: true
                                  },
                                  {
                                      id: 'lines',
                                      type: 'patternLines',
                                      background: 'inherit',
                                      color: '#eed312',
                                      rotation: -45,
                                      lineWidth: 6,
                                      spacing: 10
                                  }
                              ]}
                              fill={[
                                  {
                                      match: {
                                          id: 'fries'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'sandwich'
                                      },
                                      id: 'lines'
                                  }
                              ]}
                              borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              axisTop={null}
                              axisRight={null}
                              axisBottom={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: 50
                              }}
                              axisLeft={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: -60
                              }}
                              labelSkipWidth={12}
                              labelSkipHeight={12}
                              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              // legends={[
                              //     {
                              //         dataFrom: 'keys',
                              //         anchor: 'top-right',
                              //         direction: 'column',
                              //         justify: false,
                              //         translateX: 120,
                              //         translateY: 0,
                              //         itemsSpacing: 2,
                              //         itemWidth: 100,
                              //         itemHeight: 20,
                              //         itemDirection: 'left-to-right',
                              //         itemOpacity: 0.85,
                              //         symbolSize: 20,
                              //         effects: [
                              //             {
                              //                 on: 'hover',
                              //                 style: {
                              //                     itemOpacity: 1
                              //                 }
                              //             }
                              //         ]
                              //     }
                              // ]}
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={{size: 5}}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>Number of participants from each region</h5>
                            </Col>
                            {/* <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                              
                            </Col> */}
                          </Row>
                        </div>
                        <div style={{height: '400px'}}>
                          <ResponsiveBubble
                            root={this.state.regionSample2}
                            margin={{ top: 30, right: 20, bottom: 30, left: 20 }}
                            identity="name"
                            value="loc"
                            // colors={{ scheme: 'paired' }}
                            colors={["#f1e15b", "#1f78b4"]}
                            padding={6}
                            // label="value"
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            defs={[
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'none',
                                    color: 'inherit',
                                    rotation: -45,
                                    lineWidth: 5,
                                    spacing: 8
                                }
                            ]}
                            // fill={[ { match: { depth: 1 }, id: 'lines' } ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={12}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={{size: 5}}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 9 }}>
                              {/* <h5>Skills comparison for Region 10 and Calabarzon</h5> */}
                              <h5>Score (%) comparison of 6 skills in {this.state.regionData[this.state.comparisonRegion1].region} and {this.state.regionData[this.state.comparisonRegion2].region}</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size: 3 }}>
                              <Button onClick={e => this.changeComparableRegions(e)} size="sm" outline color="warning">change regions</Button>
                              {/* <p style={{cursor: 'pointer', color: '#fff'}} onClick={e => this.changeComparableRegions(e)}>Change</p> */}
                            </Col>
                            {/* <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                              <Button onClick={this.toggleModal} color="secondary" size="sm">Change Regions</Button>
                            </Col> */}
                          </Row>
                        </div>
                        <div style={{height: '450px'}}>
                          
                          <ResponsiveRadar
                            data={this.state.regionSample3New.data}
                            keys={this.state.regionSample3New.keys}
                            indexBy={this.state.regionSample3New.indexBy}
                            maxValue="auto"
                            margin={{ top: 70, right: 120, bottom: 80, left: 120 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color', modifiers: [] }}
                            gridLevels={1}
                            gridShape="circular"
                            gridLabelOffset={25}
                            enableDots={true}
                            dotSize={24}
                            dotColor={{ from: 'color', modifiers: [] }}
                            dotBorderWidth={0}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            // dotLabel="value"
                            dotLabel={d => `${d.value}%`}
                            dotLabelYOffset={3}
                            colors={{ scheme: this.state.skilldataindexRadar == 0 ? 'category10' : 'nivo' }}
                            fillOpacity={0}
                            blendMode="normal"
                            animate={true}
                            motionStiffness={200}
                            motionDamping={7}
                            isInteractive={true}
                            // legends={[
                            //     {
                            //         anchor: 'top-left',
                            //         direction: 'column',
                            //         translateX: -50,
                            //         translateY: -40,
                            //         itemWidth: 80,
                            //         itemHeight: 20,
                            //         itemTextColor: '#999',
                            //         symbolSize: 12,
                            //         symbolShape: 'circle',
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemTextColor: '#000'
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                        />

                        </div>
                      </div>
                    </Col>
                    
                    {

                      this.state.regionSampleGrouped.length ? 

                        // this.state.regionSampleGrouped.map((skillsData, key) =>

                          <Col md={{ size: 7 }}>

                            <div className="chartBox">
                              <div className="titleHead">
                                <Row>
                                  <Col md={{ size : 8 }}>
                                    {/* <h5>{skillsData.name}</h5> */}
                                    {/* <h5>Average score per region in select skills {this.state.skilldataindex == 0 ? 'Top' : 'Low'} Skills</h5> */}
                                    <h5>Average score per region in select skills</h5>
                                  </Col>
                                  <Col style={{textAlign: 'right'}} md={{ size : 4 }}>
                                    <Switch
                                      checked={this.state.skilldataindex === 0}
                                      onChange={e => this.changeSkillChartOrder(e, this.state.skilldataindex == 0 ? 1 : 0)}
                                      uncheckedIcon={
                                        <div className="switchLabel">
                                          Low proficiency skills
                                        </div>
                                      }
                                      checkedIcon={
                                        <div className="switchLabel">
                                          High proficiency skills
                                        </div>
                                      }
                                      className={this.state.skilldataindex == 0 ? 'react-switch big highest' : 'react-switch big lowest'}
                                      id="icon-switch"
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div style={{height: '450px'}}>
                                <ResponsiveBar
                                  data={this.state.regionSampleGrouped[this.state.skilldataindex].data}
                                  keys={this.state.regionSampleGrouped[this.state.skilldataindex].keys}
                                  indexBy={this.state.regionSampleGrouped[this.state.skilldataindex].indexBy}
                                  margin={{ top: 60, right: 30, bottom: 30, left: 120 }}
                                  padding={0.3}
                                  groupMode="stacked"
                                  label={d => `${d.value}%`}
                                  layout="horizontal"
                                  enableGridY= {false}
                                  onClick={e => this.barChartClicked(e)}
                                  colors={{ scheme: this.state.regionSampleGrouped[this.state.skilldataindex].name == "Top Skills" ? 'paired' : 'spectral' }}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  // axisBottom={{
                                  //     tickSize: 5,
                                  //     tickPadding: 5,
                                  //     tickRotation: 0,
                                  //     legend: 'Top Skills',
                                  //     legendPosition: 'middle',
                                  //     legendOffset: 50
                                  // }}
                                  // axisLeft={{
                                  //     tickSize: 5,
                                  //     tickPadding: 5,
                                  //     tickRotation: 0,
                                  //     legend: 'Regions',
                                  //     legendPosition: 'middle',
                                  //     legendOffset: -100
                                  // }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  legends={[
                                      {
                                          dataFrom: 'keys',
                                          anchor: 'top-left',
                                          direction: 'row',
                                          justify: false,
                                          translateX: 0,
                                          translateY: -30,
                                          itemsSpacing: 2,
                                          itemWidth: 170,
                                          itemHeight: 20,
                                          itemDirection: 'left-to-right',
                                          itemOpacity: 0.85,
                                          symbolSize: 20,
                                          effects: [
                                              {
                                                  on: 'hover',
                                                  style: {
                                                      itemOpacity: 1
                                                  }
                                              }
                                          ]
                                      }
                                  ]}
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={15}
                                />
                              </div>
                            </div>

                          </Col>

                        // )

                        : null

                    }

                    

                   
                </Row>
            </Container>
        </div>
        
        {/* <Footer /> */}
      </div>
    );
    
  }
}

export default PtmiRegionalStatsComponent;
