import React, { Component } from "react";
import "../../public/scss/HomeComponent.scss";
import Header from './Header';
import Footer from "./Footer";

// importing snippets for homepage
import BannerWithText from './snippets/bannerWithText';
import BannerWithSlider from './snippets/bannerWithSlider';
import IconGridSection from './snippets/iconGridSection';

import bannerImage from './../../public/images/HR-Analytics.png';
import BPO_box_image from './../../public/images/BPO_box.png';
import scorecard1 from './../../public/images/scorecardSlider1.jpg';
import scorecard2 from './../../public/images/scorecardSlider2.jpg';
import scorecard3 from './../../public/images/scorecardSlider3.jpg';
import betterJobFit1 from './../../public/images/better-job-fit-patricia.jpg';
import betterJobFit2 from './../../public/images/better-job-fit-neeta.jpg';
import betterJobFit3 from './../../public/images/better-job-fit-james.jpg';
import betterJobFit4 from './../../public/images/better-job-fit-alicia.jpg';
import betterJobFit5 from './../../public/images/better-job-fit-alex.jpg';
import icon1 from './../../public/images/test-catalog.png';
import icon2 from './../../public/images/Customizable_org.png';
import icon3 from './../../public/images/Competencies_org.png';
import icon4 from './../../public/images/Valid_org.png';
import icon5 from './../../public/images/Reports_org.png';
import icon6 from './../../public/images/Program_org.png';
import icon7 from './../../public/images/Branded_org.png';
import icon8 from './../../public/images/Profiling_org.png';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class SolutionsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bannerData : {
                heading: "Haseeb quit his job to start his own company",
                btn_text: "See how the desk help him",
                btn_text_url: "/solutions",
                image_url: bannerImage,
                video_url: "http://testedtalent.com/videos/Jeff.mp4",
                themeColor: "yellow"
            },
            sliderData1: {
                data : [{
                    text: "Right talent, right away.",
                    backgroundColor: "#000c1d",
                    backgroundImage: ""
                },{
                    text: "Test your workforce using our assessment engine.",
                    backgroundColor: "#1d001b",
                    backgroundImage: ""
                },{
                    text: "We are talent providers.",
                    backgroundColor: "#1f1f1f",
                    backgroundImage: ""
                }],
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 400,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 4500,
                    draggable: false,
                    arrows: false
                }
            },
            sliderData2: {
                data : [{
                    text: "The Talent Score Card is a one <strong>page report</strong> that’s more effective than a resume.",
                    backgroundColor: "",
                    backgroundImage: scorecard1
                },{
                    text: "In <strong>depth analysis</strong> is also available through additional reports.",
                    backgroundColor: "",
                    backgroundImage: scorecard2
                },{
                    text: "An analysis on <strong>Strengths & Weaknessess</strong> puts things in perspective",
                    backgroundColor: "",
                    backgroundImage: scorecard3
                }],
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 400,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    draggable: false,
                    arrows: false
                }
            },
            sliderData3: {
                data : [{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit1,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit2,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit3,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit4,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit5,
                    height: '808px'
                }],
                settings: {
                    dots: false,
                    infinite: true,
                    speed: 400,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    fade: true,
                    pauseOnHover: false,
                    draggable: false
                }
            },
            iconsData: {
                heading: "Take a peek and explore our assessment centers",
                background: "#fff",
                data : [{
                    iconLabel: "Our Test Catalog",
                    iconColor: "#000",
                    iconImage: icon1
                },{
                    iconLabel: "Highly Customizable",
                    iconColor: "#000",
                    iconImage: icon2
                },{
                    iconLabel: "Identify Competencies",
                    iconColor: "#000",
                    iconImage: icon3
                },{
                    iconLabel: "It's Valid & Reliable",
                    iconColor: "#000",
                    iconImage: icon4
                },{
                    iconLabel: "Reports & Analytics",
                    iconColor: "#000",
                    iconImage: icon5
                },{
                    iconLabel: "Run your programs",
                    iconColor: "#000",
                    iconImage: icon6
                },{
                    iconLabel: "Branded for your company",
                    iconColor: "#000",
                    iconImage: icon7
                },{
                    iconLabel: "Profiling Mechanism",
                    iconColor: "#000",
                    iconImage: icon8
                }]
            },
            bannerData2 : {
                heading: "Tested Talent powers<br/>a BPO called the DESK <img style='max-width: 100%; margin: 25px 0;' src='"+ BPO_box_image +"' />",
                btn_text: "Visit the Desk",
                btn_text_url: "/the-desk",
                themeColor: "red",
                // image_url: "bannerImage",
                // video_url: "http://testedtalent.com/videos/Jeff.mp4"
            }
        };
    }
    
  render() {
    return (
      <div className="HomeComponent">
        <Header />

        <BannerWithText bannerData={this.state.bannerData} />
        <BannerWithSlider sliderData={this.state.sliderData1} />
        <BannerWithSlider sliderData={this.state.sliderData2} />
        <IconGridSection iconsData={this.state.iconsData} />
        <BannerWithSlider sliderData={this.state.sliderData3} />
        <BannerWithText bannerData={this.state.bannerData2} />
        
        <Footer />
      </div>
    );
  }
}

export default SolutionsComponent;
