import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

import TextWithImage from './../snippets/TextWithImage';

import exceprt_bg from './../../../public/images/exceprt_bg.png';
import methodologyImg1 from './../../../public/images/methodology/Methodology_step1.png';
import methodologyImg2 from './../../../public/images/methodology/Methodology_step2.png';
import methodologyImg3 from './../../../public/images/methodology/Methodology_step3.png';
import methodologyImg4 from './../../../public/images/methodology/Methodology_step4.png';
import methodologyImg5 from './../../../public/images/methodology/Methodology_step5.png';
import methodologyImg6 from './../../../public/images/methodology/Methodology_step6.png';
import methodologyImg7 from './../../../public/images/methodology/Methodology_step7.png';
import methodologyImg8 from './../../../public/images/methodology/Methodology_step8.png';
import methodologyImg9 from './../../../public/images/methodology/Methodology_step9.png';

class MethodologyComponent extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      section1 : {
          heading: "Conduct needs analysis",
          para: "First step to creating an assessment is to understand needs of the position (or project for which the assessment is being created). This also includes understanding the KPIs.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "white",
          image_url: methodologyImg1,
          position: 'ltr'
      },
      section2 : {
          heading: "Create competency frameworks",
          para: "Identify a list of competencies required for the position, and study how each competency maps to one another. This includes mapping behavior and skill competencies.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "gray",
          image_url: methodologyImg2,
          position: 'rtl'
      },
      section3 : {
          heading: "Identify constructs",
          para: "Each competency that is to be tested is mapped to 1°, 2°, and distortion measures. This will further ensure that the correct traits are being tested.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "white",
          image_url: methodologyImg3,
          position: 'ltr'
      },
      section4 : {
          heading: "Create Assessments",
          para: "Situation-oriented and case-based questions will be created for all the measures identified within each competency. Careful attention is given to industry, position, experience and education.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "gray",
          image_url: methodologyImg4,
          position: 'rtl'
      },
      section5 : {
          heading: "Run validity checks",
          para: "Validate to ensure that the correct competencies have been identifed. Ensure that we are taking into account the industry, position, experience, and education.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "white",
          image_url: methodologyImg5,
          position: 'ltr'
      },
      section6 : {
          heading: "Assign 3 difficulty levels",
          para: "Each competency will have questions segmented into 3 levels of difficulty.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "gray",
          image_url: methodologyImg6,
          position: 'rtl'
      },
      section7 : {
          heading: "Run reliability checks",
          para: "Once the tests have been created, each question goes through a set of reliability checks to ensure that the scores are a reliable indicator of performance.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "white",
          image_url: methodologyImg7,
          position: 'ltr'
      },
      section8 : {
          heading: "Ensure Integrity of Analysis",
          para: "To maintain its integrity, the tests and its answers are locked into our proprietary Question bank. Only authorized personnel have access.",
          btn_text: "",
          btn_text_url: "",
          themeColor: "gray",
          image_url: methodologyImg8,
          position: 'rtl'
      },
      section9 : {
          heading: "Ready to test!",
          para: "The tests and questions are uploaded to our Assessment Center and is ready for use!",
          btn_text: "",
          btn_text_url: "",
          themeColor: "white",
          image_url: methodologyImg9,
          position: 'ltr'
      }
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      console.log(thisProps, nextProps);
  }

  render() {
    return (
      <div className="MethodologyComponent" >

        <div className="pageHeader" style={{backgroundImage: 'url('+ exceprt_bg +')'}}>
            <Container>
                <Row>
                    <Col md={{ size: 12 }}>
                        <h2>Here is a 9-step overview of how Hirelabs customizes assessments</h2>
                    </Col>
                </Row>
            </Container>
        </div>

        
        <TextWithImage propData={this.state.section1} />
        <TextWithImage propData={this.state.section2} />
        <TextWithImage propData={this.state.section3} />
        <TextWithImage propData={this.state.section4} />
        <TextWithImage propData={this.state.section5} />
        <TextWithImage propData={this.state.section6} />
        <TextWithImage propData={this.state.section7} />
        <TextWithImage propData={this.state.section8} />
        <TextWithImage propData={this.state.section9} />

      </div>
    );
  }
}

export default MethodologyComponent;
