import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./../../../public/scss/snippets/ValidityContainers.scss";

import {
//   Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

class ValidityContainers extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        data: props.data
    };
  }

  componentDidMount(){
      console.log(this.state.data);
  }

  componentWillReceiveProps(thisProps, nextProps){
      
  }

  render() {

    return (
      <div className="ValidityContainers">
            <Container fluid>

                {
                    this.state.data.map((validity, key) =>
                        <Row id={'validity'+ (key+1)} style={{backgroundImage: 'url(' + validity.bgImage + ')'}}>
                            <Col style={{background: validity.bgColor}} className="box" key={key} md={{ size: 4, offset: validity.position == 'ltr' ? 8 : '' }}>
                                <h2 dangerouslySetInnerHTML={{__html: validity.heading}}></h2>
                                <p dangerouslySetInnerHTML={{__html: validity.text}}></p>
                                <a style={{color: '#fff'}} target="_new" href={validity.button.link}>
                                    {validity.button.label}
                                </a>
                            </Col>
                        </Row>
                    )
                }

            </Container>
      </div>
    );
  }
}

export default ValidityContainers;
