import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { getAllSchools } from './../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    Table
  } from "reactstrap";

class TempSchoolsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allSchools : []
        };

    }

  componentDidMount() {

    window.scrollTo(0, 0); // scroll to top after rendering the component

    // calls all skills data
    this.props.getAllSchools();

  }

  componentWillReceiveProps(nextProps, thisProps) {

    if(this.props.schoolsResponse != nextProps.schoolsResponse) {
        this.setState({
            allSchools: nextProps.schoolsResponse
        }, () => {
            console.log(this.state.allSchools)
        })
    }

  }
    
  render() {

    return (
      <div className="TempSchoolsComponent">

          <Container style={{textAlign: 'left'}}>
              <Row>
                  <Col>
                    
                    <h3>All schools list</h3>

                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>Institute Name</th>
                                <th>Country</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.allSchools.map((school, key) =>
                                    <tr key={key}>
                                        <td>{school.institute}</td>
                                        <td>{school.country}</td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </Table>
                  </Col>
              </Row>
          </Container>
      </div>
    );
    
  }
}

TempSchoolsComponent.propTypes = {
    getAllSchools: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  schoolsResponse: state.webRed.schoolsResponse
})

export default connect(mapStatesToProps, { getAllSchools })(TempSchoolsComponent);