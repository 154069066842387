import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
import "./../../../public/scss/pagcorDashboard_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { getAllSkills, getAllCitiesData, getDemographicAvg, getGenderClassificationAvg, getAgeClassificationAvg, getPositionClassificationAvg, getMediumsClassificationAvg, getTransportClassificationAvg } from './../../../actions/webRedActions';
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';

import BranchesListSidebar from './BranchesListSidebar';
import DistributionAndComparisonTopCitiesComponent from './DistributionAndComparisonTopCitiesComponent';
import DMEProfileComponent from './DMEProfileComponent';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Tooltip
  } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faTimes, faBars, faSchool, faInfo, faComment, faCalendarCheck, faCalendar } from '@fortawesome/free-solid-svg-icons';

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { ResponsiveHeatMap } from '@nivo/heatmap'

import branchicon from './../../../public/images/branches/branchicon2.png';
import devisionicon from './../../../public/images/branches/divisionIcon.png';
import sectionicon from './../../../public/images/branches/sectionIcon.png';

import branchData1 from './branchData1';
import branchData2 from './branchData2';
import archetypesData from './architypesData';


// import $ from 'jquery'; 

class PagcorStatsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

            branchIndex : 0,
            competencyIndex : 0,
            divisionIndex : 0,
            sectionIndex : 0,
            branches: [
                branchData1,
                branchData2
            ],
            allBranches : {
                mainCompetencies : [{
                    name : "CORE",
                    totalAverage : 84.3
                },{
                    name : "ORGANIZATIONAL",
                    totalAverage : 90
                },{
                    name : "LEADERSHIP",
                    totalAverage : 95.3
                },{
                    name : "TECHNICAL",
                    totalAverage : 93
                }]
            },
            archetypesList : archetypesData,
            archetypesDataChart : [],
            compComparisonData : {
                keys : [],
                data : []
            },
            compComparisonDataDivisionWise : {
                keys : [],
                data : []
            },
            compComparisonDataDivisionWiseTempDivisionArray : [], // temporary random scores array for persistency while switching in different divisions to show in sections comparison chart
            compComparisonDataSectionWise : {
                keys : [],
                data : []
            },
            divisionSectionData : {
                "name": "loading",
                "color": "hsl(241, 70%, 50%)",
                "sample": 1
            },
            respondentsCatogorizeDMEData : '',
            competencyFrameworkData : '',
            branchesListToggle : false,
            archetypesTreeMapData : '',
            tooltipOpen : [],



            selectedSkill : this.props.match.params.skillId,
            selectedSkillId : 0,
            
            situations : [''],
            situationsAvg : [],
            situationsAvgSpecificCity : [],
            userTypeDataAllRegions: '',
            userTypeDataSpecificRegion: '',
            genderDataAllRegions: '',
            genderDataSpecificRegion: '',
            ageDataAllRegions: '',
            ageDataSpecificRegion: '',
            positionsDataAllRegions: '',
            positionsDataSpecificRegion: '',
            mediumsDataAllRegions: '',
            mediumsDataSpecificRegion: '',
            transportDataAllRegions: '',
            transportDataSpecificRegion: '',
            userTypeScoresData : {
              keys : [],
              data : []
            },
            skillUpdateLoader : false,
            GenderUpdateLoader : false,
            userTypeScoresDataPie1 : [[],[]],
            userTypeScoresDataPie1Loc : 0,
            genderScoresData : [],
            genderScoresDataLoc : 0,
            ageScoreDataLoc : 0,
            userTypeAgeScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            positionScoreDataLoc : 0,
            userTypePositionsScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            mediumScoreDataLoc : 0,
            userTypeMediumsScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            transportScoreDataLoc : 0,
            userTypeTransportsScoresData : [{
              keys : [],
              data : []
            },{
              keys : [],
              data : []
            }],
            swarmDataAllRegions : [],
            isCitiesLoading : false,
            allCities : [{
              cityid : 0,
              cityname: "Manila"
            }],
            cityUpdateLoader : false,
            situationsAvgDataLoc : 0,
            citySearchText : '',
            selectedMayer : "Isko Moreno",
            // showModalInfo : false,
            // showModalChat : false,
            integratedFormId : "",
            integratedFormToggle : false,
            formSource : "",
            
        };

      // this.toggleModal = this.toggleModal.bind(this);

    }

  componentDidMount() {

    window.scrollTo(0, 0); // scroll to top after rendering the component
    
    this.averageWithAllBranchesMapping();

    this.averageWithBranchesMappingForDivisions();
    
    this.averageWithBranchesMappingForSections();
    
    // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);

    this.devisionSectionDesignationMapping();

    this.competencyFrameworkDataMapping(this.state.branchIndex);

    this.archetypesTreeMapDataMapping();

    this.archetypesDataMapping(this.state.branchIndex);

  }

  devisionSectionDesignationMapping = () => {

    const { branches, branchIndex, competencyIndex, allBranches } = this.state;
    
    // mapping of divisions, sections & designations bubble chart data starts here
    var divisionsArray = [];
    branches[branchIndex].divisions.map((division, key) => { //loop for extracting data for divisions

        var sectionsArray = [];
        division.sections && division.sections.length && division.sections.map((section, key) => {

            var designationsArray = [];
            section.designations && section.designations.length && section.designations.map((designation, key) => {
                designationsArray.push({
                    "name": designation.name,
                    "sample": designation.count
                })
            })

            sectionsArray.push({
                "name": section.name,
                "children": designationsArray
            })
        })

        divisionsArray.push({
            "name": division.name,
            "children": sectionsArray
        });

    })

    setTimeout(() => {

      this.setState({
        divisionSectionData : {
          "name": branches[branchIndex].name,
          "color": "hsl(241, 70%, 50%)",
          "children": divisionsArray
        }
      },
      () => {
        console.log(this.state.divisionSectionData);
      })

    }, 500);
    
    // mapping of divisions, sections & designations bubble chart data ends here
  }

  averageWithAllBranchesMapping = () => {

    // mapping data for comparison all branches chart
    
    const { branches, branchIndex, competencyIndex, allBranches, divisionIndex, sectionIndex } = this.state;
    
    var dataArray = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {
        dataArray.push({
            "type" : comp.name,
            [ branches[branchIndex].name ] : Math.round(comp.totalAverage),
            "All Branches" : Math.round(allBranches.mainCompetencies[key].totalAverage)
        });
    })
    
    this.setState({
        compComparisonData : {
            keys : [ "All Branches", branches[branchIndex].name ],
            data : dataArray
        }
    }, () => {
        console.log(this.state.compComparisonData);
    })
    
  }

  averageWithBranchesMappingForDivisions = () => {

    // mapping data for comparison all branches chart
    
    const { branches, branchIndex, allBranches, divisionIndex } = this.state;

    var dataArrayDivision = [];
    var tempArrayRandomVal = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {
      
      //temperory work done starts here
      var randomValue = this.getRandomAvgScores(75, 95);
      tempArrayRandomVal.push(randomValue);
      //temperory work done stops here

      dataArrayDivision.push({
            "type" : comp.name,
            [ branches[branchIndex].divisions[divisionIndex].name ] : randomValue, // temporary random average score
            [ branches[branchIndex].name ] : Math.round(comp.totalAverage),
            "All Branches" : Math.round(allBranches.mainCompetencies[key].totalAverage)
        });
    })
    
    this.setState({
      compComparisonDataDivisionWise : {
            keys : [ "All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name ],
            data : dataArrayDivision
        }
    }, () => {
        console.log(this.state.compComparisonDataDivisionWise);
        
        this.setState({
          compComparisonDataDivisionWiseTempDivisionArray : tempArrayRandomVal
        }, () => {
          this.averageWithBranchesMappingForSections(); // reflects the sections chart
        })
    })

    
  }

  averageWithBranchesMappingForSections = () => {
    
    const { branches, branchIndex, allBranches, divisionIndex, sectionIndex } = this.state;

    var dataArraySection = [];
    branches[branchIndex].mainCompetencies.map((comp, key) => {
      dataArraySection.push({
            "type" : comp.name,
            [ branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name ] : this.getRandomAvgScores(75, 95), // temporary random average score,
            [ branches[branchIndex].divisions[divisionIndex].name ] : this.state.compComparisonDataDivisionWiseTempDivisionArray[key], // temporary random average score
            [ branches[branchIndex].name ] : Math.round(comp.totalAverage),
            "All Branches" : Math.round(allBranches.mainCompetencies[key].totalAverage)
        });
    })
    
    this.setState({
      compComparisonDataSectionWise : {
            keys : [ "All Branches", branches[branchIndex].name, branches[branchIndex].divisions[divisionIndex].name, branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name ],
            data : dataArraySection
        }
    }, () => {
        console.log(this.state.compComparisonDataSectionWise);
    })
    
  }


  getRandomAvgScores = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
  }

  getRandomArchetypeValue = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
  }

  // DMEDataMapping = (branchIndex, competencyIndex) => {

  //   console.log(branchIndex, competencyIndex);
      
  //   const { branches } = this.state;
  //   // mapping of DME pie charts data starts here
  //   var tempArrayData = [];
  //   // var tempArrayKeys = [];
  //   // var d_array = [];
  //   // var d_totalNumber = 0;

  //   // var m_array = [];
  //   // var m_totalNumber = 0;
    
  //   // var e_array = [];
  //   // var e_totalNumber = 0;

  //   branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {
  //       tempArrayData.push({
  //         "competency" : comp.name,
  //         "Deficient" : comp.d_total,
  //         "Matching" : comp.m_total,
  //         "Exceeding" : comp.e_total
  //       })
  //       // d_array.push({
  //       //     "id" : comp.name,
  //       //     "label" : comp.name,
  //       //     "value" : comp.d_total ? comp.d_total : 1
  //       // });
  //       // d_totalNumber += comp.d_total ? comp.d_total : 1;
        
  //       // m_array.push({
  //       //     "id" : comp.name,
  //       //     "label" : comp.name,
  //       //     "value" : comp.m_total ? comp.m_total : 1
  //       // });
  //       // m_totalNumber += comp.m_total ? comp.m_total : 1;
        
  //       // e_array.push({
  //       //     "id" : comp.name,
  //       //     "label" : comp.name,
  //       //     "value" : comp.e_total ? comp.e_total : 1
  //       // });
  //       // e_totalNumber += comp.e_total ? comp.e_total : 1;
  //   })

  //   setTimeout(() => {

  //     this.setState({
  //         // respondentsCatogorizeDMEData : [{
  //         //         name : "deificient",
  //         //         heading : "<span>D</span>eficient",
  //         //         para : "Participants whose average score lies under required competencies score",
  //         //         data : d_array,
  //         //         total : d_totalNumber,
  //         //     },{
  //         //         name : "matching",
  //         //         heading : "<span>M</span>atching",
  //         //         para : "Participants whose average score is matching the required competencies score",
  //         //         data : m_array,
  //         //         total : m_totalNumber
  //         //     },{
  //         //         name : "exceeding",
  //         //         heading : "<span>E</span>xceeding",
  //         //         para : "Participants whose average score is exceeding the required competencies score",
  //         //         data : e_array,
  //         //         total : e_totalNumber
  //         //     }]
  //         respondentsCatogorizeDMEData : {
  //           data : tempArrayData,
  //           keys : ['Deficient', 'Matching', 'Exceeding'],
  //           indexBy : 'competency'
  //         }
  //     },
  //     () => {
  //       console.log(this.state.respondentsCatogorizeDMEData);
  //     })

  //   }, 500);
    
  //   // mapping of DME pie charts data ends here

  // }


  archetypesDataMapping = (this_branchIndex) => {
    
    const { branches, branchIndex, archetypesList } = this.state;

    console.log(archetypesList)

    var dataArray = [];
    var keysArray = [];
    branches[branchIndex].divisions.map((division, key) => {
        dataArray.push({
            "division" : division.name,
            [ archetypesList[0].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[1].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[2].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[3].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[4].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[5].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[6].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[7].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[8].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[9].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[10].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[11].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[12].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[13].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[14].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[15].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[16].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[17].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[18].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[19].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[20].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[21].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[22].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[23].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[24].name ] : this.getRandomArchetypeValue(10, 25),
            [ archetypesList[25].name ] : this.getRandomArchetypeValue(1, 10),
            [ archetypesList[26].name ] : this.getRandomArchetypeValue(1, 10)
        });
    })

    archetypesList.map(archetype => {
      keysArray.push(archetype.name);
    })

    
    this.setState({
        archetypesDataChart : {
            keys : keysArray,
            data : dataArray,
            indexBy : "division"
        }
    }, () => {
        console.log(this.state.archetypesDataChart);
    })
    
  }

  competencyFrameworkDataMapping = (branchIndex) => {
    
    console.log('branchIndex', branchIndex);
      
    const { branches } = this.state;

    // mapping of competency framework treemap chart data starts here
    var rootName = branches[branchIndex].name;

    var mainCompTemp = [];

    branches[branchIndex].mainCompetencies.map((mainComp, key) => {
      
      // var subCompTemp = [];
      // var namePrefix = '';
      // if(key == 0)
      //   namePrefix = "C";
      // else if(key == 1)
      //   namePrefix = "O";
      // else if(key == 2)
      //   namePrefix = "L";
      // else
      //   namePrefix = "T";

      // mainComp.subCompetencies.map((subComp, key) => {
      //   subCompTemp.push({
      //     "name" : namePrefix + (key+1),
      //     "fullname" : subComp.name,
      //     "totalSample" : parseInt(subComp.d_total + subComp.m_total + subComp.e_total),
      //   })
      // })
      var totalSample = 0;
      mainComp.subCompetencies.map(subComp => {
        totalSample += parseInt(subComp.d_total + subComp.m_total + subComp.e_total);
      })
      
      mainCompTemp.push({
        "name" : mainComp.name,
        "fullname" : mainComp.name + " - Total responses",
        "averageScore" : Math.round(mainComp.totalAverage),
        "totalSample" : totalSample,
      })

    })

    setTimeout(() => {

      this.setState({
        competencyFrameworkData : {
            // "root" : {
              "name" : rootName,
              "fullname" : rootName,
              "children" : mainCompTemp
            // }
          }
      },
      () => {
        console.log(this.state.competencyFrameworkData);
      })

    }, 500);
    
    // mapping of competency framework treemap chart data ends here
  }
  

  archetypesTreeMapDataMapping = () => {
      
    const { archetypesList } = this.state;

    var archetypeArray = [];

    archetypesList.map((archetype, key) => {

      archetypeArray.push({
        "name" : archetype.name,
        "fullname" : archetype.desc,
        // "averageScore" : Math.round(mainComp.totalAverage),
        "totalSample" : archetype.total,
      })

    })

    setTimeout(() => {

      this.setState({
        archetypesTreeMapData : {
            // "root" : {
              "name" : "Archetypes",
              "fullname" : "Archetypes",
              "children" : archetypeArray
            // }
          }
      },
      () => {
        console.log(this.state.archetypesTreeMapData);
      })

    }, 500);
    
  }

  updateCompetency = (competencyName, index) => {
      this.setState({
          competencyIndex : index
      }, () => {
        // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
      })
  }

  componentWillReceiveProps(nextProps, thisProps) {
    
    if(this.props.switchedBranch != nextProps.switchedBranch) {
        this.setState({
            branchIndex : nextProps.switchedBranch.selectedBranch.id
        }, () => {
            console.log('branchIndex', this.state.branchIndex);
            this.averageWithAllBranchesMapping();
            this.averageWithBranchesMappingForDivisions();
            this.averageWithBranchesMappingForSections();
            // this.DMEDataMapping(this.state.branchIndex, this.state.competencyIndex);
            this.competencyFrameworkDataMapping(this.state.branchIndex);
        })
    }

  }

  toggleBranchesBar = () => {
    this.setState({
        branchesListToggle : !this.state.branchesListToggle
    }, () => {
      // if(this.state.branchesListToggle) {
      //   $('body').css({overflow: 'hidden'})
      // }else{
      //   $('body').css({overflow: ''})
      // }
    })
  }

  toggleModalInfo = () => {
    this.setState({
      integratedFormId : "ODY5Mg==",
      integratedFormToggle : true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);

      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  toggleModalChat = () => {
    this.setState({
      integratedFormId : "ODY5Mw==",
      integratedFormToggle : true
    }, () => {

      // var randomEmail = parseInt(Math.random()*5000 + 10) + "@yopmail.com";
      // var randomPhone = parseInt(Math.random()*100000 + 200);
      
      this.setState({
        // formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId +"?nested=true&email="+ randomEmail +"&phone="+ randomPhone +"&name=DashboardTalentMapUser"
        formSource : "https://form.assessmentfund.com/"+ this.state.integratedFormId
      }, () => {
        console.log(this.state.formSource);
      })

    })
  }

  closeModalForm = () => {
    this.setState({
      integratedFormToggle : false,
      formSource : ""
    })
  }

  toggleTooltip = (key) => {
    var tempArrayTooltip = this.state.tooltipOpen;
    tempArrayTooltip[key] = !tempArrayTooltip[key];
    this.setState({
        tooltipOpen : tempArrayTooltip
    }, () => {
        // console.log(this.state.tooltipOpen);
    })
  };
  
    
  render() {
    const { branches, allBranches, branchIndex, competencyIndex, divisionIndex, sectionIndex, archetypesDataChart } = this.state;

    var subCompetenciesDataArray = []; // for sub competencies with average bar chart
    
    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

      var averageValue = comp.average;
      
      subCompetenciesDataArray.push({
        score : averageValue,
        value : comp.name
      })


    });

    var subCompetenciesDataArrayCount = []; // for sub competencies with average bar chart
    
    branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((comp, key) => {

      var total = parseInt(comp.d_total + comp.m_total + comp.e_total);
      
      subCompetenciesDataArrayCount.push({
        score : total,
        value : comp.name
      })


    });
    

    return (
      <div className="PagcorStatsComponent">
        {/* <Header /> */}

        <div>

            <Container fluid>
                <Row style={{ padding: '3vh 0' }}>
                    {/* <Col style={{padding: '5vh 0'}} md={{ size: 12 }}>
                      <h3>Students Results - Overall</h3>
                    </Col> */}


                    


                    {/* section for mayer information and city additional info - starts here */}
                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div>
                          <Row style={{padding: '25px'}}>

                            <Col md={{ size: 4 }}>
                              <div className="schoolBox topSchoolBox">
                                <Row>
                                  <Col md={{size: 12}}>
                                    <div style={{verticalAlign: 'middle', padding: '0vh 0'}}>
                                      <div className="circleBox imageBack" style={{backgroundImage: "url("+ branches[branchIndex].thumbnail +")"}}></div>
                                      <p style={{margin: 0, color: '#7a8596', position: 'relative', top: '8px'}}>Branch</p>
                                      <h3 className="cityName">{branches[branchIndex].name}</h3>
                                    </div>
                                  </Col>
                                </Row>
                                {/* <p className="titleTag">City</p> */}
                              </div>
                            </Col>

                            <Col md={{ size: 4 }}>
                              <div className="schoolBox topSchoolBox">
                                <Row>
                                  <Col md={{size: 12}}>
                                    <div style={{verticalAlign: 'middle', padding: '0vh 0'}}>
                                        <div className="circleBox imageBack">
                                            <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                            <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" />
                                        </div>
                                        <p style={{margin: 0, color: '#7a8596', position: 'relative', top: '8px'}}>People assessed</p>
                                        <h3 className="cityName">{branches[branchIndex].totalCandidates}</h3>
                                    </div>
                                  </Col>
                                </Row>
                                {/* <p className="titleTag">Mayer</p> */}
                              </div>
                            </Col>

                            <Col md={{ size: 4 }}>
                              <div className="schoolBox topSchoolBox">
                                <Row>
                                  <Col>
                                    <h3 className="cityName"></h3>
                                    <div className="oneInfo">
                                      <div className="iconBox">
                                        {/* <FontAwesomeIcon className="iconHere" icon={faMale} size="4x" />
                                        <FontAwesomeIcon className="iconHere" icon={faFemale} size="4x" /> */}
                                        <img src={devisionicon} />
                                      </div>
                                      <p style={{color: '#7a8596'}}>Divisions</p>
                                      <h3>{branches[branchIndex].totalDivisions}</h3>
                                    </div>
                                    <div className="oneInfo">
                                      <div className="iconBox">
                                        {/* <FontAwesomeIcon className="iconHere" icon={faSchool} size="4x" /> */}
                                        <img src={sectionicon} />
                                      </div>
                                      <p style={{color: '#7a8596', maxWidth: "140px"}}>Sections</p>
                                      <h3>{branches[branchIndex].totalSections}</h3>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>

                            
                                    
                          </Row>

                          

                        </div>
                      </div>
                    </Col>
                    {/* section for mayer information and city additional info - ends here */}







                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Overview of competencies in {branches[branchIndex].name} </h5>
                            </Col>
                            
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>

                              {/* <Switch
                                checked={this.state.situationsAvgDataLoc == 1 ? true : false}
                                onChange={e => this.setState({ situationsAvgDataLoc : this.state.situationsAvgDataLoc == 0 ? 1 : 0 }) }
                                uncheckedIcon={
                                  <div className="switchLabel">
                                    {this.state.selectedRegion}
                                  </div>
                                }
                                checkedIcon={
                                  <div className="switchLabel">
                                    All Cities
                                  </div>
                                }
                                className={this.state.situationsAvgDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                id="icon-switch"
                              /> */}
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row style={{padding: '25px'}}>

                            <Col md={{ size: 6 }}>
                              <div className="schoolBox">
                                <Row>
                                  <Col md={{size: 7}}>
                                    <div style={{verticalAlign: 'middle', padding: '5vh 0'}}>
                                      <div className="circleBox">
                                        <h2>{branches[branchIndex].mainCompetencies.length}</h2>
                                        <p>*Main competencies tested for<br/>the <strong>{branches[branchIndex].name}</strong></p>
                                        <div className="smallCircle">
                                          { branches[branchIndex].mainAverage }<span>%</span>
                                          <p>*Average<br />score</p>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={{size: 5}} style={{textAlign: 'left'}}>
                                    <div style={{color: '#fff', position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>

                                      {
                                        branches[branchIndex].mainCompetencies.map((m_competency, key) => {

                                          var isThisOne = false;
                                          if(key == competencyIndex){ // compare with skill id from url
                                            isThisOne = true;
                                          }

                                          return (
                                            <span style={{
                                              opacity : !isThisOne && this.state.competencyUpdateLoader ? '0.4': '1'
                                            }} key={key} className={isThisOne ? 'skillHere highlighted' : 'skillHere'} onClick={e => this.updateCompetency(m_competency.name, key)}>
                                              {
                                                isThisOne && this.state.competencyUpdateLoader ? 
                                                  <Spinner className="skillSpinner" size="sm" color="light" />
                                                : null
                                              }
                                              {m_competency.name}
                                            </span>
                                          )
                                        })
                                      }

                                    </div>
                                    
                                  </Col>
                                  {/* <Col className="bottomLabel" md={{size: 12}}>
                                      <p>* Skills recommended by the World Bank<br />for 21st century workforce</p>
                                  </Col> */}
                                </Row>
                              </div>
                            </Col>

                            <Col md={{ size: 6 }}>
                              <div className="schoolBox">
                                <Row>
                                  <Col md={{size: 6}}>
                                    <div style={{verticalAlign: 'middle', padding: '7vh 0'}}>
                                      <div className="circleBox2" style={{width: '280px', height: '280px'}}>
                                        <h2 style={{lineHeight: '190px'}}>{ branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.length }</h2>
                                        <p>*Competencies tested in
                                          <br/>
                                          <strong style={{fontSize: '18px'}}>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong>
                                        </p>
                                        {/* animated infinite bounce delay-2s */}
                                        <div className="smallCircle">
                                          { branches[branchIndex].mainCompetencies[competencyIndex].totalAverage }<span>%</span>
                                          <p>*Average<br />score</p>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={{size: 6}} style={{textAlign: 'left', paddingRight: '0', position: 'relative', zIndex: '999'}}>
                                    <div className="situationsParent">

                                      {
                                        branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.map((competency, key) => {

                                          // var isSkill = false;
                                          var dotColor = "red";
                                          var averageValue = competency.average;
                                          
                                          if(averageValue >= 80 && averageValue <=89){
                                            dotColor = "yellow";
                                          }
                                          if(averageValue >= 90 && averageValue <=100){
                                            dotColor = "#2cf32c";
                                          }

                                          return (
                                            <div key={key} className='small skillHere'>
                                              { competency.name }
                                              {
                                                averageValue != 0 ?
                                                  <span className="dot" style={{background: dotColor, color: dotColor}}>
                                                    <span className="dotText">
                                                      { competency.average }
                                                      <span style={{fontSize: '10px'}}>%</span>
                                                    </span>
                                                  </span>
                                                : null
                                              }
                                            </div>
                                          )
                                        })
                                      }  
                                    </div>
                                    
                                  </Col>
                                  <Col className="bottomLabel" md={{size: 12}}>
                                      
                                      <p>* Average percentage of required<br />competencies met in { branches[branchIndex].mainCompetencies[competencyIndex].name } competency</p>
                                      
                                      {/* {
                                        this.state.situationsAvgDataLoc == 0 ?
                                          <p className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                                        : null
                                      } */}

                                  </Col>
                                </Row>
                              </div>
                            </Col>
                                    
                          </Row>

                          <Row style={{padding: '0 15px 15px 15px'}} className="competencyFramworkChartBox">
                            <Col md={{size: 12}}>
                              <h5 style={{marginTop: '20px'}}>This map depicts the average percentage score for each of the four main competency for {branches[branchIndex].name}</h5>
                              {
                                this.state.competencyFrameworkData && this.state.competencyFrameworkData != '' ?
                                  <div style={{height: 'calc(60vh - 60px)'}}>
                                    <ResponsiveTreeMap
                                      root={this.state.competencyFrameworkData}
                                      identity="fullname"
                                      value="totalSample"
                                      tile="binary"
                                      innerPadding={10}
                                      outerPadding={10}
                                      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                      label={(e) => {return e.name + " - "+ e.averageScore +"%"}}
                                      // labelFormat=".0s"
                                      labelSkipSize={10}
                                      labelTextColor={{ theme: 'background' }}
                                      colors={["#172740", "#0d1623", "#a6cee3"]}
                                      // colors={{ scheme: 'purpleRed_green' }}
                                      borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                                      animate={true}
                                      motionStiffness={90}
                                      motionDamping={11}
                                    />
                                  </div>
                                : null
                              }
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </Col>

                    
                    

                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead" >
                          <Row>
                            <Col md={{ size : 8 }}>
                                <h5>
                                    <strong>{branches[branchIndex].name}</strong> competencies' average score comparison with all branches
                                </h5>
                            </Col>
                            <Col md={{ size : 4 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: 'auto'}}>

                        <Row style={{padding: '25px'}}>
                          <Col md={{ size: 6 }}>
                            
                            <p style={{color: '#7ba2dc', textAlign: 'left'}}>This bar chart depicts a performance analysis of percentage scores for {branches[branchIndex].name} as compared to the overall result for this assessment for each of the four main competencies.</p>

                          </Col>
                          <Col md={{ size: 12 }}>

                            <div className="chartBox" style={{background: 'transparent'}}>
                                <div style={{height: '400px', position: 'relative'}}>

                                    {/* {
                                        this.state.skillUpdateLoader ? 
                                        <div className="customSpinnerChart">
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        : null
                                    } */}
                                    <ResponsiveBar
                                        data={this.state.compComparisonData.data}
                                        keys={this.state.compComparisonData.keys}
                                        indexBy="type"
                                        margin={{ top: 60, right: 30, bottom: 60, left: 100 }}
                                        padding={0.6}
                                        innerPadding={10}
                                        label={d => `${d.value}%`}
                                        // onClick={e => this.barChartClicked(e)}
                                        groupMode="grouped"
                                        colors={{ scheme: 'paired' }}
                                        // colors={["#f706d5", "#770367" ]}
                                        // colors={["#68366f", "#3d1f42" ]}
                                        layout="vertical"
                                        enableGridY= {false}
                                        defs={[
                                            {
                                                id: 'dots',
                                                type: 'patternDots',
                                                background: 'inherit',
                                                color: '#38bcb2',
                                                size: 4,
                                                padding: 1,
                                                stagger: true
                                            },
                                            {
                                                id: 'lines',
                                                type: 'patternLines',
                                                background: 'inherit',
                                                color: '#eed312',
                                                rotation: -45,
                                                lineWidth: 6,
                                                spacing: 10
                                            }
                                        ]}
                                        fill={[
                                            {
                                                match: {
                                                    id: 'fries'
                                                },
                                                id: 'dots'
                                            },
                                            {
                                                match: {
                                                    id: 'sandwich'
                                                },
                                                id: 'lines'
                                            }
                                        ]}
                                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 50
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: -60
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                        legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'top-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: -15,
                                            translateY: -60,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 25,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    />

                                </div>
                            </div>
                        </Col>

                        <div className="seperatorHeading">
                          Competencies' average score of {branches[branchIndex].divisions[divisionIndex].name} with in {branches[branchIndex].name}

                          <select className="customDropDown" onChange={e => {this.setState({
                              sectionIndex : 0, // reset the sectionIndex
                              divisionIndex : e.target.value
                            }, () => {
                              this.averageWithBranchesMappingForDivisions();
                            })}}>
                            {
                              branches[branchIndex].divisions.map((division, key) => 
                                <option key={key} value={key}>{division.name}</option>
                              )
                            }
                          </select>

                        </div>

                        <Col md={{ size: 8, offset: 1 }}>
                            
                          <div style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                            This bar chart highlights the performance for the Divisions in {branches[branchIndex].name} for each of the four main competencies and has three points of relevance and comparison:
                            <ol style={{marginTop: '20px'}}>
                              <li>Average percentage score of each of the {branches[branchIndex].totalDivisions} divisions for {branches[branchIndex].name}</li>
                              <li>Average percentage score of each Division in {branches[branchIndex].name} as compared to the {branches[branchIndex].name}'s overall scores</li>
                              <li>Average percentage score of each Division in {branches[branchIndex].name} as compared to overall result for this assessment</li>
                            </ol>
                          </div>

                        </Col>

                        <Col md={{ size: 9 }} style={{paddingTop: '25px'}}>
                          <div className="chartBox" style={{background: 'transparent'}}>
                            <div style={{height: '400px', position: 'relative'}}>

                                <ResponsiveBar
                                  data={this.state.compComparisonDataDivisionWise.data}
                                  keys={this.state.compComparisonDataDivisionWise.keys}
                                  indexBy="type"
                                  margin={{ top: 100, right: 0, bottom: 60, left: 100 }}
                                  padding={0.3}
                                  innerPadding={10}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  colors={{ scheme: 'paired' }}
                                  // colors={["#f706d5", "#770367" ]}
                                  // colors={["#68366f", "#3d1f42" ]}
                                  layout="vertical"
                                  enableGridY= {false}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: 50
                                  }}
                                  axisLeft={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  legends={[
                                  {
                                      dataFrom: 'keys',
                                      anchor: 'top-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: -100,
                                      translateY: -100,
                                      itemsSpacing: 2,
                                      itemWidth: 150,
                                      itemHeight: 25,
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 0.85,
                                      symbolSize: 20,
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />

                            </div>
                        </div>
                    </Col>

                    <Col md={{ size: 3 }}>
                      <div className="schoolBox topSchoolBox bottomBox">
                        <Row>
                          <Col>
                          
                            <div className="oneInfo">
                              <div className="iconBox">
                                
                                <Row>
                                  <Col>
                                    <h3>{branches[branchIndex].totalDivisions}</h3>
                                    <p style={{color: '#7a8596'}}>Divisions in<br/>{branches[branchIndex].name}</p>
                                  </Col>
                                  <Col>
                                    <img src={devisionicon} />
                                  </Col>
                                </Row>

                              </div>
                            </div>
                            
                            <div className="oneInfo">
                              <div className="iconBox">

                                <Row>
                                  <Col>
                                    <h3>{branches[branchIndex].divisions[divisionIndex].sections.length}</h3>
                                    <p style={{color: '#7a8596'}}>Sections in<br/>{branches[branchIndex].divisions[divisionIndex].name}</p>
                                  </Col>
                                  <Col>
                                    <img src={sectionicon} />
                                  </Col>
                                </Row>
                                
                              </div>
                            </div>

                          </Col>
                        </Row>
                      </div>
                    </Col>


                        <div className="seperatorHeading">
                          Competencies' average score of {branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name} with in {branches[branchIndex].divisions[divisionIndex].name}

                          <select className="customDropDown" onChange={e => {this.setState({
                              sectionIndex : e.target.value
                            }, () => {
                              this.averageWithBranchesMappingForSections();
                            })}}>
                            {
                              branches[branchIndex].divisions[divisionIndex].sections.map((section, key) => 
                                <option key={key} value={key}>{section.name}</option>
                              )
                            }
                          </select>

                        </div>

                        <Col md={{ size: 8, offset: 1 }}>
                            
                          <div style={{color: '#7ba2dc', textAlign: 'left', marginTop: '30px'}}>
                            This bar chart is a further breakdown of each Division and highlights the performance for the Sections in each Division in {branches[branchIndex].name} for each of the four main competencies and has four points of relevance and comparison:
                            <ol style={{marginTop: '20px'}}>
                              <li>Average percentage score of each of the Section in the selected Division for {branches[branchIndex].name}</li>
                              <li>Average percentage score of each Section as compared to the overall scores for the selected Division in {branches[branchIndex].name} as compared to the {branches[branchIndex].name}'s overall scores</li>
                              <li>Average percentage score of each Section for the selected Division as compared to the {branches[branchIndex].name}'s overall scores</li>
                              <li>Average percentage score of each Section for the selected Division in {branches[branchIndex].name} as compared to overall result for this assessment </li>
                            </ol>
                          </div>

                        </Col>

                        <Col md={{ size: 9 }} style={{paddingTop: '25px'}}>
                          <div className="chartBox" style={{background: 'transparent'}}>
                            <div style={{height: '500px', position: 'relative'}}>

                                <ResponsiveBar
                                  data={this.state.compComparisonDataSectionWise.data}
                                  keys={this.state.compComparisonDataSectionWise.keys}
                                  indexBy="type"
                                  margin={{ top: 140, right: 0, bottom: 60, left: 100 }}
                                  padding={0.25}
                                  innerPadding={10}
                                  label={d => `${d.value}%`}
                                  // onClick={e => this.barChartClicked(e)}
                                  groupMode="grouped"
                                  colors={{ scheme: 'paired' }}
                                  // colors={["#f706d5", "#770367" ]}
                                  // colors={["#68366f", "#3d1f42" ]}
                                  layout="vertical"
                                  enableGridY= {false}
                                  defs={[
                                      {
                                          id: 'dots',
                                          type: 'patternDots',
                                          background: 'inherit',
                                          color: '#38bcb2',
                                          size: 4,
                                          padding: 1,
                                          stagger: true
                                      },
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'inherit',
                                          color: '#eed312',
                                          rotation: -45,
                                          lineWidth: 6,
                                          spacing: 10
                                      }
                                  ]}
                                  fill={[
                                      {
                                          match: {
                                              id: 'fries'
                                          },
                                          id: 'dots'
                                      },
                                      {
                                          match: {
                                              id: 'sandwich'
                                          },
                                          id: 'lines'
                                      }
                                  ]}
                                  borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: 50
                                  }}
                                  axisLeft={{
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: '',
                                      legendPosition: 'middle',
                                      legendOffset: -60
                                  }}
                                  labelSkipWidth={12}
                                  labelSkipHeight={12}
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                  legends={[
                                  {
                                      dataFrom: 'keys',
                                      anchor: 'top-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: -100,
                                      translateY: -135,
                                      itemsSpacing: 2,
                                      itemWidth: 150,
                                      itemHeight: 25,
                                      itemDirection: 'left-to-right',
                                      itemOpacity: 0.85,
                                      symbolSize: 20,
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />

                            </div>
                        </div>
                    </Col>

                    <Col md={{ size: 3 }}>
                      <div className="schoolBox topSchoolBox bottomBox">
                        <Row>
                          <Col>
                            <div className="oneInfo">
                              <div className="iconBox">

                                <Row>
                                  <Col>
                                    <h3>{branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].count}</h3>
                                    <p style={{color: '#7a8596'}}>People<br/>in {branches[branchIndex].divisions[divisionIndex].sections[sectionIndex].name}</p>
                                  </Col>
                                  <Col>
                                    <div style={{margin: '30px 0px 10px 0'}}>
                                      <FontAwesomeIcon className="iconHere" icon={faMale} size="2x" />
                                      <FontAwesomeIcon className="iconHere" icon={faFemale} size="2x" />
                                    </div>
                                  </Col>
                                </Row>
                                
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col md={{ size: 5 }}>
                      <div className="chartBox" style={{border: '1px #0544a2 solid', borderRadius: '0'}}>
                        <div className="titleHead" style={{background: '#0b2548', borderBottom: '1px #0544a2 solid'}}>
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>
                                <strong>{branches[branchIndex].name}</strong> competencies' average score comparison with all branches
                              </h5>
                            </Col>
                            {/* <Col md={{ size : 4 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col> */}
                            
                          </Row>
                        </div>
                        
                        <div style={{height: '660px', background: '#05162d', position: 'relative'}}>

                          <p style={{color: '#7ba2dc', textAlign: 'left', padding: '15px 20px 0px 20px'}}>
                            The following graph depicts the comparison of average percentage scores for {branches[branchIndex].name} with other branches for each of the four main competencies.
                          </p>
                          
                          {/* {
                            this.state.skillUpdateLoader ?
                              <div className="customSpinnerChart">
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          } */}
                              <ResponsiveRadar
                                data={this.state.compComparisonData.data}
                                keys={this.state.compComparisonData.keys}
                                indexBy="type"
                                maxValue="auto"
                                margin={{ top: 50, right: 160, bottom: 70, left: 110 }}
                                curve="linearClosed"
                                borderWidth={2}
                                borderColor={{ from: 'color', modifiers: [] }}
                                gridLevels={1}
                                gridShape="circular"
                                gridLabelOffset={25}
                                enableDots={true}
                                dotSize={12}
                                dotColor={{ from: 'color', modifiers: [] }}
                                dotBorderWidth={0}
                                dotBorderColor={{ from: 'color' }}
                                enableDotLabel={false}
                                dotLabel="value"
                                dotLabelYOffset={-12}
                                colors={["#93519c", "#e84f9a" ]}
                                fillOpacity={0}
                                blendMode="normal"
                                animate={true}
                                motionStiffness={200}
                                motionDamping={7}
                                isInteractive={true}
                                legends={[
                                  {
                                      anchor: 'top-left',
                                      direction: 'column',
                                      translateX: -90,
                                      translateY: 0,
                                      itemWidth: 80,
                                      itemHeight: 25,
                                      itemTextColor: '#999',
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: '#000'
                                              }
                                          }
                                      ]
                                  }
                              ]}
                            />

                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 7 }}>
                      <div className="chartBox"  style={{border: '1px #0544a2 solid', borderRadius: '0'}}>
                        <div className="titleHead" style={{background: '#0b2548', borderBottom: '1px #0544a2 solid'}}>
                          <Row>
                            <Col md={{ size : 12 }}>
                              <h5>Candidates' distribution in divisions, sections & designations in <strong>{branches[branchIndex].name}</strong></h5>
                            </Col>
                            {/* <Col style={{textAlign: 'right'}} md={{ size : 5 }}>
                                
                                <div className="quickIcons">
                                  <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                  <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                                </div>
                                
                                <Switch
                                  checked={this.state.userTypeScoresDataPie1Loc == 1 ? true : false}
                                  onChange={e => this.setState({ userTypeScoresDataPie1Loc : this.state.userTypeScoresDataPie1Loc == 0 ? 1 : 0 }) }
                                  uncheckedIcon={
                                    <div className="switchLabel">
                                      {this.state.selectedRegion}
                                    </div>
                                  }
                                  checkedIcon={
                                    <div className="switchLabel">
                                      All Cities
                                    </div>
                                  }
                                  className={this.state.userTypeScoresDataPie1Loc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                                  id="icon-switch"
                                />
                            </Col> */}
                          </Row>
                        </div>
                        <div>
                          {/* {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                              </div>
                            : null
                          } */}

                          <Row>
                            {/* <Col md={{ size : 12 }}>
                              <h2>asdadasd</h2>
                            </Col> */}
                            <Col md={{ size : 12 }} style={{height: '660px', background: '#05162d', position: 'relative', overflow: 'hidden'}}>
                              
                              <p style={{color: '#7ba2dc', textAlign: 'left', padding: '15px 20px 0px 20px'}}>
                                The following representation provides in-depth view of the volume of people assessed in {branches[branchIndex].name}. This is further broken down to give a complete overview of volume of people assessed in each Division, Section and Position for {branches[branchIndex].name}.
                              </p>
                              
                              <ResponsiveBubble
                                  root={this.state.divisionSectionData}
                                  margin={{ top: 30, right: 20, bottom: 130, left: 20 }}
                                  identity="name"
                                  value="sample"
                                  colors={{ scheme: 'paired' }}
                                  // colors={["#f1e15b", "#1f78b4"]}
                                  padding={6}
                                  label="value"
                                  labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
                                  borderWidth={2}
                                  borderColor={{ from: 'color' }}
                                  defs={[
                                      {
                                          id: 'lines',
                                          type: 'patternLines',
                                          background: 'none',
                                          color: 'inherit',
                                          rotation: -45,
                                          lineWidth: 5,
                                          spacing: 8
                                      }
                                  ]}
                                  // fill={[ { match: { depth: 1 }, id: 'lines' } ]}
                                  animate={true}
                                  motionStiffness={90}
                                  motionDamping={12}
                              />
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </Col>
                          </Row>


                        </div>
                      </div>
                    </Col>                    
                    

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>

                              <h5>Avg. score for { branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.length } competencies assessed within <strong>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong></h5>
                            
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              {/* <div className="quickIcons">
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div> */}

                              <select className="customDropDown" style={{top: 0}} onChange={e => {
                                this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                              }}>
                              {
                                  branches[branchIndex].mainCompetencies.map((comp, key) => 
                                  <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.name}</option>
                                  )
                              }
                              </select>
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: subCompetenciesDataArray.length > 15 ? '840px' : '440px', position: 'relative'}}>
                          {/* {
                            this.state.skillUpdateLoader ? 
                              <div className="customSpinnerChart">
                                <Spinner style={{width: '3rem', height: '3rem'}} size="lg" color="light" />
                              </div>
                            : null
                          } */}
                          
                          <ResponsiveBar
                              data={subCompetenciesDataArray}
                              keys={[ 'score' ]}
                              indexBy="value"
                              margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                              padding={0.2}
                              innerPadding={5}
                              label={d => `${d.value}%`}
                              // onClick={e => this.barChartClicked(e)}
                              groupMode="stacked"
                              // colors={{ scheme: 'paired' }}
                              colors={["#d63333", "#f706d5" ]}
                              layout="horizontal"
                              enableGridY= {false}
                              defs={[
                                  {
                                      id: 'dots',
                                      type: 'patternDots',
                                      background: 'inherit',
                                      color: '#38bcb2',
                                      size: 4,
                                      padding: 1,
                                      stagger: true
                                  },
                                  {
                                      id: 'lines',
                                      type: 'patternLines',
                                      background: 'inherit',
                                      color: '#eed312',
                                      rotation: -45,
                                      lineWidth: 6,
                                      spacing: 10
                                  }
                              ]}
                              fill={[
                                  {
                                      match: {
                                          id: 'fries'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'sandwich'
                                      },
                                      id: 'lines'
                                  }
                              ]}
                              borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              axisTop={null}
                              axisRight={null}
                              axisBottom={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: 50
                              }}
                              axisLeft={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: -60
                              }}
                              labelSkipWidth={12}
                              labelSkipHeight={12}
                              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              // legends={[
                              //     {
                              //         dataFrom: 'keys',
                              //         anchor: 'top-right',
                              //         direction: 'column',
                              //         justify: false,
                              //         translateX: -15,
                              //         translateY: -40,
                              //         itemsSpacing: 2,
                              //         itemWidth: 100,
                              //         itemHeight: 25,
                              //         itemDirection: 'left-to-right',
                              //         itemOpacity: 0.85,
                              //         symbolSize: 20,
                              //         effects: [
                              //             {
                              //                 on: 'hover',
                              //                 style: {
                              //                     itemOpacity: 1
                              //                 }
                              //             }
                              //         ]
                              //     }
                              // ]}
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                          />

                          {/* {
                            this.state.situationsAvgDataLoc == 0 ?
                              <p style={{fontSize: '12px', color: '#435f84', left: '5px'}} className="contactForCity" onClick={e => this.toggleModalInfo()}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
                            : null
                          } */}
                          
                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">

                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>

                              <h5>People assessed for { branches[branchIndex].mainCompetencies[competencyIndex].subCompetencies.length } sub-competencies within <strong>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong></h5>

                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 4 }}>

                              <select className="customDropDown" style={{top: 0}} onChange={e => {
                                this.updateCompetency(branches[branchIndex].mainCompetencies[e.target.value].name, e.target.value);
                              }}>
                              {
                                  branches[branchIndex].mainCompetencies.map((comp, key) => 
                                  <option key={key} value={key} selected={key == this.state.competencyIndex}>{comp.name}</option>
                                  )
                              }
                              </select>
                            </Col>
                          </Row>
                        </div>

                        <div style={{height: subCompetenciesDataArrayCount.length > 15 ? '840px' : '440px', position: 'relative'}}>
                          
                          <ResponsiveBar
                              data={subCompetenciesDataArrayCount}
                              keys={[ 'score' ]}
                              indexBy="value"
                              margin={{ top: 30, right: 40, bottom: 60, left: 400 }}
                              padding={0.2}
                              innerPadding={5}
                              label={d => `${d.value}`}
                              // onClick={e => this.barChartClicked(e)}
                              groupMode="stacked"
                              // colors={{ scheme: 'paired' }}
                              colors={["#1f78b4", "#f706d5" ]}
                              layout="horizontal"
                              enableGridY= {false}
                              defs={[
                                  {
                                      id: 'dots',
                                      type: 'patternDots',
                                      background: 'inherit',
                                      color: '#38bcb2',
                                      size: 4,
                                      padding: 1,
                                      stagger: true
                                  },
                                  {
                                      id: 'lines',
                                      type: 'patternLines',
                                      background: 'inherit',
                                      color: '#eed312',
                                      rotation: -45,
                                      lineWidth: 6,
                                      spacing: 10
                                  }
                              ]}
                              fill={[
                                  {
                                      match: {
                                          id: 'fries'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'sandwich'
                                      },
                                      id: 'lines'
                                  }
                              ]}
                              borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              axisTop={null}
                              axisRight={null}
                              axisBottom={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: 50
                              }}
                              axisLeft={{
                                  tickSize: 5,
                                  tickPadding: 5,
                                  tickRotation: 0,
                                  legend: '',
                                  legendPosition: 'middle',
                                  legendOffset: -60
                              }}
                              labelSkipWidth={12}
                              labelSkipHeight={12}
                              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                          />
                          
                        </div>
                      </div>
                    </Col>


                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              {/* <h5>Candidates' distribution in <strong>{ branches[branchIndex].mainCompetencies[competencyIndex].name }</strong> competency based on Deficient, Matching and Exceeding (DME)</h5> */}
                              <h5><strong>{ branches[branchIndex].name }</strong> average score comparison based on Deficient, Matching and Exceeding (DME)</h5>
                            </Col>
                            <Col md={{ size: 4 }}>
                              <div className="quickIcons" style={{right: '15px'}}>
                                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.toggleModalInfo()} />
                                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x" onClick={e => this.toggleModalChat()} />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        {/* Here we'll call component for DME profile */}
                        {
                          // this.state.respondentsCatogorizeDMEData && this.state.respondentsCatogorizeDMEData != '' ?
                            <DMEProfileComponent
                              // data={this.state.respondentsCatogorizeDMEData}
                              // c_competency={ branches[branchIndex].mainCompetencies[competencyIndex].name }
                              // c_branch={branches[branchIndex].name}
                              allData={{
                                branches : branches,
                                allBranches : allBranches,
                                branchIndex : branchIndex,
                                divisionIndex : divisionIndex,
                                sectionIndex : sectionIndex,
                                competencyIndex : competencyIndex
                              }}
                            />
                          // : null
                        }

                      </div>
                    </Col>


                    {/* <Col md={{ size: 12 }}>

                      {
                        this.state.situations && this.state.situations.length ?
                          <DistributionAndComparisonTopCitiesComponent selectedRegion={this.state.selectedRegion} selectedSkill={this.state.selectedSkill} selectedSkillId={this.state.selectedSkillId} situations={this.state.situations} situationsAvg={this.state.situationsAvg} situationsAvgSpecificCity={this.state.situationsAvgSpecificCity} />
                        : null
                      }

                    </Col> */}



                    <Col md={{ size: 12 }} style={{display: 'none'}}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 7 }}>
                              <h5>Candidates of {branches[branchIndex].name} were evaluated on each of the following Archetypes</h5>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={{ size : 5 }}>

                            </Col>
                          </Row>
                        </div>
                        <div>
                          <Row style={{padding: '30px 50px'}}>
                            
                            <Col md={{size: 3}}>
                              <Row>
                              {
                                this.state.archetypesList.map((archetype, key) => 
                                  <Col key={key} md={{ size: 6 }} style={{padding: 0}}>
                                    <div className="archetypeNameBox" id={"tooltip_" + key}>
                                      {
                                        archetype.name
                                      }
                                    </div>
                                    <Tooltip placement="right" isOpen={this.state.tooltipOpen[key]} target={"tooltip_" + key} toggle={e => this.toggleTooltip(key)} innerClassName="customTooltip">
                                      <p style={{textAlign: 'left', padding: '10px',  background:'#d63333', fontSize: '1rem'}}>
                                        {
                                          archetype.desc
                                        }
                                      </p>
                                    </Tooltip>
                                  </Col>
                                )
                              }
                              </Row>
                            </Col>
                            
                            <Col md={{size: 9}}>
                              <Row style={{padding: '0'}} className="competencyFramworkChartBox smallFont">
                                <Col md={{size: 12}} style={{paddingRight: 0}}>
                                  <p style={{color: '#7ba2dc', textAlign: 'left', padding: '12px 20px 0px 20px'}}>
                                    The pattern of Archetypes across {branches[branchIndex].name} can be seen in the following representation
                                  </p>
                                  {
                                    this.state.archetypesTreeMapData && this.state.archetypesTreeMapData != '' ?
                                      <div style={{height: 'calc(82vh)'}}>
                                        <ResponsiveTreeMap
                                          root={this.state.archetypesTreeMapData}
                                          identity="name"
                                          value="totalSample"
                                          tile="resquarify"
                                          innerPadding={2}
                                          outerPadding={2}
                                          margin={{ top: 10, right: 0, bottom: 10, left: 10 }}
                                          label={(e) => {return e.name}}
                                          // labelFormat=".0s"
                                          labelSkipSize={10}
                                          labelTextColor={{ theme: 'background' }}
                                          colors={["#0544a2", "#020a15", "#a6cee3"]}
                                          // colors={{ scheme: 'purpleRed_green' }}
                                          borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                                          animate={true}
                                          motionStiffness={90}
                                          motionDamping={11}
                                        />
                                      </div>
                                    : null
                                  }
                                </Col>
                              </Row>
                            </Col>

                          </Row>

                          
                          
                          <Row>
                            
                            <Col md={{size: 10, offset: 1}}>
                              <p style={{color: '#7ba2dc', textAlign: 'center', padding: '15px 20px 0px 20px'}}>
                                The following framework represents the distribution of each Archetype across the Divisions in {branches[branchIndex].name}.
                              </p>
                            </Col>

                            <Col md={{size: 12}}>
                              <div style={{height: '500px', position: 'relative'}}>
                                {
                                  archetypesDataChart.data && archetypesDataChart.data.length ?
                                    <ResponsiveHeatMap
                                      data={archetypesDataChart.data}
                                      keys={archetypesDataChart.keys}
                                      indexBy={archetypesDataChart.indexBy}
                                      margin={{ top: 120, right: 60, bottom: 20, left: 280 }}
                                      forceSquare={true}
                                      axisTop={{ orient: 'top', tickSize: 15, tickPadding: 10, tickRotation: -40, legend: 'Archetypes', legendOffset: -110, legendPosition: 'middle' }}
                                      axisRight={null}
                                      axisBottom={null}
                                      axisLeft={{
                                          orient: 'left',
                                          tickSize: 10,
                                          tickPadding: 5,
                                          tickRotation: 0,
                                          legend: 'Divisions',
                                          legendPosition: 'middle',
                                          legendOffset: -230
                                      }}
                                      cellOpacity={1}
                                      cellBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.4 ] ] }}
                                      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.8 ] ] }}
                                      defs={[
                                          {
                                              id: 'lines',
                                              type: 'patternLines',
                                              background: 'inherit',
                                              color: 'rgba(0, 0, 0, 0.1)',
                                              rotation: -45,
                                              lineWidth: 4,
                                              spacing: 7
                                          }
                                      ]}
                                      fill={[ { id: 'lines' } ]}
                                      animate={true}
                                      motionStiffness={80}
                                      motionDamping={9}
                                      hoverTarget="cell"
                                      cellHoverOthersOpacity={0.25}
                                    />
                                    : null
                                }
                              </div>
                            </Col>

                          </Row>
                        </div>
                        
                      </div>
                    </Col>






                   
                </Row>
            </Container>
        </div>

        <div className={this.state.branchesListToggle ? "citiesListBarContainer active" : 'citiesListBarContainer'}>
          <Container fluid>
            <Row style={{textAlign: 'left'}}>
              {
                this.state.isCitiesLoading ? 
                  <div className="customSpinnerChart">
                    {/* <Spinner style={{width: '2rem', height: '2rem'}} size="lg" color="light" /> */}
                    
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  </div>
                : 
                  <BranchesListSidebar selectedBranchId={ branchIndex } selectedBranch={ branches[branchIndex].name }></BranchesListSidebar>
              }
              
            </Row>
          </Container>
          
          <Button onClick={e => this.toggleBranchesBar()} className={ this.state.branchesListToggle ? "citiesListBarSwitch opened" : "citiesListBarSwitch"} color="primary">
            {
              this.state.branchesListToggle ? 
                <FontAwesomeIcon className="iconHere" icon={this.state.branchesListToggle ? faTimes : faBars} size="1x" />
              : 
                <span>change<br/>branch</span>
            }
          </Button>
        </div>

        <div className={this.state.integratedFormToggle ? "integratedFormSideBar active" : 'integratedFormSideBar'}>
          {
            this.state.formSource !== "" ?
              <iframe src={this.state.formSource}></iframe>
            : null
          }

          <Button onClick={e => this.closeModalForm()} className={ this.state.integratedFormToggle ? this.state.integratedFormId == "ODY5Mg==" ? "citiesListBarSwitch green opened" : "citiesListBarSwitch yellow opened" : "citiesListBarSwitch"} color="primary">
                <FontAwesomeIcon className="iconHere" icon={faTimes} size="1x" />
          </Button>

        </div>
        
        {/* <Footer /> */}
      </div>
    );
    
  }
}

PagcorStatsComponent.propTypes = {
  

}

const mapStatesToProps = state => ({
  switchedBranch: state.webRed.switchedBranch
})

export default connect(mapStatesToProps, {  })(PagcorStatsComponent);