import React, { Component } from "react";
// import "../../public/scss/PartnersComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
    Media,
    Container,
    Row,
    Col
  } from "reactstrap";

// importing snippets for homepage
import BannerWithText from './snippets/bannerWithText';
import TextWithImage from './snippets/TextWithImage';

import bannerImageObj from './../../public/images/objectiveBanner.png';
import worldBannerIcon from './../../public/images/The-Desk_Cube.png';
import partnerImage from './../../public/images/partnersImage.jpg';

import AFLogo from './../../public/images/partners/AFLogo.png';
import businessTrends from './../../public/images/partners/businessTrends.png';
import CHED from './../../public/images/partners/CHED.png';
import CHED1  from './../../public/images/partners/CHED1 .png';
import DOLE from './../../public/images/partners/DOLE.png';
import Hirelabs from './../../public/images/partners/Hirelabs.png';
import lincolnMethod from './../../public/images/partners/lincolnMethod.png';
import sfi from './../../public/images/partners/sfi.png';
import SFI2 from './../../public/images/partners/SFI2.png';
import skillUpz from './../../public/images/partners/skillUpz.png';
import Tesda from './../../public/images/partners/Tesda.png';
import ts from './../../public/images/partners/ts.png';
import tt_logo_w from './../../public/images/partners/tt_logo_w.png';

import bhdi from './../../public/images/partners/bhdi.png';
import iue from './../../public/images/partners/iue.png';
import olivesTouch from './../../public/images/partners/olivesTouch.png';
import efp from './../../public/images/partners/efp.png';
import careercenter from './../../public/images/partners/careercenter.png';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class PartnersComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bannerData3 : {
                heading: "Our Partners",
                // para: "The Philippine TalentMap Initiative (PTMI) (https://talentmap.ph) categorized and examined the current trends and issues that both the academe and industry face in terms of workforce development.  The data derived from this research study was used as springboard to develop sound action plans in positioning the Filipino workforce in the local and global labor market. <br/><br/>TalentMap World will focus on creating an ecology that bridges the gaps amongst different schools, industries, local government units, and national agencies that have a stake in addressing the labor market information gaps of a particular locality and compare the data across other areas covered by the research.",
                btn_text: "",
                btn_text_url: "",
                themeColor: "white header",
                image_url: bannerImageObj,
                // video_url: "http://testedtalent.com/videos/Jeff.mp4"
            }
        };
    }
    
  render() {
    return (
      <div className="PartnersComponent">
        <Header />

            <BannerWithText bannerData={this.state.bannerData3} />
            
            <div className="partnerLogos">
              <Container>
                  <Row>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={AFLogo} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#000024'}}>
                        <Media style={{maxHeight: '135px'}} object src={businessTrends} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#000024'}}>
                        <Media style={{maxHeight: '135px'}} object src={CHED} />
                      </div>
                    </Col>
                    {/* <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={CHED1} />
                      </div>
                    </Col> */}
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={DOLE} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={Hirelabs} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#000024'}}>
                        <Media style={{maxHeight: '135px'}} object src={lincolnMethod} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={sfi} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={SFI2} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={skillUpz} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={Tesda} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px', marginTop: '20px'}} object src={ts} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={tt_logo_w} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={bhdi} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={careercenter} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={efp} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={iue} />
                      </div>
                    </Col>
                    <Col md={{size: '3'}}>
                      <div className="logoBox" style={{background: '#fff'}}>
                        <Media style={{maxHeight: '135px'}} object src={olivesTouch} />
                      </div>
                    </Col>
                  </Row>
              </Container>
            </div>
        
        <Footer />
      </div>
    );
  }
}

export default PartnersComponent;
