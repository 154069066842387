import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {browserHistory} from "react-router";
// import "./../../../public/scss/talentMap_statistics.scss";
// import Header from '../../website/Header';
// import Footer from "../../website/Footer";

// import store from './../../store';
import PropTypes from 'prop-types';
import { triggerFormIframe, getSpecificCitySkillsForDemo } from '../../../actions/webRedActions';
import Switch from "react-switch";
// import { validateEmailAddress } from './../../actions/utility';
import { connect } from 'react-redux';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faCalendarCheck, faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

import {
    Container,
    Row,
    Col,
    Button,
    Spinner,
    FormGroup,
    Input,
    Media,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

  
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMale } from '@fortawesome/free-solid-svg-icons';


class DistributionAndComparisonTopCitiesComponent extends Component {

  constructor(props) {
      super(props);

      this.state = {
        swarmDataAllCities : [],
        swarmDataSpecificCity : [],
        selectedRegion : this.props.selectedRegion,
        selectedSkill : this.props.selectedSkill,
        selectedSkillId : this.props.selectedSkillId,
        situations : this.props.situations,
        situationsAvg : this.props.situationsAvg,
        situationsAvgSpecificCity : this.props.situationsAvgSpecificCity,
        topCitiesDataLoc : 0,
        demoDataDumpTopCities : [],
        topPopulatedCities : [{
          cityid : 1,
          cityname : "Quezon"
        },{
          cityid : 2,
          cityname : "Manila"
        },{
          cityid : 14,
          cityname : "Caloocan"
        },{
          cityid : 23,
          cityname : "Davao"
        },{
          cityid : 22,
          cityname : "Cebu"
        },{
          cityid : 67,
          cityname : "Zambales"
        },{
          cityid : 11,
          cityname : "Taguig"
        },{
          cityid : 5,
          cityname : "Pasig"
        },{
          cityid : 21,
          cityname : "Cagayandeoro"
        },{
          cityid : 12,
          cityname : "Paranaque"
        }]
      };

  }

  componentDidMount() {

    this.state.topPopulatedCities.map(city => {
      // calls an action for getting skills/situations against each specific city.
      this.props.getSpecificCitySkillsForDemo(city.cityname, city.cityid);
    })

  }

  
  generateRandomAverage = () => {
    return (Math.floor(Math.random() * 95));
  }

  generateRandomVolume = () => {
    return (Math.floor(Math.random() * 4000));
  }
  
  situationsAverageMapping = (correctAns, totalAns) => {
    return parseInt((parseInt(correctAns) / parseInt(totalAns)) * 100);
  }

  componentWillReceiveProps(nextProps, thisProps) {
      
    if(this.props.selectedRegion != nextProps.selectedRegion) {
      this.setState({
        selectedRegion : nextProps.selectedRegion
      })
    }

    if(this.props.selectedSkill != nextProps.selectedSkill) {
      this.setState({
        selectedSkill : nextProps.selectedSkill
      })
    }

    if(this.props.selectedSkillId != nextProps.selectedSkillId) {
      this.setState({
        selectedSkillId : nextProps.selectedSkillId
      })
    }

    if(this.props.situations != nextProps.situations) {
      this.setState({
        situations : nextProps.situations
      }, () => {
        // console.log(this.state.situations);


        if(this.state.demoDataDumpTopCities.length > 9) {

          // console.log(this.state.demoDataDumpTopCities.length);

          var swarmDataArrayCity = [];
          var colors = ["#ba68c8", "#e91e63", "#64b5f6", "#ffcc80", "#e6ee9c", "#cfd8dc", "#9e9d24", "#18ffff", "#ea80fc", "#ff1744"];

          // var keys = Object.keys(this.state.situationsAvgSpecificCity);

          // console.log(this.state.situations);

          this.state.situations.map((situation, key) => {

            var data_array = [];
            
            this.state.demoDataDumpTopCities.map((city, key) => {

              // console.log(city);

              var selectedSkillBody = city.data[this.state.selectedSkillId];

              data_array.push({
                "id": city.city + " - candidates: "+ selectedSkillBody[situation].totallCandidates + ", avg. score",
                "group": situation,
                "score": this.situationsAverageMapping(selectedSkillBody[situation].totallCorrectAns, selectedSkillBody[situation].totallCandidates),
                "volume": selectedSkillBody[situation].totallCandidates
              });

            })

            swarmDataArrayCity.push({
              data :  data_array,
              color : colors[key]
            })

          })

          this.setState({
            swarmDataAllCities : swarmDataArrayCity
          }, () => {
            console.log(this.state.swarmDataAllCities)
          })

        }


      })
    }

    if(this.props.situationsAvg != nextProps.situationsAvg) { // for all cities
      // console.log(nextProps.situationsAvg)
    }

    if(this.props.situationsAvgSpecificCity != nextProps.situationsAvgSpecificCity) { // for specific city

      this.setState({
        situationsAvgSpecificCity : nextProps.situationsAvgSpecificCity
      }, () => {

        console.log(this.state.situationsAvgSpecificCity);

        var swarmDataArrayCity = [];
        var colors = ["#ba68c8", "#e91e63", "#64b5f6", "#ffcc80", "#e6ee9c", "#cfd8dc", "#9e9d24", "#18ffff", "#ea80fc", "#ff1744"];

        var keys = Object.keys(this.state.situationsAvgSpecificCity);

        keys.map((situation, key) => {

          var data_array = [];
          
          // this.state.allCities.map((city, key) => {
          data_array[0] = {
            "id": this.state.selectedRegion + " - candidates: "+ this.state.situationsAvgSpecificCity[situation].totallCandidates + ", avg. score",
            "group": situation,
            "score": this.situationsAverageMapping(this.state.situationsAvgSpecificCity[situation].totallCorrectAns, this.state.situationsAvgSpecificCity[situation].totallCandidates),
            "volume": this.state.situationsAvgSpecificCity[situation].totallCandidates
          };
          // })

          swarmDataArrayCity.push({
            data :  data_array,
            color : colors[key]
          })

        })

        this.setState({
          swarmDataSpecificCity : swarmDataArrayCity
        }, () => {
          console.log(this.state.swarmDataSpecificCity)
        })

      })
    }

    if(this.props.skillsCitySpecificResponseDemo != nextProps.skillsCitySpecificResponseDemo) {
      var tempArray = this.state.demoDataDumpTopCities;
      tempArray.push({
        city : nextProps.skillsCitySpecificResponseDemo.clickedRegion,
        data : nextProps.skillsCitySpecificResponseDemo.data
      })

      this.setState({
        demoDataDumpTopCities : tempArray
      }, () => {
        console.log(this.state.demoDataDumpTopCities);
      })
    }


  }
    
  render() {
      
    return (

      <div className="chartBox">
        <div className="titleHead">
          <Row>
            <Col md={{ size : 9 }}>
              <h5>Distribution and comparison of scores in top 10 cities, that tested for <strong>{this.state.selectedSkill}</strong></h5>
            </Col>
            <Col md={{ size : 3 }}>
              <div className="quickIcons" style={{right: '15px'}}>
                <FontAwesomeIcon className="customIcon" icon={faCalendarCheck} size="1x" onClick={e => this.props.triggerFormIframe('info')} />
                <FontAwesomeIcon className="customIcon" icon={faComment} size="1x"  onClick={e => this.props.triggerFormIframe('chat')} />
              </div>

              <Switch
                checked={this.state.topCitiesDataLoc == 1 ? true : false}
                onChange={e => this.setState({ topCitiesDataLoc : this.state.topCitiesDataLoc == 0 ? 1 : 0 }) }
                uncheckedIcon={
                  <div className="switchLabel">
                    {this.state.selectedRegion}
                  </div>
                }
                checkedIcon={
                  <div className="switchLabel">
                    Top 10 Cities
                  </div>
                }
                className={this.state.topCitiesDataLoc == 0 ? 'react-switch medium lowest' : 'react-switch medium highest'}
                id="icon-switch"

              />

            </Col>
          </Row>
        </div>
        
        <div className={ this.state.topCitiesDataLoc == 0 ? "swarmDataChartBox" : "swarmDataChartBox active"} style={{textAlign : 'center'}}>
          

        <div className="allcities">
          {   
            this.state.swarmDataAllCities && this.state.swarmDataAllCities.length && this.state.swarmDataAllCities.map((data, key) => 

              <div key={key} style={{width: '100%', height: '250px'}}>
                <ResponsiveSwarmPlot
                  data={data.data}
                  groups={[data.data[0].group]}
                  colors={[data.color]}
                  value="score"
                  valueFormat={d => `${d}%`}
                  valueScale={{ type: 'linear', min: 0, max: 100 }}
                  size={{ key: 'volume', values: [ 1, 500 ], sizes: [ 6, 20 ] }}
                  layout="horizontal"
                  forceStrength={4}
                  simulationIterations={100}
                  borderColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'darker',
                              0.6
                          ],
                          [
                              'opacity',
                              0.5
                          ]
                      ]
                  }}
                  margin={{ top: 70, right: 400, bottom: 70, left: 400 }}
                  enableGridX={true}
                  enableGridY={true}
                  motionStiffness={50}
                  motionDamping={10}
                />
              </div>
            )
          }
        </div>
        
        <div className="specificcity">
          {
            this.state.swarmDataSpecificCity && this.state.swarmDataSpecificCity.length && this.state.swarmDataSpecificCity.map((data, key) => 
              {

                return (

                  <div key={key} style={{width: '100%', height: '250px'}}>          
                    <ResponsiveSwarmPlot
                      data={data.data}
                      groups={[data.data[0].group]}
                      colors={[data.color]}
                      value="score"
                      valueFormat={d => `${d}%`}
                      valueScale={{ type: 'linear', min: 0, max: 100 }}
                      size={{ key: 'volume', values: [ 1, 500 ], sizes: [ 6, 20 ] }}
                      layout="horizontal"
                      forceStrength={4}
                      simulationIterations={100}
                      borderColor={{
                          from: 'color',
                          modifiers: [
                              [
                                  'darker',
                                  0.6
                              ],
                              [
                                  'opacity',
                                  0.5
                              ]
                          ]
                      }}
                      margin={{ top: 70, right: 400, bottom: 70, left: 400 }}
                      enableGridX={true}
                      enableGridY={true}
                      motionStiffness={50}
                      motionDamping={10}
                    />
                  </div>

                )
              }
            )
          }
      </div>

      <p style={{fontSize: '12px', color: '#435f84'}} className="contactForCity"  onClick={e => this.props.triggerFormIframe('info')}>* This graph represents partial data for this city. <span>Contact us</span> for the complete dataset for this city.</p>
          
      </div>
    
  </div>

  );
    
  }
}

DistributionAndComparisonTopCitiesComponent.propTypes = {
  triggerFormIframe: PropTypes.func.isRequired,
  getSpecificCitySkillsForDemo: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
  skillsCitySpecificResponseDemo : state.webRed.skillsCitySpecificResponseDemo
})

export default connect(mapStatesToProps, { triggerFormIframe, getSpecificCitySkillsForDemo })(DistributionAndComparisonTopCitiesComponent);