import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {browserHistory} from "react-router";
import "./../../../public/scss/grantreports.scss";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import PieChartComponent from './charts/PieChartComponent';

import {
    Container,
    Row,
    Col,
    Button,
    Input
  } from "reactstrap";

import Switch from "react-switch";
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveBubble } from '@nivo/circle-packing';
import Chart from 'react-google-charts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

class GrantApplicationFormStatsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalApplicantsData : [
                {
                  "id": "Total",
                  "label": "Total grant applications",
                  "value": 34,
                  "color": "hsl(288, 70%, 50%)"
                }
              ],
              pendingApplicantsData : [
                {
                  "id": "Pending",
                  "label": "Grants pending approval",
                  "value": 20,
                  "color": "hsl(104, 70%, 50%)"
                },
                {
                  "id": "Total",
                  "label": "",
                  "value": 14,
                  "color": "hsl(288, 70%, 50%)"
                }
              ],
              approvedApplicantsData : [
                {
                    "id": "Approved",
                    "label": "Grants approved",
                    "value": 8,
                    "color": "hsl(104, 70%, 50%)"
                },
                {
                    "id": "Total",
                    "label": "",
                    "value": 26,
                    "color": "hsl(288, 70%, 50%)"
                }
              ],
              rejectedApplicantsData : [
                {
                    "id": "Rejected",
                    "label": "Grants rejected",
                    "value": 6,
                    "color": "hsl(104, 70%, 50%)"
                },
                {
                    "id": "Total",
                    "label": "",
                    "value": 28,
                    "color": "hsl(288, 70%, 50%)"
                }
              ],
                globalPresence: {
                    all : [{
                            "country": "Philippines",
                            "sample": 12
                        },{
                            "country": "Malaysia",
                            "sample": 4
                        },{
                            "country": "Indonesia",
                            "sample": 3
                        },{
                            "country": "China",
                            "sample": 3
                        },{
                            "country": "USA",
                            "sample": 2
                        },{
                            "country": "RU",
                            "sample": 26
                        }],
                    pending : [{
                        "country": "Philippines",
                        "sample": 6
                    },{
                        "country": "Malaysia",
                        "sample": 8
                    },{
                        "country": "Indonesia",
                        "sample": 3
                    },{
                        "country": "China",
                        "sample": 3
                    },{
                        "country": "USA",
                        "sample": 2
                    },{
                        "country": "RU",
                        "sample": 15
                    }],
                    approved : [{
                        "country": "Philippines",
                        "sample": 12
                    },{
                        "country": "Malaysia",
                        "sample": 3
                    },{
                        "country": "Indonesia",
                        "sample": 0
                    },{
                        "country": "China",
                        "sample": 3
                    },{
                        "country": "USA",
                        "sample": 2
                    },{
                        "country": "RU",
                        "sample": 6
                    }],
                    rejected : [{
                        "country": "Philippines",
                        "sample": 12
                    },{
                        "country": "Malaysia",
                        "sample": 4
                    },{
                        "country": "Indonesia",
                        "sample": 3
                    },{
                        "country": "China",
                        "sample": 3
                    },{
                        "country": "USA",
                        "sample": 0
                    },{
                        "country": "RU",
                        "sample": 2
                    }]
                },
                selectedType : "all",
                globalPresenceBubble : {
                    "name": "loading",
                    "color": "hsl(241, 70%, 50%)",
                    "sample": 1
                },
                geoData : [],
              bottomGrantStatus : [
                  {
                    color : "#65f309",
                    title : "Grants approved",
                    viewLink : "#",
                    data : [{
                            "id": "Approved",
                            "label": "Grants Approved",
                            "value": 8,
                            "color": "hsl(104, 70%, 50%)"
                        }]
                  },{
                    color : "#65f309",
                    title : "White labels active",
                    viewLink : "#",
                    data : [{
                            "id": "Active",
                            "label": "Active",
                            "value": 2,
                            "color": "hsl(104, 70%, 50%)"
                        },
                        {
                            "id": "Approved",
                            "label": "",
                            "value": 6,
                            "color": "hsl(288, 70%, 50%)"
                        }]
                  },{
                    color : "#65f309",
                    title : "White labels pending",
                    viewLink : "#",
                    data : [{
                            "id": "Pending",
                            "label": "Pending",
                            "value": 5,
                            "color": "hsl(104, 70%, 50%)"
                        },
                        {
                            "id": "Approved",
                            "label": "",
                            "value": 3,
                            "color": "hsl(288, 70%, 50%)"
                        }]
                  },{
                    color : "#65f309",
                    title : "White labels dormant",
                    viewLink : "#",
                    data : [{
                            "id": "Dormant",
                            "label": "Dormant",
                            "value": 1,
                            "color": "hsl(104, 70%, 50%)"
                        },
                        {
                            "id": "Approved",
                            "label": "",
                            "value": 7,
                            "color": "hsl(288, 70%, 50%)"
                        }]
                  },{
                    color : "#65f309",
                    title : "White labels active & dormant",
                    viewLink : "#",
                    data : [{
                            "id": "Active & Dormant",
                            "label": "Active & Dormant",
                            "value": 3,
                            "color": "hsl(104, 70%, 50%)"
                        }]
                  }
              ]
        };

      // this.toggleModal = this.toggleModal.bind(this);

    }


  // toggleModal() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }

  componentDidMount() {

    this.updateBubbleChart(this.state.selectedType);
    this.updateGeoChartData(this.state.selectedType);

  }

  updateType = (e) => {
      var selected = e.target.value;
      this.setState({
          selectedType : selected
      })
  }

  updateBubbleChart = (type) => {
    var dataArrayBubble = [];
    this.state.globalPresence[type].map((data, key) => { //loop for extracting data for countries
      dataArrayBubble.push({
        "name": data.country,
        "color": "hsl(178, 70%, 50%)",
        "sample": data.sample
      });
    })

    setTimeout(() => {

      this.setState({
        globalPresenceBubble : {
          "name": "World",
          "color": "hsl(241, 70%, 50%)",
          "children": dataArrayBubble
        }
      },
      () => {
        console.log(this.state.globalPresenceBubble);
      })

    }, 500)
  }

  updateGeoChartData = (type) => {
    var dataArray = [];
    dataArray.push([
        'Country', 'Popularity'
    ]);
    this.state.globalPresence[type].map((data, key) => { //loop for extracting data for countries
        var country = data.country;
        if(country == "USA")
            country = "United States";

        dataArray.push([
            country, data.sample
        ]);
    })

    setTimeout(() => {

      this.setState({
        geoData : dataArray
      },
      () => {
        console.log(this.state.geoData);
      })

    }, 500)
  }


  

    
  render() {
      
    var types = Object.keys(this.state.globalPresence);
    // console.log(types);

    const valueStyle = {
        color: "#65f309",
        position: 'relative',
        top: '50%',
        transform : 'translateY(-50%)'
    }

    const iconStyle = {
        color: "#0b53a9"
    }

    return (
      <div className="GrantApplicationFormStatsComponent">
        {/* <Header /> */}

        <div>

            <Container fluid>
                <Row style={{ padding: '0 0 3vh 0' }}>

                    <Col style={{background: '#10223c', padding: '3vh 15px', textAlign: 'left'}} md={{ size: 12 }}>
                        <h5 style={{margin: 0}}>
                            TalentMap analytics for managing velocity or grant allocation & usage
                        </h5>
                    </Col>

                    <Col style={{margin: '4vh 0'}} md={{ size: 12 }}>
                        <div>
                            <Row>
                                <Col md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 6}}>
                                                <p style={{ color: '#fff' }}>Total grant applications</p>
                                                <Button size="sm" color="secondary">view list</Button>
                                            </Col>
                                            <Col md={{size : 6}}>
                                                <PieChartComponent data={this.state.totalApplicantsData} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 6}}>
                                                <p style={{ color: '#fff' }}>Grants pending approval</p>
                                                <Button size="sm" color="secondary">view list</Button>
                                            </Col>
                                            <Col md={{size : 6}}>
                                                <PieChartComponent data={this.state.pendingApplicantsData} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 6}}>
                                                <p style={{ color: '#fff' }}>Grants approved</p>
                                                <Button size="sm" color="secondary">view list</Button>
                                            </Col>
                                            <Col md={{size : 6}}>
                                                <PieChartComponent data={this.state.approvedApplicantsData} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 6}}>
                                                <p style={{ color: '#fff' }}>Grants rejected</p>
                                                <Button size="sm" color="secondary">view list</Button>
                                            </Col>
                                            <Col md={{size : 6}}>
                                                <PieChartComponent data={this.state.rejectedApplicantsData} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={{ size: 12 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 9 }}>
                              <h5>Global presence of grant applications</h5>
                            </Col>
                            <Col md={{ size : 3 }}>
                                <Input type="select" name="typeSelect3" id="typeSelect3" onChange={e => this.updateGeoChartData(e.target.value)}>
                                {
                                    types.map((type, key) => 

                                        <option key={key} value={type}>{type}</option>
                                    
                                    )
                                }
                                </Input>
                                
                            </Col>
                          </Row>
                        </div>
                        <div className="geoChart" style={{height: '700px', padding: '15px'}}>

                            <Chart
                                width={'100%'}
                                height={'100%'}
                                chartType="GeoChart"
                                data={this.state.geoData}
                                options={{
                                    // region: '002', // Africa
                                    colorAxis: { colors: ['#ffd401', '#ffd401'] },
                                    backgroundColor: '#0d1623',
                                    datalessRegionColor: '#2a4977',
                                    // defaultColor: '#ffffff',
                                }}
                                // Note: you will need to get a mapsApiKey for your project.
                                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                                mapsApiKey="YOUR_KEY_HERE"
                                rootProps={{ 'data-testid': '1' }}
                            />

                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Global presence of grant applications</h5>
                            </Col>
                            <Col md={{ size : 4 }}>
                                <Input type="select" name="typeSelect" id="typeSelect" onChange={e => this.updateType(e)}>
                                {
                                    types.map((type, key) => 

                                        <option key={key} value={type}>{type}</option>
                                    
                                    )
                                }
                                </Input>
                                
                            </Col>
                          </Row>
                        </div>
                        <div style={{height: '400px'}}>

                            <ResponsiveBar
                                data={this.state.globalPresence[this.state.selectedType]}
                                keys={[ 'sample' ]}
                                indexBy="country"
                                margin={{ top: 50, right: 60, bottom: 90, left: 120 }}
                                padding={0.4}
                                // onClick={e => this.barChartClicked(e)}
                                groupMode="grouped"
                                colors={['#d63333']}
                                layout="horizontal"
                                enableGridY= {false}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 50
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -60
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                            />

                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 6 }}>
                      <div className="chartBox">
                        <div className="titleHead">
                          <Row>
                            <Col md={{ size : 8 }}>
                              <h5>Global presence of grant applications</h5>
                            </Col>
                            <Col md={{ size : 4 }}>
                                <Input type="select" name="typeSelect2" id="typeSelect2" onChange={e => this.updateBubbleChart(e.target.value)}>
                                {
                                    types.map((type, key) => 

                                        <option key={key} value={type}>{type}</option>
                                    
                                    )
                                }
                                </Input>
                                
                            </Col>
                          </Row>
                        </div>
                        <div style={{height : "400px"}}>
                          
                            <ResponsiveBubble
                                root={this.state.globalPresenceBubble}
                                margin={{ top: 30, right: 20, bottom: 30, left: 20 }}
                                identity="name"
                                value="sample"
                                // colors={{ scheme: 'paired' }}
                                colors={["#f1e15b", "#1f78b4"]}
                                padding={6}
                                // label="value"
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
                                borderWidth={2}
                                borderColor={{ from: 'color' }}
                                defs={[
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'none',
                                        color: 'inherit',
                                        rotation: -45,
                                        lineWidth: 5,
                                        spacing: 8
                                    }
                                ]}
                                // fill={[ { match: { depth: 1 }, id: 'lines' } ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={12}
                            />
                          
                        </div>
                      </div>
                    </Col>

                    <Col md={{ size: 12 }}>
                        <div>
                            <Row>
                                {
                                    this.state.bottomGrantStatus.map((status, key) => 
                                        <Col style={{margin: '2vh 0'}} key={key} md={{ size: 3 }}>
                                            <div className="topBox">
                                                <Row>
                                                    <Col style={{textAlign: 'left'}} md={{size : 6}}>
                                                        <p style={{ color: '#fff' }}>{status.title}</p>
                                                        <a href={status.viewLink}><Button size="sm" color="secondary">view list</Button></a>
                                                    </Col>
                                                    <Col md={{size : 6}}>
                                                        <PieChartComponent color={status.color} data={status.data} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )
                                }

                                <Col style={{margin: '2vh 0'}} md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 6}}>
                                                <p style={{ color: '#fff' }}>Total people assessed</p>
                                                <a href="#"><Button size="sm" color="secondary">view list</Button></a>
                                            </Col>
                                            <Col md={{size : 6}}>
                                                <h3 style={valueStyle}>4,252</h3>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                <Col style={{margin: '2vh 0'}} md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 4}}>
                                                <p style={{ color: '#fff' }}>Men assessed</p>
                                                <a href="#"><Button size="sm" color="secondary">view list</Button></a>
                                            </Col>
                                            <Col md={{size : 4}}>
                                                <FontAwesomeIcon style={iconStyle} icon={faMale} size="6x" />
                                            </Col>
                                            <Col md={{size : 4}}>
                                                <h3 style={valueStyle}>2,365</h3>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                <Col style={{margin: '2vh 0'}} md={{ size: 3 }}>
                                    <div className="topBox">
                                        <Row>
                                            <Col style={{textAlign: 'left'}} md={{size : 4}}>
                                                <p style={{ color: '#fff' }}>Women assessed</p>
                                                <a href="#"><Button size="sm" color="secondary">view list</Button></a>
                                            </Col>
                                            <Col md={{size : 4}}>
                                                <FontAwesomeIcon style={iconStyle} icon={faFemale} size="6x" />
                                            </Col>
                                            <Col md={{size : 4}}>
                                                <h3 style={valueStyle}>1,887</h3>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                
                            </Row>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
        
        {/* <Footer /> */}
      </div>
    );
    
  }
}

export default GrantApplicationFormStatsComponent;
