import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Media
} from "reactstrap";

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HRReportsTemplate from './../../../public/images/HR-Reports-Template.png';
import HRReportsMeter from './../../../public/images/HR-Reports-meter.gif';

import InteractiveDashboardsMain from './../../../public/images/reports/Interactive-Dashboards_main.png';
import ProfilingImg from './../../../public/images/reports/profiling_img.jpg';
import InDepthPieChart from './../../../public/images/reports/InDepth_pieChart.png';
import BasicTSCFinanceManagerPaulJones from './../../../public/images/reports/BasicTSCFinanceManagerPaulJones.jpg';

import reportPage1 from './../../../public/images/reports/updated/1.JPG';
import reportPage2 from './../../../public/images/reports/updated/2.JPG';
import reportPage3 from './../../../public/images/reports/updated/3.JPG';
import reportPage4 from './../../../public/images/reports/updated/4.JPG';
import reportPage5 from './../../../public/images/reports/updated/5.JPG';
import reportPage6 from './../../../public/images/reports/updated/6.JPG';
import reportPage7 from './../../../public/images/reports/updated/7.JPG';
import reportPage8 from './../../../public/images/reports/updated/8.JPG';
import reportPage9 from './../../../public/images/reports/updated/9.JPG';
import reportPage10 from './../../../public/images/reports/updated/10.JPG';
import reportPage11 from './../../../public/images/reports/updated/11.JPG';
import reportPage12 from './../../../public/images/reports/updated/12.JPG';
import reportPage13 from './../../../public/images/reports/updated/13.JPG';
import reportPage14 from './../../../public/images/reports/updated/14.JPG';
import reportPage15 from './../../../public/images/reports/updated/15.JPG';
import reportPage16 from './../../../public/images/reports/updated/16.JPG';
import reportPage17 from './../../../public/images/reports/updated/17.JPG';
import reportPage18 from './../../../public/images/reports/updated/18.png';
import reportPage19 from './../../../public/images/reports/updated/19.png';
import reportPage20 from './../../../public/images/reports/updated/20.png';
import reportPage21 from './../../../public/images/reports/updated/21.png';
import reportPage22 from './../../../public/images/reports/updated/22.png';
import reportPage23 from './../../../public/images/reports/updated/23.png';

import staff1 from './../../../public/images/reports/staff1.png';
import staff2 from './../../../public/images/reports/staff2.png';
import staff3 from './../../../public/images/reports/staff3.png';



class ReportsComponent extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        // reportState : [reportPage1, reportPage2, reportPage3, reportPage4, reportPage5, reportPage6]
        reportState : [reportPage1, reportPage2, reportPage3, reportPage4, reportPage5, reportPage6, reportPage7, reportPage8, reportPage9, reportPage10, reportPage11, reportPage12, reportPage13, reportPage14, reportPage15, reportPage16, reportPage17, reportPage18, reportPage19, reportPage20, reportPage21, reportPage22, reportPage23]
    };
  }

  componentWillReceiveProps(thisProps, nextProps){
      
  }

  render() {

    const reportSlidersettings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        draggable: true,
        arrows: true,
        adaptiveHeight: true
    };

    return (
      <div className="ReportsComponent">

        <div className="engineContainer">
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>We provide easy to read reports</h2>
                    </Col>
                    <Col style={{padding: '14vh 15px 0 15px', textAlign: 'left'}} md={{ size: 4 }}>
                        <p style={{fontSize: '14px'}}>
                            The analysis is presented through an easy to understand acronym, called &nbsp; <span style={{fontWeight: 'bold', color: '#000'}}>I M A G E</span>
                        </p>
                    </Col>
                    <Col md={{ size: 3 }}>
                        <Media style={{width: '100%'}} object src={HRReportsTemplate} alt="HR Reports Template" />
                    </Col>
                    <Col md={{ size: 5 }}>
                        <Media style={{width: '100%'}} object src={HRReportsMeter} alt="HR Reports Meter" />
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="engineContainer" style={{background: '#fafafa', borderTop: '1px #ccc solid', borderBottom: '1px #ccc solid'}}>
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>Interactive & responsive dashboards</h2>
                    </Col>
                    <Col md={{ size: 12 }}>
                        <Media style={{maxWidth: '100%'}} object src={InteractiveDashboardsMain} alt="InteractiveDashboardsMain" />
                    </Col>
                    <Col style={{paddingTop: '5vh'}} md={{ size: 12 }}>
                        <a href="http://www.testedtalent.com/interactive-dashboards/" target="_new">
                            <Button size="lg" color="secondary">Learn more</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="engineContainer">
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>Profiling Mechanism</h2>
                    </Col>
                    <Col md={{ size: 12 }}>
                        <Media style={{maxWidth: '100%'}} object src={ProfilingImg} alt="Profiling Mechanism" />
                    </Col>
                    <Col style={{paddingTop: '5vh'}} md={{ size: 12 }}>
                        <a href="http://www.testedtalent.com/profiling-mechanism/" target="_new">
                            <Button size="lg" color="secondary">Learn more</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
      
        <div className="engineContainer" style={{background: '#fafafa', borderTop: '1px #ccc solid', borderBottom: '1px #ccc solid'}}>
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>In-depth Analysis</h2>
                    </Col>
                    <Col md={{ size: 12 }}>
                        <Media style={{maxWidth: '100%'}} object src={InDepthPieChart} alt="InDepthPieChart" />
                    </Col>
                    <Col style={{paddingTop: '5vh'}} md={{ size: 12 }}>
                        <a href="http://www.testedtalent.com/tar-talent-assessment-report/" target="_new">
                            <Button size="lg" color="secondary">Learn more</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="engineContainer">
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>One Page Reports</h2>
                        <p style={{color: '#666'}}>Check out the Talent Score Card</p>
                    </Col>
                    <Col md={{ size: 12 }}>
                        <Media style={{maxWidth: '100%'}} object src={BasicTSCFinanceManagerPaulJones} alt="BasicTSCFinanceManagerPaulJones" />
                    </Col>
                    <Col style={{paddingTop: '5vh'}} md={{ size: 12 }}>
                        <a href="http://www.testedtalent.com/talent-score-card/" target="_new">
                            <Button size="lg" color="secondary">Learn more</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="engineContainer reportSlider2" style={{background: '#fafafa', borderTop: '1px #ccc solid', borderBottom: '1px #ccc solid'}}>
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>Detailed Report</h2>
                    </Col>
                    <Col md={{ size: 12 }}>
                        {/* <Media style={{maxWidth: '100%'}} object src={JohnPaul} alt="John Paul Report" /> */}

                        <Slider { ...reportSlidersettings }>
                            {
                                this.state.reportState.map((item, key) =>
                                    <div  key={key} style={{textAlign: 'center'}}>
                                        <Media key={key} style={{maxWidth: '100%', margin: 'auto'}} object src={item} alt={item} />
                                    </div>
                                )
                            }
                        </Slider>

                    </Col>
                    <Col style={{marginTop: '6vh'}} md={{ size: 12 }}>
                        <a href="http://www.testedtalent.com/talent-assessment-report/" target="_new">
                            <Button size="lg" color="secondary">Learn more</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="engineContainer">
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>Video Interview App</h2>
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Media style={{maxWidth: '100%'}} object src={staff1} alt="staff1" />
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Media style={{maxWidth: '100%'}} object src={staff2} alt="staff2" />
                    </Col>
                    <Col md={{ size: 4 }}>
                        <Media style={{maxWidth: '100%'}} object src={staff3} alt="staff3" />
                    </Col>
                </Row>
                <Row>
                    <Col style={{paddingTop: '5vh'}} md={{ size: 12 }}>
                        <a href="http://www.testedtalent.com/video-interview-app/" target="_new">
                            <Button size="lg" color="secondary">Learn more</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
      
      </div>
    );
  }
}

export default ReportsComponent;
