import React, { Component } from "react";
import "../../public/scss/HomeComponent.scss";
import Header from './Header';
import Footer from "./Footer";

import {
      Container,
      Row,
      Col,
      Media,
      Button
    } from "reactstrap";

// importing snippets for homepage
import BannerWithText from './snippets/bannerWithText';
import BannerWithSlider from './snippets/bannerWithSlider';
import IconGridSection from './snippets/iconGridSection';

import ambisyon2040 from './../../public/images/ambisyon2040.png';
import bannerImage from './../../public/images/HR-Analytics.png';
import bannerImageWorld from './../../public/images/worldPicture.png';
import bannerVideo from './../../public/videos/bannerVideo.mov';
import BPO_box_image from './../../public/images/BPO_box.png';
import worldBannerIcon from './../../public/images/The-Desk_Cube2.png';
import scorecard1 from './../../public/images/scorecardSlider1.jpg';
import scorecard2 from './../../public/images/scorecardSlider2.jpg';
import scorecard3 from './../../public/images/scorecardSlider3.jpg';
import betterJobFit1 from './../../public/images/better-job-fit-patricia.jpg';
import betterJobFit2 from './../../public/images/better-job-fit-neeta.jpg';
import betterJobFit3 from './../../public/images/better-job-fit-james.jpg';
import betterJobFit4 from './../../public/images/better-job-fit-alicia.jpg';
import betterJobFit5 from './../../public/images/better-job-fit-alex.jpg';
import CompleteRecruitmentSolution from './../../public/images/Complete-Recruitment-Solution.png';
import AssessmentCenterFeatures from './../../public/images/Assessment-Center-Features.png';
import Shortlisttoptalentforinterview from './../../public/images/Shortlist-top-talent-for-interview.png';
import BPOhireremoteteams from './../../public/images/BPO-hire-remote-teams.png';
import HRReportsTemplate from './../../public/images/HR-Reports-Template.png';
import HRReportsMeter from './../../public/images/HR-Reports-meter.gif';
import dashboardBanner1 from './../../public/images/dashboardBanner1.png';
import dashboardBanner2 from './../../public/images/dashboardBanner2.png';
import dashboardBanner3 from './../../public/images/dashboardBanner3.png';
import icon1 from './../../public/images/test-catalog.png';
import icon2 from './../../public/images/Customizable_org.png';
import icon3 from './../../public/images/Competencies_org.png';
import icon4 from './../../public/images/Valid_org.png';
import icon5 from './../../public/images/Reports_org.png';
import icon6 from './../../public/images/Program_org.png';
import icon7 from './../../public/images/Branded_org.png';
import icon8 from './../../public/images/Profiling_org.png';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class HomeComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            digitalTransformationVideo : "http://info.lincolnmethod.com/wp-content/uploads/2019/12/digitalTransformation.mp4",
            videoAutoPlay : false,
            bannerData : {
                heading: "21<sup>st</sup> Century Skills",
                subheading: "We are giving HR Companies a grant to build a tested talent pipeline",
                btn_text: "Apply for grant funding",
                btn_text_url: "http://www.testedtalent.com/grant-application-form/",
                // image_url: bannerImage,
                // video_url: "http://testedtalent.com/videos/Jeff.mp4",
                video_url: bannerVideo,
                themeColor: "yellow"
            },
            sliderData1: {
                data : [{
                        text: "Use the assessment engine",
                        backgroundColor: "#000c1d",
                        backgroundImage: "",
                        height: '400px'
                },{
                    text: "Test candidates for 21st Century Skills",
                    backgroundColor: "#1d001b",
                    backgroundImage: "",
                    height: '400px'
                },{
                    text: "Apply for grant funding",
                    backgroundColor: "#1f1f1f",
                    backgroundImage: "",
                    height: '400px'
                }],
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 400,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 3500,
                    draggable: false,
                    arrows: false
                }
            },
            sliderData2: {
                data : [{
                    text: "You get a one-page report called the <strong>Talent Score Card</strong>",
                    backgroundColor: "",
                    backgroundImage: scorecard1,
                    height: '500px'
                },{
                    text: "Get <strong>in-depth analysis</strong> through additional reports",
                    backgroundColor: "",
                    backgroundImage: scorecard2,
                    height: '500px'
                },{
                    text: "An analysis on <strong>Strengths & Weaknessess</strong> puts things in perspective",
                    backgroundColor: "",
                    backgroundImage: scorecard3,
                    height: '500px'
                }],
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 400,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    draggable: false,
                    arrows: false
                }
            },
            sliderData3: {
                data : [{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit1,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit2,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit3,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit4,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "",
                    backgroundImage: betterJobFit5,
                    height: '808px'
                }],
                settings: {
                    dots: false,
                    infinite: true,
                    speed: 400,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    fade: true,
                    pauseOnHover: false,
                    draggable: false
                }
            },
            dashboardData: {
                data : [{
                    text: "",
                    backgroundColor: "#f4d506",
                    backgroundImage: dashboardBanner1,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "#f4d506",
                    backgroundImage: dashboardBanner2,
                    height: '808px'
                },{
                    text: "",
                    backgroundColor: "#f4d506",
                    backgroundImage: dashboardBanner3,
                    height: '808px'
                }],
                settings: {
                    dots: false,
                    infinite: true,
                    speed: 1200,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    fade: true,
                    autoplaySpeed: 1500,
                    pauseOnHover: false,
                    draggable: false
                }
            },
            iconsData: {
                heading: "Here is the assessment center included in the grant",
                background: "#f9f9f9",
                data : [{
                    iconLabel: "The Test Catalog",
                    iconColor: "#000",
                    iconImage: icon1
                },{
                    iconLabel: "Highly Customizable",
                    iconColor: "#000",
                    iconImage: icon2
                },{
                    iconLabel: "Identify Competencies",
                    iconColor: "#000",
                    iconImage: icon3
                },{
                    iconLabel: "It's Valid & Reliable",
                    iconColor: "#000",
                    iconImage: icon4
                },{
                    iconLabel: "Reports & Analytics",
                    iconColor: "#000",
                    iconImage: icon5
                },{
                    iconLabel: "Run recruitment programs",
                    iconColor: "#000",
                    iconImage: icon6
                },{
                    iconLabel: "Branded for your company",
                    iconColor: "#000",
                    iconImage: icon7
                },{
                    iconLabel: "Profiling Mechanism",
                    iconColor: "#000",
                    iconImage: icon8
                }]
            },
            bannerData2 : {
                heading: "Tested Talent powers<br/>a BPO called the DESK <img style='max-width: 100%; margin: 25px 0;' src='"+ BPO_box_image +"' />",
                btn_text: "Visit the Desk",
                btn_text_url: "/#",
                themeColor: "red",
                // image_url: "bannerImage",
                // video_url: "http://testedtalent.com/videos/Jeff.mp4"
            },
            bannerData3 : {
                heading: "<img style='max-width: 100%;' src='"+ worldBannerIcon +"' /> Flagship project funded in Philippines</br></br>",
                para: "The <a href='https://talentmap.ph' style='color:#f4d506'>Philippine TalentMap Initiative (PTMI)</a> categorized and examined the current trends and issues that both the academe and industry face in terms of workforce development.  The data derived from this research study was used as springboard to develop sound action plans in positioning the Filipino workforce in the local and global labor market. <br/><br/>TalentMap World will focus on creating an ecology that bridges the gaps amongst different schools, industries, local government units, and national agencies that have a stake in addressing the labor market information gaps of a particular locality and compare the data across other areas covered by the research.",
                btn_text: "See the Dashboard",
                btn_text_url: "http://talentmap.report/",
                themeColor: "white world",
                image_url: bannerImageWorld,
                // video_url: "http://testedtalent.com/videos/Jeff.mp4"
            },
            BridgeTheSkillGapVideo : "http://info.lincolnmethod.com/wp-content/uploads/2020/02/BridgeTheSkillGap.mp4"
        };
    }
    
    componentDidMount() {
        // window.addEventListener('scroll', (e) => {
        //     let scrollTop = window.scrollY;
        //     if(scrollTop >= 5200) {
        //         this.setState({
        //             videoAutoPlay : true
        //         })
        //     }
        // });
    }

  render() {
    return (
      <div className="HomeComponent">
        <Header />

        {/* temperary work */}
        <div className="titleContainer ambisyon2040" style={{ background: '#000', padding: "50px 0" }}>
            <Container fluid>
                <Row>
                    <Col md={{ size: 3 }}>
                        <Media width="200" object src={ambisyon2040} alt="Ambisyon 2040" />
                    </Col>
                    <Col style={{ padding: '15px 0', textAlign: 'right' }} md={{ size: 5 }}>
                        <h3 style={{fontSize: "2rem", marginBottom: 0}}>Come and work with us !</h3>
                        {/* <a href="/#/adopt-a-city" style={{color: '#fff'}}>Learn More</a> */}
                    </Col>
                    <Col md={{ size: 4 }}>
                        <a href="/#/adopt-a-city-philippines" >
                            <Button style={{margin : '12px 0', fontSize: '1.25rem'}} size="md" color="primary">Become an Ambassador</Button>
                        </a><br/>
                        <p style={{fontSize: '16px', color: '#fff', width: '60%', margin: '0 auto', fontStyle: 'italic'}}>As an ambassador you can pledge your support or you can adopt a city</p>
                        {/* <a href="/#/adopt-a-city">
                            <Button style={{width: '100%'}} size="lg" color="warning">Adopt a city</Button>
                        </a> */}
                    </Col>
                </Row>
            </Container>
        </div>

        <BannerWithText bannerData={this.state.bannerData} />
        <BannerWithSlider sliderData={this.state.sliderData1} />
        <BannerWithText bannerData={this.state.bannerData3} />

        <div  className="titleContainer">
            <Container>
                <Row>
                    <Col md={{ size: 6, offset: 3 }}>
                        <h3>Use grant funding towards an assessment engine that has competency tests for 21<sup>st</sup> Century Skills</h3>
                    </Col>
                </Row>
            </Container>
        </div>
        {/* <div style={{background: '#f4d506'}} className="engineContainer">
            <Container>
                <Row>
                    <Col md={{ size: 3, offset: 1 }}>
                        <Media object src={CompleteRecruitmentSolution} alt="Complete Recruitment Solution" />
                    </Col>
                    <Col md={{ size: 8 }} style={{textAlign: 'left'}}>
                        <h3>The three most popular applications</h3><br/>
                        <Media object src={AssessmentCenterFeatures} alt="Assessment Center Features" />
                        <Media object src={Shortlisttoptalentforinterview} alt="Shortlist top talent for interview" />
                        <Media object src={BPOhireremoteteams} alt="BPO hire remote teams" />

                    </Col>
                </Row>
            </Container>
        </div> */}

        <IconGridSection iconsData={this.state.iconsData} />
        <BannerWithSlider sliderData={this.state.sliderData3} />

        <div className="engineContainer">
            <Container>
                <Row>
                    <Col style={{paddingBottom: '50px'}} md={{ size: 12 }}>
                        <h2>You can use the grant to evaluate candidates using IMAGE</h2>
                        <h3 style={{color: '#666'}}>It is an effective way to classify skillset</h3>
                    </Col>
                    <Col style={{padding: '14vh 15px 0 15px', textAlign: 'left'}} md={{ size: 4 }}>
                        <p style={{fontSize: '14px'}}>
                            The analysis is presented through an easy to understand acronym, called &nbsp; <span style={{fontWeight: 'bold', color: '#000'}}>I M A G E</span>
                        </p>
                    </Col>
                    <Col md={{ size: 3 }}>
                        <Media style={{width: '100%'}} object src={HRReportsTemplate} alt="HR Reports Template" />
                    </Col>
                    <Col md={{ size: 5 }}>
                        <Media style={{width: '100%'}} object src={HRReportsMeter} alt="HR Reports Meter" />
                    </Col>
                </Row>
            </Container>
        </div>

        <BannerWithSlider sliderData={this.state.sliderData2} />

        
        <div className="titleContainer override">
            <Container>
                <Row>
                    <Col md={{ size: 12 }}>
                        <h2 style={{color: '#000', fontWeight: 'bold'}}>The grant give you access amazing dashboard</h2>
                    </Col>
                </Row>
            </Container>
        </div>
        <BannerWithSlider sliderData={this.state.dashboardData} />
        {/* <BannerWithText bannerData={this.state.bannerData2} /> */}

        <div className="titleContainer">
            {
                this.state.BridgeTheSkillGapVideo && this.state.BridgeTheSkillGapVideo !== '' ?
            
                <video className="slider-video" width="100%" loop controls>
                    {/* <source src={this.state.BridgeTheSkillGapVideo} type="video/webm" /> */}
                    <source type="video/mp4" src={this.state.BridgeTheSkillGapVideo} />
                </video>
            
                : null

            }
        </div>
        
        <div className="titleContainer" style={{padding: "10vh 0", background: '#ffb400'}}>
            <Container>
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <h3 style={{padding: '0 0 5vh 0', color: '#000'}}>Look what we are doing for DIGITAL TRANSFORMATION</h3>
                        <video className="slider-video" width="100%" preload="auto" controls={true} loop={false} autoPlay={this.state.videoAutoPlay}>
                            <source type="video/mp4" src={this.state.digitalTransformationVideo} />
                        </video>
                    </Col>
                </Row>
            </Container>
        </div>
        
        <Footer />
      </div>
    );
  }
}

export default HomeComponent;
